import React from 'react';
// import styles
import {
    ContFooter1,
    ContFooter2,
    ContImgFooterLaptop,
    ContImgFooterMovile,
    ContLinkFooter,
    Copy,
    FooterLandingPage,
    Hr
} from '../styles/Footerlanding'
// import react router dom
import { Link } from 'react-router-dom';
// import data
import { spanish } from '../data/spanish';
// import logos footer
import facebook from '../images/footer-landing/facebook.svg';
import instagram from '../images/footer-landing/instagram.svg';
import twitter from '../images/footer-landing/twitter.svg';
import youtube from '../images/footer-landing/youTube.svg';
import logoPrincipal from '../images/Logos-Swirl/logo-swirl-09.png';

const FooterLanding = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring data body
    const { landingPage: {
        footerLanding: {
            titleFooter1,
            titleFooter2,
            text1Footer,
            text2Footer,
            text3Footer,
            text4Footer,
            text5Footer,
            text6Footer,
            text7Footer,
        }
    } } = textBody;

    //add current year
    const year = new Date().getFullYear();


    return (
        <FooterLandingPage>
            <ContFooter1>
                <ContLinkFooter>
                    <p>{titleFooter1}</p>
                    <Link>{text1Footer}</Link>
                    <Link to='/terms-and-conditions'>{text2Footer}</Link>
                    <Link
                        to='https://www.go2swirl.com/blog/'
                        target="_blank"
                        rel="noopener noreferrer"
                    >{text3Footer}</Link>
                </ContLinkFooter>
                <ContImgFooterLaptop >
                    <p>{titleFooter2}</p>
                    <Link><img src={facebook} alt="logo_facebook" /></Link>
                    <Link><img src={instagram} alt="logo_instagraam" /></Link>
                    <Link><img src={twitter} alt="logo_twitter" /></Link>
                    <Link><img src={youtube} alt="logo_you_tube" /></Link>
                </ContImgFooterLaptop >
                <ContLinkFooter>
                    <Link>{text4Footer}</Link>
                    <Link>{text5Footer}</Link>
                    <Link>{text6Footer}</Link>
                </ContLinkFooter>
            </ContFooter1>

            <ContImgFooterMovile >
                <p>{titleFooter2}</p>
                <Link><img src={facebook} alt="logo_facebook" /></Link>
                <Link><img src={instagram} alt="logo_instagraam" /></Link>
                <Link><img src={twitter} alt="logo_twitter" /></Link>
                <Link><img src={youtube} alt="logo_you_tube" /></Link>
            </ContImgFooterMovile >

            <Hr />

            <ContFooter2>
                <img src={logoPrincipal} alt="logo_principal_swirl" />
                <Copy>
                    <p>&copy; &nbsp;{year} &nbsp; {text7Footer}</p>
                </Copy>
            </ContFooter2>
        </FooterLandingPage>
    )
}

export default FooterLanding