import React, { useEffect, useState } from "react";
//import react router dom
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import componets
import { LogIn } from "./pages/LogIn";
import { SingUp } from "./pages/SingUp";
import { Home } from "./pages/Home";
// import function private route
import { PrivateRoutes } from "./routes/PrivateRoute";
// firebase
import { getAuth, onAuthStateChanged } from 'firebase/auth'
//firestore
import { getFirestore, collection, getDocs } from "firebase/firestore";
import app from "./firebase/credenciales";
//import context
import MyContext from "./Context/MyContext";
//import pages
import DatosPerfil from "./pages/DatosPerfil";
import AgregarFotos from "./pages/AgregarFotos";
import PerfilOtroUsuario from "./pages/PerfilOtroUsuario";
import AdminDashboard from "./pages/AdminDashboard";
import NewUser from "./pages/NewUser";
import Buscador from "./pages/Buscador";
import Mensajes from "./pages/Mensajes";
import Conversacion from "./pages/Conversacion";
import MIPerfil from "./pages/MIPerfil";
import EditarPerfil from "./pages/EditarPerfil";
import Notificaciones from "./pages/Notificaciones";
import Amigos from "./pages/Amigos";
import LandingHome from "./pages/LandingHome";
import Eventos from "./pages/Eventos";
import TermsConditions from "./pages/TermsConditions";
import ScrollToTop from "./components/ScrollToTop";



function App() {


  //firebase autentication
  const auth = getAuth(app);
  const firestore = getFirestore(app);

  //managing global data
  const [data, setData] = useState([]);

  //estado local user
  const accessUser = localStorage.getItem('user');


  // Obtengo el string previamente guardado de local para admi
  var guardado = localStorage.getItem('userD');
  //desestructuro el dato
  const dataAdmi = JSON.parse(guardado);


  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        // getRol(usuarioFirebase.uid);
        localStorage.setItem('user', `${usuarioFirebase.uid}`);

        const searchData = async () => {
          const collectionRef = collection(firestore, "usuarios");
          const querySnapshot = await getDocs(collectionRef);
          const documentosData = [];

          querySnapshot.forEach((doc) => {
            documentosData.push(doc.data());
          });

          setData(documentosData);
          const userActive = documentosData.find(item => item.id === usuarioFirebase.uid);
          const userData = {
            Usid: userActive.id,
            estatus: userActive.S5p4r1bn3k,
            name: userActive.name,
            perfil: userActive.perfil_complete,
            photo: userActive.fileData_complete,
            profileView: false
          };
          localStorage.setItem('userD', JSON.stringify(userData));
        };
        searchData();

      } else {
        localStorage.removeItem('user'); // Eliminar del localStorage
        localStorage.removeItem('userD');

      }
    });
    return () => {
      unsubscribe();
      localStorage.removeItem('user'); // Eliminar del localStorage
      localStorage.removeItem('userD');
    }

  }, [accessUser]);

  return (
    <MyContext.Provider value={data}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/login' element={<LogIn />} />
          <Route path='/singup' element={<SingUp />} />
          <Route path='/' element={<LandingHome />} />
          <Route path='/terms-and-conditions' element={<TermsConditions />} />
          <Route path="*" element={<Navigate to="/" />} />
          

          <Route path='/home' element={<PrivateRoutes isAutentication={accessUser}> <Home /> </PrivateRoutes>} />
          <Route path='/datos-de-perfil' element={<PrivateRoutes isAutentication={accessUser}> <DatosPerfil /> </PrivateRoutes>} />
          <Route path='/agregar-fotos' element={<PrivateRoutes isAutentication={accessUser}> <AgregarFotos /> </PrivateRoutes>} />
          <Route path='/nuevos-usuarios' element={<PrivateRoutes isAutentication={accessUser}> <NewUser /> </PrivateRoutes>} />
          <Route path='/buscador' element={<PrivateRoutes isAutentication={accessUser}> <Buscador /> </PrivateRoutes>} />
          <Route path='/mensajes' element={<PrivateRoutes isAutentication={accessUser}> <Mensajes /> </PrivateRoutes>} />
          <Route path='/conversacion/:id' element={<PrivateRoutes isAutentication={accessUser}> <Conversacion /> </PrivateRoutes>} />
          <Route path='/mi-perfil/:id' element={<PrivateRoutes isAutentication={accessUser}> <MIPerfil /> </PrivateRoutes>} />
          <Route path='/editar-perfil' element={<PrivateRoutes isAutentication={accessUser}> <EditarPerfil /> </PrivateRoutes>} />
          <Route path='/notificaciones' element={<PrivateRoutes isAutentication={accessUser}> <Notificaciones /> </PrivateRoutes>} />
          <Route path='/mis-amigos' element={<PrivateRoutes isAutentication={accessUser}> <Amigos /> </PrivateRoutes>} />
          <Route path='/eventos' element={<PrivateRoutes isAutentication={accessUser}> <Eventos /> </PrivateRoutes>} />

          <Route path="/perfil-otros-usuarios/:id" element={<PrivateRoutes isAutentication={accessUser}> <PerfilOtroUsuario /> </PrivateRoutes>} />

          <Route path="/dashboard" element={<PrivateRoutes isAutentication={dataAdmi}> <AdminDashboard /> </PrivateRoutes>} />


        </Routes>
      </BrowserRouter>
    </MyContext.Provider>
  );
}

export default App;


