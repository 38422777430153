import React, { useState, useEffect } from 'react';
//import styles
import { ContSelectPais, SelectCity } from '../styles/DatosPerfil';
//import functions from utils js
import { dataCountries } from '../UtilsData';

const GeoSearchEdit = ({ onEnviar, city, country }) => {

    let c = console.log.bind(document);

    //import data 
    const [dataC] = dataCountries;
    //deconstructing the name of countries
    const destrCountries = Object.keys(dataC.America);
    //state countrye data
    const [countryName, setCountryName] = useState('');
    //deconstructing cities of the country
    const destrCityes = Object.entries(dataC.America);
    //state selected citie
    const [countries, setCountries] = useState('');
    //array of cities
    const [cities, setCities] = useState([]);
    //send data from parent component
    onEnviar(countries, countryName)

    useEffect(() => {
        // Buscar el elemento en el objeto
        let contenidoElemento = null;
        for (let i = 0; i < destrCityes.length; i++) {
            if (destrCityes[i][0] === countryName) {
                contenidoElemento = destrCityes[i][1];
                break;
            }
        }
        // Imprimir el contenido del elemento encontrado
        setCities(contenidoElemento);
    }, [countryName])

    return (
        <>

            <ContSelectPais>
                <div>
                    <span>
                        <label className='form_label'>País de Residencia</label>
                        <SelectCity
                            required
                            onChange={(e) => setCountryName(e.target.value)}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{country}</option>
                                {destrCountries.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCity>
                    </span>
                    <span>
                        <label className='form_label'>Ciudad</label>

                        <SelectCity
                            required
                            onChange={(e) => setCountries(e.target.value)}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{cities ? 'selecciona' : city}</option>
                                {cities && cities.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCity>
                    </span>
                </div>
            </ContSelectPais > {/* country */}

        </>
    );
};

export default GeoSearchEdit;