import styled from "styled-components";



export const ContNavBarA = styled.nav`
    /* border: 1px solid red;//........ */
    z-index: 900;
    position: fixed;
    top: 0;
    font-size: 1.2em;
    height: 45px;
    width:100%;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 .3em 0 1em; */
    background: rgb(31,72,126);
    background: linear-gradient(90deg, rgba(31,72,126,1) 0%, rgba(35,112,153,1) 50%, rgba(31,72,126,1) 100%); 
    p{
        margin: 0;
        color: var(--color3);
        font-weight: 700;
        text-transform: capitalize;
    }
`
export const ArrowNav = styled.span`
    cursor: pointer;
    color: var(--color3);
    padding-left: .5em;
    display: flex;

`
export const IconDots = styled.span`
    cursor: pointer;
    color: var(--color3);
    padding-right: .5em;
`
export const SingleIcon = styled.span`
    color: transparent;
    padding-right: .5em;
`
export const ContImgNav = styled.div`
    /* border:1px solid red;//................. */
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    display: grid;
    place-content: center;
    overflow: hidden;
    background-color: #eee;
    margin-left: .6em;
    img{
        width: 103%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center 
    }
`
export const ContConver = styled.span`
    display: flex;
    p{
        padding-left: .6em;
    }
`
export const ContMenuEdit = styled.span`
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1{
        font-size: 1.2em;
        color: var(--color3);
    }
    a{
        font-size: .7em;
        text-decoration: none;
        color: var(--color3);
        font-weight: 500;
    }
    p{
        font-size: .9em;
        color: var(--color-2);
        cursor: pointer;
    }
`