import React from 'react';
//import skeletons from material ui
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
//import styles
import { ContImgP, ContImgParagraph, ContSectionP, ParagraphP1 } from '../../styles/PerfilOtrosUsuarios';

export default function SklPerfilOtrosUsuarios() {

    // create and managint skeletons
    const fileInputs = (Array(13).fill());

    return (
        <>
            <Stack spacing={1}>
                <ContSectionP>
                    <ContImgParagraph>
                        <ContImgP><Skeleton variant="circular" width={100} height={100} /></ContImgP>
                        <ParagraphP1>
                            <Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" width="85%" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" width="85%" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" width="85%" sx={{ fontSize: '1rem' }} />
                        </ParagraphP1>
                    </ContImgParagraph>
                    {fileInputs.map((index) =>
                        <Skeleton key={index} variant="text" width="100%" sx={{ fontSize: '1rem' }} />
                    )}
                    <br />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                </ContSectionP>
            </Stack>
        </>
    );
}