import React, { useState } from "react";
//react router dom
import { Link, useNavigate } from "react-router-dom";
//styles
import { CheckboxInput, Form } from "../styles/Login";
import {
  BtnSignUp,
  ContCheckSignUp,
  ContFormSignUp,
  ContInputSign,
  ContPLink,
  ContSelectSign,
  ContSignUp,
  DivTitle
} from "../styles/SingUp";
import styled from "styled-components";
// AUTH firebase
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
// import db firebase
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import app from "../firebase/credenciales";
// toastify alert
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleBackdrop from "../components/BackDrop";
import { spanish } from "../data/spanish";
// Importar la librería crypto-js
const CryptoJS = require('crypto-js');



export function SingUp() {

  //get data from text body
  const [textBody] = spanish;
  //destructuring static data
  const { singUp: {
    titleSingUp,
    labelNickName,
    labelEmail,
    labelPassword,
    labelConfirmPassword,
    labelProfileType,
    labelOptionHidden,
    labelCheckBox1,
    labelCheckBox2,
    button,
    textLogin,
    optionsProfile
  }} = textBody;

  //AUTH firebase
  const auth = getAuth(app);
  // db firebase
  const firestore = getFirestore(app);
  //active backdrop
  const [open, setOpen] = useState(false);
  //
  const navigate = useNavigate();
  //
  const clave = `${process.env.REACT_APP_CLAVE_CIFRADO}`;
  //
  const iv = `${process.env.REACT_APP_VECTOR_INICIALIZACION}`;
  // const default avatar
  const default_avatar = `${process.env.REACT_APP_DEFAULT_AVATAR}`;


  // get the month, day, year, time
  const now = new Date().toISOString();



  const userEstate = '55s1'
  //managment values form..............
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    perfil: "",
  });
  //...................................

  //reset form............................
  const [formKey, setFormKey] = useState(0);

  const handleFormReset = () => {
    setFormKey((prevKey) => prevKey + 1);
  };
  //...................................

  //.....managment focus errors.........................
  const [alert0, setAlert0] = useState(false)

  const LabelPassword = styled.label`
        color: ${alert0 ? 'red' : '#707070'};
    `;
  const LabelCurrent = styled.label`
        color: ${'#707070'};
    `;

  //manage text label password...
  const [isErrorPassword, setErrorPassword] = useState(`${labelPassword}`)
  const [isErrorPassword2, setErrorPassword2] = useState(`${labelConfirmPassword}`)

  //...................................................

  //..managment focus input............................
  const [labelText, setLabelText] = useState(false);

  function handleFocus() {
    setLabelText(true);
  }
  function handleBlur() {
    setLabelText(false);
    setAlert0(false)
    setErrorPassword('Contraseña')
    setErrorPassword2('Confirmar Contraseña')
  }
  //...................................................

  // managment send validation AUTH....................
  async function registerUser(name, email, password2, tipo_perfil) {
    setOpen(true);
    const infoUser = await createUserWithEmailAndPassword(
      auth,
      email,
      password2,
    ).then((usuarioFirebase) => {
      navigate('/login');
      // window.location.reload();
      return usuarioFirebase
    }).catch((error) => {
      setOpen(false);
      //close backdrop
      console.log(error);
      //active error alert
      toast.error('El Email Ya Se Encuentra En Uso!', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    // write in DataBase...........
    const docuRef = doc(firestore, `usuarios/${infoUser.user.uid}`);
    // encriptando email
    // Dato original
    const datoOriginal = user.email;
    // Cifrar el dato
    const emailCifrado = CryptoJS.AES.encrypt(datoOriginal, clave, { iv: iv }).toString();

    setDoc(docuRef, {
      avatar: default_avatar,
      S5p4r1bn3k: userEstate,
      fecha: now,
      correo: emailCifrado,
      tipo_perfil: tipo_perfil,
      name: name,
      id: infoUser.user.uid,
      perfil_complete: false,
      fileData_complete: false,
      perfil: false,
      buscamos: false,
      fileData: false,
      para_hacer: false,
      post: false,
      post_hidden: false,
      friends: false,
      blocked_people: false,
      verification: null,
      reported_users: false,
      favorite_users: false,
      notifications: false,
    })
  }
  //...................................................

  const submitHandler = (e) => {
    e.preventDefault();

    // ...regex input email
    const emailRegex = /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailRegex.test(user.email);

    if ( //Nombre completo............
      user.name.trim() === "" ||
      user.name === null ||
      user.name.length === 0
    ) {
      // setClassAlert0('class_1');
      // setErrorName(true)
      console.log('error name......')

    } else if// password and password2
      (user.password !== user.password2) {
      setAlert0(true)
      setErrorPassword('La Contraseña no Coincide')
      setErrorPassword2('La Contraseña no Coincide')
      console.log('error pasword......')
    } else {
      // console.log('Todo OK!', user)
      handleFormReset()
      registerUser(user.name, user.email, user.password2, user.perfil)
    }
  }

  return (
    <>
      <SimpleBackdrop open={open} />
      <ToastContainer />


      <ContSignUp >
        <ContFormSignUp>

          <Form key={formKey} onSubmit={submitHandler}>

            <DivTitle >
              <h1>{titleSingUp}</h1>
            </DivTitle >

            <ContInputSign>
              <LabelCurrent>{labelNickName}</LabelCurrent>
              <input
                id="name"
                type="text"
                name="name"
                className="normalClass"
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                required
              />
            </ContInputSign>
            <ContInputSign>
              <LabelCurrent>{labelEmail}</LabelCurrent>
              <input
                id="email"
                type="email"
                name="email"
                className="normalClass"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
              />
            </ContInputSign>

            <ContInputSign>
              <LabelPassword>{labelText ? 'Min. 8 Caracteres' : `${isErrorPassword}`}</LabelPassword>
              <input
                id="password"
                type="password"
                name="password"
                className={alert0 ? 'alertClass' : 'normalClass'}
                pattern=".{8,}"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </ContInputSign>
            <ContInputSign>
              <LabelPassword>{isErrorPassword2}</LabelPassword>
              <input
                id="password2"
                type="password"
                name="password2"
                className={alert0 ? 'alertClass' : 'normalClass'}
                pattern=".{8,}"
                onChange={(e) => setUser({ ...user, password2: e.target.value })}
              />
            </ContInputSign>

            <ContSelectSign>
              <label className='form_label'>{labelProfileType}</label>
              <select
                id="tipo_perfil" name="tipo_perfil" required
                onChange={(e) => setUser({ ...user, perfil: e.target.value })}
              >
                <option value="" hidden>{labelOptionHidden}</option>
                <option value='Pareja heterosexual'>Pareja heterosexual</option>
                <option value='Pareja (Ella Bisexual)'>Pareja (Ella Bisexual)</option>
                <option value='Pareja (El Bisexual)'>Pareja (El Bisexual)</option>
                <option value='Pareja bisexual'>Pareja bisexual</option>
                <option value='Mujer heterosexual'>Mujer heterosexual</option>
                <option value='Mujer bisexual'>Mujer bisexual</option>
                <option value='Hombre heterosexual'>Hombre heterosexual</option>
                <option value='Hombre bisexual'>Hombre bisexual</option>
              </select>
            </ContSelectSign>


            <ContCheckSignUp >
              <CheckboxInput type="checkbox" id="cbox1" value="first_checkbox" required />
              <p>{labelCheckBox1}<span> &nbsp;{ labelCheckBox2}</span></p>
            </ContCheckSignUp >

            <div>
              <BtnSignUp type="submit">{button}</BtnSignUp>
            </div>
          </Form>

          <ContPLink>
            <Link to="/login">{textLogin}</Link>
          </ContPLink>
        </ContFormSignUp>
      </ContSignUp >
    </>
  );
}

