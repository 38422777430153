import React, { useEffect, useState } from 'react'
//import styles
import { CheckBuscamos, SelectBuscamos } from '../styles/DatosPerfil'

const BuscamosEditar = ({ onHandleBuscamos, datosForm }) => {

    const {
        buscamos: {
            "Parejas (El Bisexual)": parejasElBisexual,
            "Mujeres heterosexuales": mujeresHeterosexuales,
            "Hombre heterosexual": hombreHeterosexual,
            "Parejas (Ella bisexual)": parejasEllaBisexual,
            "Parejas Bisexuales": parejasBisexuales,
            "Mujer Bisexual": mujerBisexual,
            "Parejas heterosexuales": parejasHeterosexuales,
            "Hombre bisexual": hombreBisexual
        }
    } = datosForm;

    //..managint select Buscamos.........
    //inicialisamos los select en false
    const [options, setOptions] = useState({
        'Parejas heterosexuales': parejasHeterosexuales ? true : false,
        'Mujeres heterosexuales': mujeresHeterosexuales ? true : false,
        'Parejas (Ella bisexual)': parejasEllaBisexual ? true : false,
        'Mujer Bisexual': mujerBisexual ? true : false,
        'Parejas (El Bisexual)': parejasElBisexual ? true : false,
        'Hombre heterosexual': hombreHeterosexual ? true : false,
        'Parejas Bisexuales': parejasBisexuales ? true : false,
        'Hombre bisexual': hombreBisexual ? true : false,
    });

    //recibe el evento y extrae el nombre de la opción y el estado actualizado
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        //actualizar el estado con la nueva selección.
        setOptions(prevOptions => ({
            ...prevOptions,
            [name]: checked
        }));
    };

    const handleChange = (event) => {
        //crea un nuevo objeto llamado allOptions que contiene todas las opciones, 
        //tanto las seleccionadas como las no seleccionadas
        const handleSave = () => {
            //obtiene un array de pares clave-valor del objeto
            //utiliza reduce para construir el objeto allOptions a partir de ese array
            const allOptions = Object.entries(options).reduce((acc, [key, value]) => {
                //Dentro de reduce, se asigna cada par clave-valor al nuevo objeto acc. 
                //Al final, se obtiene un objeto allOptions con todas las opciones, 
                //independientemente de si están seleccionadas (true) o no seleccionadas (false)
                acc[key] = value;
                return acc
            }, {});
            // se debuelve el objeto allOptions actualizado
            setOptions(allOptions);
        };
    };
    //enviamos el estado actualizado al padre
    useEffect(() => {
        onHandleBuscamos(options);
    }, [options])
    //...................................

    const optionList = Object.entries(options);

    return (
        <div>
            <SelectBuscamos>
                <label className='label1' >Buscamos</label>
                <span>
                    {
                        optionList.map(([clave, valor]) => (
                            <CheckBuscamos
                                onChange={handleChange}
                                style={{
                                    backgroundColor: valor ? '#205285' : '#FFFFFF',
                                    color: valor ? '#FFFFFF' : '#707070'
                                }}
                                key={clave}
                            >
                                <input
                                    type="checkbox"
                                    name={clave}
                                    checked={valor}
                                    onChange={handleCheckboxChange}
                                /><p>{clave}</p>
                            </CheckBuscamos>
                        ))}
                </span>
            </SelectBuscamos>
        </div>
    )
}

export default BuscamosEditar