import React, { useEffect, useState } from 'react'
import BasicTabs from './TabsNotifications';





const ChildNotifications = ({ userData }) => {

    //get user id from local storage
    const userId = localStorage.getItem('user');
    //state from friend request
    const [requestFriends, setRequestFriends] = useState([]);
    //filter data
    const filterData = userData.filter(item => item.id === userId);



    useEffect(() => {
        setTimeout(() => {
            const [primerElemento] = filterData;

            if (primerElemento !== undefined && primerElemento.notifications !== false) {
                const { notifications: { friendRequest } } = primerElemento;

                const elementosCoincidentes = userData.filter(objeto => {
                    // Comprueba si el id del objeto está presente como clave en algún objeto de objetoComparar
                    return friendRequest.some(obj => obj.hasOwnProperty(objeto.id));
                }).map(objeto => {
                    // Obtiene los elementos deseados del objeto coincidente y el valor correspondiente de objetoComparar
                    const clave = Object.keys(friendRequest.find(obj => obj.hasOwnProperty(objeto.id)))[0];
                    const valor = friendRequest.find(obj => obj.hasOwnProperty(objeto.id))[clave];

                    return {
                        id: objeto.id,
                        avatar: objeto.avatar,
                        name: objeto.name,
                        date: valor,
                        status: "friendRequest"
                    };
                });

                if (elementosCoincidentes === undefined) {
                    setRequestFriends(null);
                } else {
                    setRequestFriends(elementosCoincidentes);
                }

            } else {
                setRequestFriends(false);
            }
        }, 500);
    }, [userData])

    return (
        <>
            <br /><br />
            <BasicTabs requestFriends={requestFriends} />
        </>
    )
}

export default ChildNotifications