import React, { useEffect, useState } from 'react';
//import styles
import {
    ButtonF,
    ContAgrFotos,
    ContFaterImg,
    ContImgPrev,
    ContInputF,
    FileInput,
    FormFotos,
    SubTitleAgrF,
    TitleAgrF
} from '../styles/AgregarFotos';
//import firebase store
import app from '../firebase/credenciales';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
//import react router dom
import { useNavigate } from 'react-router-dom';
// toastify alert
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import component backdrop
import SimpleBackdrop from "../components/BackDrop";
//import data
import { spanish } from '../data/spanish';
// Obtén una referencia al almacenamiento de Firebase
const storage = getStorage();




const AgregarFotos = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { addPhotos: {
        titllePhotos,
        textPhotos,
        btnPhotos
    } } = textBody;


    // db firebase
    const firestore = getFirestore(app)
    //navigating to next page
    const navigate = useNavigate();
    // create and managint inputs
    const [fileInputs, setFileInputs] = useState(Array(4).fill(null));
    //managint preview image
    const [previews, setPreviews] = useState(Array(4).fill(null));
    //managint data form
    const [fileData, setFileData] = useState({});
    //managint avatar form
    const [avatar, setAvatar] = useState('');
    //wait to charge data
    const [open, setOpen] = useState(false);
    //
    const [eventTrigger, setEventTrigger] = useState(false);
    // pidiendo datos de local storage
    const userUid = localStorage.getItem('user')
    //shorted de console.log
    var c = console.log.bind(document);
    // c(previews)


    useEffect(() => {
        if (eventTrigger) {

            const sendFile = async () => {
                // write in DataBase
                const docuRef = doc(firestore, `usuarios/${userUid}`);
                //search in DataBase and type
                try {
                    await updateDoc(docuRef, {
                        fileData_complete: true,
                        fileData,
                        avatar
                    });
                    //navigating to next page
                    setOpen(false);
                    console.log('Documento actualizado exitosamente', fileData);
                    navigate('/home');
                } catch (e) {
                    console.log('Error al actualizar el documento:', e);
                }
            }
            sendFile()
        }
    }, [fileData])

    //................................................
    const handleFileInputChange = async (e, index) => {
        const file = e.target.files[0];
        // console.log(file.name)
        setFileInputs((prevState) => {
            const updatedFileInputs = [...prevState];
            updatedFileInputs[index] = file;
            return updatedFileInputs;
        });
        // addFiledb(file);
        previewFile(file, index);
    };
    //................................................

    //................................................
    // Función para subir la foto a Firebase Storage
    async function subirFoto(fotos) {

        try {
            const nombres = Object.keys(fotos);
            const urls = [];

            for (const nombre of nombres) {
                const foto = fotos[nombre];

                //send each photo to database
                const fileRef = ref(storage, `/images/${userUid}`);

                //subiendo datos al Storage
                const snapshot = await uploadBytesResumable(fileRef, foto);
                //pidiendo datos a Storage de la ubicacion de cada foto
                const urlPromise = await getDownloadURL(snapshot.ref)

                urls.push(urlPromise);
            };
            if (urls.length !== 0) {
                setAvatar(urls[0])
                setFileData(urls);
                setEventTrigger(true)
            }
        } catch (error) {
            // console.log('Error al cargar las fotos:', error);
        }
    }
    //................................................

    //.preview de imagen que se va a subir............
    const previewFile = (file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviews((prevState) => {
                const updatedPreviews = [...prevState];
                updatedPreviews[index] = reader.result;
                return updatedPreviews;
            });
        };
    };
    //................................................

    //managint form values............................
    const submitHandler = (e) => {
        //managint backdrop
        setOpen(true);
        e.preventDefault();
    }
    //................................................

    //funcion para bloquear el backdrop cuando se dispara toastify
    const handleToastClose = () => {
        setOpen(false);
    };

    //manejamos evento gestion imagenes 
    const sendImages = () => {

        //eliminando entradas vacias de el array
        const elementosNoNulos = fileInputs.filter(elemento => elemento !== null);

        // evitar que pasen datos vacios o en 0
        if (elementosNoNulos.length === 0) {
            toast.error('Agrega Por Lo Menos 1 Foto', {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: handleToastClose,
            });
        } else {
            const MayoresA1 = elementosNoNulos;
            //disparando la accion para subir las fotos a Storage
            subirFoto(MayoresA1)
        }
    }

    return (
        <>
            <ContAgrFotos >

                <ToastContainer />

                <SimpleBackdrop open={open} />

                <TitleAgrF>{titllePhotos}</TitleAgrF>
                <SubTitleAgrF>{textPhotos}</SubTitleAgrF>

                <FormFotos onSubmit={submitHandler}>

                    {fileInputs.map((file, index) => (
                        <ContFaterImg key={index}>
                            <ContImgPrev>
                                {previews[index] && <img src={previews[index]} alt="preview" />}
                            </ContImgPrev>
                            <ContInputF>
                                +
                                <FileInput type="file" onChange={(e) => handleFileInputChange(e, index)} />
                            </ContInputF>
                        </ContFaterImg>
                    ))}
                    <ButtonF onClick={sendImages} type="submit">{btnPhotos}</ButtonF>

                </FormFotos>
            </ContAgrFotos >
        </>
    )
}

export default AgregarFotos;