import styled from "styled-components";
import { Animation } from "./Assets";



export const ContSliderTab = styled.div`
    /* border: 1px solid red;//........................ */
    box-sizing: border-box;
    margin: .3em auto;
    display: flex;
    overflow-x: scroll;
    padding: 1em 0;
    width: 95%;
    max-width: 700px;
    scroll-snap-type: x mandatory;
    scroll-padding:1em;
    gap: .4em;
    @media (min-width:500px) {
        gap: 1em;
        justify-content: center;
    }
`
export const Buttons = styled.button`
    border: 1px solid var(--degradado3);//...............
    flex: 0 0 content;
    scroll-snap-align:start;
    border-radius:.7em;
    padding:.3em;
    background-color: var(--degradado3);
    color: var(--color6);
    font-size: 13px;
    cursor: pointer;
    &:last-child{
        background-color:transparent;
        border: 1px solid transparent;//...............
    }
    &:active{
        border: 1px solid white;//...............
    }
`
export const ContCardNot = styled.div`
    /* border: 1px solid red;//........................ */
    width: 95%;
    max-width: 550px;
    height: 5.5em;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`
export const ContImgSearchNot = styled.div`
    /* border:1px solid red;//................. */
    border-radius: 50%;
    width: 3.4em;
    height: 3.4em;
    display: grid;
    place-content: center;
    overflow: hidden;
    margin-right: 1.2em;
    /* animation background */
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${Animation};
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    /* .....................*/
    img{
        width: 103%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center 
    }
`
export const TitleCardNot = styled.p`
    color: var(--color6);
    font-size: .8em;
    font-weight: 700;
    margin: 0;
`
export const ConTextCardNot = styled.div`
    display: flex;
    color: var(--color2);
    font-size: .6em;
    p{
        padding: .2em;
        margin: 0;
    }
`
export const ButtonNot = styled.button`
    border: 1px solid var(--color-2);
    background-color: var(--color-2);
    color: var(--color3);
    font-weight: 500;
    font-size: 13px;
    border-radius: .6em;
    padding: .4em;
    cursor: pointer;
    &:active{
        border: .5px solid white;
    }
`