import React from 'react';
//import components
import NavBarLanding from '../components/NavBarLanding';
//import data
import { spanish } from '../data/spanish';
//import styles
import {
    ContNavBarS,
    ContTerms,
    ParagraphTerms,
    ParagraphTerms1,
    TitleTerms
} from '../styles/TermsConditions';
import FooterLanding from '../components/FooterLanding';

const TermsConditions = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring data body
    const { TermsAndConditions: { titleTerms, cards } } = textBody;
    //extract data from cards
    const card = Object.values(cards);


    return (
        <>
            <ContNavBarS />
            <NavBarLanding />
            <ContTerms>
                <TitleTerms>{titleTerms}</TitleTerms>

                <div>
                    {
                        card.map((item) =>
                            <div key={item.id}>
                                <ParagraphTerms1>{item.text1}</ParagraphTerms1>
                                <ParagraphTerms>{item.text2.split('\n').map((line, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    )
                                })}</ParagraphTerms>
                            </div>
                        )
                    }
                </div>

            </ContTerms>

            <FooterLanding />

        </>
    )
}

export default TermsConditions