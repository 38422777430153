export const spanish = [
    {
        landingPage: {
            section1: {
                banner1: "section1-landing/amigos-felices-club-1.png",
                mockUp: "section1-landing/image-movile-mockup.png",
                primaryText: "La red social líder para que solteros y parejas sexualmente libres compartan contenido con su círculo social swinger y conozcan personas con ideas a fines",
                btnSingUp: "Unete",
                linksNavLanding: {
                    text1: "Sobre nosotros",
                    text2: "Blog",
                    text3: "Afiliados"
                }
            },
            cards: {
                card1: {
                    id: "profile",
                    icon: "icons/Profile.png",
                    text1: "Perfil de pareja",
                    text2: "conoce diferentes tipos de perfiles de swingers",
                },
                card2: {
                    id: "lock",
                    icon: "icons/Lock.png",
                    text1: "Protección de la privacidad",
                    text2: "disfruta de un ambiente protegido y confiable para conocer swingers",
                },
                card3: {
                    id: "comment",
                    icon: "icons/Comment.png",
                    text1: "Chat",
                    text2: "Entabla conversaciones con tu círculo de amigos y contactos swinger",
                }
            },
            section3: {
                cards3: {
                    card1: {
                        id: "card-1",
                        img: "section3-landing/retrato-feliz-pareja-gay.png",
                        text1: false,
                    },
                    card2: {
                        id: "card-2",
                        img: false,
                        text1: "Sea quien sea, encuéntrelo aquí",
                    },
                    card3: {
                        id: "card-3",
                        img: "section3-landing/vida-nocturna-gente-bailando.png",
                        text1: false,
                    },
                    card4: {
                        id: "card-4",
                        img: "section3-landing/chicas-vista-frontal-tomando.png",
                        text1: false,
                    },
                    card5: {
                        id: "card-5",
                        img: "section3-landing/pareja-teniendo-momentos-intimidad.png",
                        text1: false,
                    },
                    card6: {
                        id: "card-6",
                        img: "section3-landing/pareja-poliamorosa.png",
                        text1: false,
                    },
                    card7: {
                        id: "card-7",
                        img: false,
                        text1: "Una red social compatible con LGBTQ+ para explorar tus deseos.",
                    },
                    card8: {
                        id: "card-8",
                        img: "section3-landing/amigos-sonrientes-haciendose.png",
                        text1: false,
                    },
                }
            },
            section4: {
                text1: '"Privacidad absoluta y diversión ilimitada para swingers: En nuestra red social, puedes disfrutar de momentos emocionantes sabiendo que tus datos personales están protegidos y tu identidad segura".'
            },
            section5: {
                cards5: {
                    card1: {
                        id: "card5-1",
                        text1: '“',
                        text2: "Cuando descubrí esta red social swinger, supe que había encontrado el lugar perfecto para explorar mis fantasías en un ambiente seguro y confiable.",
                        text3: '”',
                        text4: '-Andrea Andres'
                    },
                    card2: {
                        id: "card5-2",
                        text1: '“',
                        text2: "Es genial tener un espacio donde podamos ser notros mismos sin prejuicios.",
                        text3: '”',
                        text4: '-AmatistaSwinger'
                    },
                    card3: {
                        id: "card5-3",
                        text1: '“',
                        text2: "Desde que me uní a esta red social swinger, he encontrado un espacio seguro y respetuoso para explorar mi sexualidad.",
                        text3: '”',
                        text4: 'Parejaswcol'
                    }
                }
            },
            section6: {
                cards6: {
                    card1: {
                        id: "card6-1",
                        text1: '10 millones',
                        text2: "Usuarios",
                    },
                    card2: {
                        id: "card6-2",
                        text1: '2 millones',
                        text2: "miembros activos",
                    },
                    card3: {
                        id: "card6-3",
                        text1: '800.000',
                        text2: "mensajes enviados por mes",
                    },
                    card4: {
                        id: "card6-4",
                        text1: '10 millones',
                        text2: "Conexiones",
                    },
                }
            },
            footerLanding: {
                titleFooter1:'Legal',
                titleFooter2:'Redes',
                text1Footer: 'Privacidad',
                text2Footer: 'Términos y condiciones',
                text3Footer: 'Blog',
                text4Footer: 'Preguntas frecuentes',
                text5Footer: 'Sala de prensa',
                text6Footer: 'Contacto',
                text7Footer: 'Swirl Group, todos los derechos reservados.'
            }
        }, 
        buscador: {
            placeholder: 'Buscar',
            searchFilter: {
                text1Search: 'Encuentra Swingers',
                text2Search: 'Mostrando primero los mas recientes',
                text3Search: 'Filtros de busqueda',
            }
        },
        login:{
            labelEmail:'Email',
            labelPassword:'Contraseña',
            labelRemenber:'Recordar contraseña',
            button:'Entrar',
            textSingUp1:'No Tienes Cuenta?',
            textSingUp2:'Registrate'
        },
        singUp:{
            titleSingUp:'Datos de perfil',
            labelNickName:'NickName',
            labelEmail:'Email',
            labelPassword:'Contraseña',
            labelConfirmPassword:'Confirmar contraseña',
            labelProfileType:'Tipo de perfil',
            labelOptionHidden:'Seleccione el tipo de perfil',
            optionsProfile:{
                option1: 'Pareja heterosexual',
                option2: 'Pareja (Ella Bisexual)',
                option3: 'Pareja (El Bisexual)',
                option4: 'Pareja bisexual',
                option5: 'Mujer heterosexual',
                option6: 'Mujer bisexual',
                option7: 'Hombre heterosexual',
                option8: 'Hombre bisexual',
            },
            labelCheckBox1:'Acepto los',
            labelCheckBox2:'terminos y condiciones',
            button:'Registrar',
            textLogin:'Ya Tienes Una Cuenta?',
        },
        datosPerfil:{
            placeHolderSelect:'Seleccionar',
            titleDatosPerfil:'Completa tu perfil',
            labelAboutMe:'Sobre mi',
            labelCountry:'País de Residencia',
            labelCities:'Ciudad',
            labelNames:'Nombres',
            placeHolderNames1:'El',
            placeHolderNames2:'Ella',
            labelSelecP:'Privado',
            labelDate:'Fecha de nacimiento',
            labelOrientation:'Orientacion',
            labelRace:'Raza',
            labelNationality:'Nacionalidad',
            labelWeight:'Peso',
            labelHeight:'Estatura',
            labelBustSize:'Talla de busto',
            labelPenisLength:'Longitud del pene',
            labelTattoos:'Tatuajes',
            labelPiercings:'Piercings',
            labelSmoke:'Fuma',
            labelDrinking:'Bebe Alcohol',
            labelLanguages:'Idiomas',
            boton:'Guardar'
        },
        formOrientacion:{
            option1: 'Heterosexual',
            option2: 'Bisexual',
            option3: 'Pansexual',
            option4: 'No definido',
        },
        selectBuscamos:{
            titleBuscamos:'Buscamos',
            options:{
                option1: 'Pareja heterosexual',
                option2: 'Pareja (Ella Bisexual)',
                option3: 'Pareja (El Bisexual)',
                option4: 'Parejas Bisexuales',
                option5: 'Mujeres',
                option6: 'Mujer bisexual',
                option7: 'Hombre heterosexual',
                option8: 'Hombre bisexual',
            }
        },
        selectParaHacer:{
            titleParaHacer:'Para hacer',
            options:{
                option1: 'Intercambio completo',
                option2: 'Intercambio Light',
                option3: 'Sexo en grupo',
                option4: 'Trios',
                option5: 'Solo ellas',
                option6: 'Intercambio de fotos',
                option7: 'Mirar y ser vistos',
                option8: 'Cuckold',
                option9: 'Practicas BDSM',
                option10: 'Comportir fetiches',
                option11: 'Cybersexo',
                option12: 'Sexo por separado',
            }
        },
        addPhotos:{
            titllePhotos:'Fotografias',
            textPhotos:'Agrega por lo menos 1 foto aqui',
            btnPhotos:'Guardar',
        },
        navBarFooter:{
            textNavFooter1:'Inicio',
            textNavFooter2:'Notificaciones',
            textNavFooter3:'Mis Amigos',
            textNavFooter4:'Eventos',
            textNavFooter5:'Perfil',
            textNavFooter6:'Búsqueda',
            textNavFooter7:'Mensajes',
        },
        Messages:{
            textMessage1:'Tus mensajes',
            textMessage2:'Envía mensajes privados a un amigo',
        },
        NewUsers:{
            textNewUsers1:'Nuevos usuarios',
            textNewUsers2:'Ver todos',
        },
        Friends:{
            textFriends1:'Tus Amigos',
            textFriends2:'Aquí verás a todos tus amigos',
        },
        Events:{
            textEvents1:'Tus Eventos',
            textEvents2:'Aquí verás todos los eventos',
        },
        Notifications:{
            textNotifications1:'Tus Notificaciones',
            textNotifications2:'Aquí verás todas tus Notificaciones',
            TabsNotifications:{
                btnNoti1:'Todo',
                btnNoti2:'Comentarios',
                btnNoti3:'Menciones',
                btnNoti4:'Me gusta',
                btnNoti5:'Solicitud de amistad',
            },
            
        },
        TermsAndConditions:{
            titleTerms:'Swirl - Términos y condiciones ',
            cards:{
                card0:{
                    id: 'Terms_0',
                    text1:'1. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu.Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card1:{
                    id: 'Terms_1',
                    text1:'2. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card2:{
                    id: 'Terms_2',
                    text1:'3. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card3:{
                    id: 'Terms_3',
                    text1:'4. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card4:{
                    id: 'Terms_4',
                    text1:'5. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card5:{
                    id: 'Terms_5',
                    text1:'6. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card6:{
                    id: 'Terms_6',
                    text1:'7. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
                card7:{
                    id: 'Terms_7',
                    text1:'8. Lorem ipsum.',
                    text2:'Lorem ipsum dolor sit amet consectetur adipiscing elit orci nunc, placerat semper torquent felis sodales mus diam eu. Nec tempor fermentum egestas duis habitant quisque tempus facilisis libero interdum congue, sem neque nam non enim eleifend viverra hac eu ante ligula, tristique facilisi nibh taciti rhoncus suspendisse rutrum volutpat quis vel. Facilisi sagittis justo lectus placerat pretium vel diam nibh purus elementum, proin tempor suspendisse dignissim habitant sem lobortis auctor penatibus velit pulvinar, curabitur orci vehicula cras semper maecenas quis ultrices potenti.\n\nDictum ut aenean fusce arcu tristique condimentum conubia habitasse, ante nunc primis suscipit ligula sodales urna. Dignissim et inceptos eros montes habitant suscipit mollis metus tincidunt luctus ligula fames, ante sapien facilisi fusce tortor ultricies posuere fermentum aenean donec curabitur, maecenas nisl pulvinar cum mauris aliquam dui ut cubilia porta natoque. Quam nisl eget congue lectus cum curae duis turpis suspendisse aliquam, class magnis eleifend odio erat orci integer cubilia augue nam, bibendum ac platea litora cursus sapien et malesuada ridiculus. Neque lacinia non mollis tellus vel aenean, erat etiam maecenas tincidunt est ad, aliquam duis blandit mus netus.',
                },
            }
        },
    }
]