import styled from "styled-components";


export const ContAgrFotos = styled.div`
    /* border: 1px solid red;//................ */
    height: 100vh;
    width: 95%;
    max-width: 800px;
    margin: auto;
    background: var(--color3);
`
export const FormFotos = styled.form`
    /* border: 1px solid red;//.................... */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`
export const ButtonF = styled.button`
    width: 90%;
    margin: auto;
    height: 2.3em;
    background-color: var(--color1);
    color: var(--color3);
    font-size: 24px;
    border-radius:.6em;
    border: 2px solid transparent;
    cursor: pointer;
    font-weight: 600;
    margin-top: 1.3em;
    &:hover{
        background-color: var(--degradado2);
    }
`
export const TitleAgrF = styled.h1`
    /* border: 1px solid red;//................ */
    color: var(--color2);
    text-align: justify;
    font-size: 20px;
    padding-top: 2em;
    padding-left: .4em;
    font-weight: 700;
`
export const SubTitleAgrF = styled.p`
    color: var(--color2);
    font-size: 15px;
    padding-left: .4em;
`
export const ContFaterImg = styled.div`
    border: 2px dashed var(--color7);//................
    width: 45%;
    height: 42vw;
    max-height: 300px;
    border-radius: 1em;
    margin-bottom: .8em;
    background-color: var(--color8);
`
export const ContImgPrev = styled.div`
    /* border: 1px solid red;//................ */
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1em;
    img{
        /* border: 1px solid red;//................ */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
`
export const ContInputF = styled.div`
    /* border: 1px solid red;//................ */
    position: relative;
    bottom: 20px;
    float: right;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer!important;
    background-color: var(--degradado2);
    color: var(--color3);
	overflow: hidden;
`
export const FileInput = styled.input.attrs({
    type: "file",
})`
    /* border: 1px solid red;//................ */
    opacity: 0;
    z-index:1;
    width:100%;
    margin-top:-2em!important;
    cursor: pointer!important;
    + label {
        font-size: 0em;
        display: inline-block;
    }
`