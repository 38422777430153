import { Link } from "react-router-dom";
import styled from "styled-components";
import { Animation } from "./Assets";
import { Button, Dialog } from "@mui/material";


export const ContSearch = styled.div`
    position: relative;
    width: 95%;
    max-width: 850px;
    margin: auto;
    input{
        width: 100%;
        height: 3.2em;
        border-radius: 15px;
        border: 2px solid transparent;
        padding: 15px 15px 15px 45px;
        margin-bottom: .5em;
        background-color: var(--degradado3);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    svg{
        color: var(--color4);
        position: absolute;
        width: 27px;
        height: 27px;
        left: 12px;
        top: 45%;
        transform: translateY(-50%);
    }
`
export const ContTitle = styled.div`
    /* border: 1px solid red;//.................. */
    width: 95%;
    max-width: 850px;
    text-align: center;
    margin: auto;
    h1{
        color: var(--color2);
        font-size: 1.1em;
        margin:0;
    }
    p{
        color: var(--color4);
        font-size: .7em;
        margin:0;
    }
`
export const ContImgSearch = styled.div`
    /* border:1px solid red;//................. */
    border-radius: 50%;
    width: 3.4em;
    height: 3.4em;
    display: grid;
    place-content: center;
    overflow: hidden;
    margin-right: 1.2em;
    /* animation background */
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${Animation};
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    /* .....................*/
    img{
        width: 103%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center 
    }
`
export const ContCard = styled(Link)`
    border-bottom: 2px solid var(--degradado3) ;
    width: 95%;
    max-width: 850px;
    height: 5.5em;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
`
export const TitleCard = styled.p`
    color: var(--color6);
    font-size: .8em;
    font-weight: 700;
    margin: 0;
`
export const ConTextCard = styled.div`
    display: flex;
    color: var(--color2);
    font-size: .6em;
    p{
        padding: .2em;
        margin: 0;
    }
`
//filtro buscador
export const BtnFiltro = styled(Button)`
    &{
        font-weight: 900 !important;
        font-size: .8em !important;
        color: var(--color-2) !important;
        text-transform: none !important;;
    }
`
export const ContModalFilter = styled(Dialog)`
    /* border: 1px solid red;//.......................... */
    .MuiDialog-paper{
        margin: 0;
        width: 100%;
    }
`
export const ContForm = styled.div`
    /* border: 1px solid red;//.......................... */
    margin: 0 auto !important;
    width: 99%;
    max-width: 1100px;
    padding:0 .5em;
    .MuiDialogContent-root{
            padding: .9em;
    }
`
export const BtnClose = styled.div`
    /* border: 1px solid red;//................. */
    margin: 1em ;
    svg{
        font-size: 40px;
        cursor: pointer;
        color: var(--color9);
    }
`
export const BtnFilter = styled.button`
    width: 100%;
    height: 51px;
    border-radius: 15px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 700;
    color: var(--color3);
    background-color: var(--color1);
    cursor: pointer;
    &:hover{
        background-color: var(--degradado2);
    }
`
export const ContFilters = styled.div`
    border: 1.5px solid var(--color2);//.............
    border-radius: 1em;
    padding: 1em .2em;
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    span{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .label1{
        text-align:left;
        color:var(--color2);
        font-weight: 700;
        padding: 0 0 .5em .2em;
        font-size: 1.3em;
    }
`