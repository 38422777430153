import React, { useEffect, useState } from 'react'
//import styles
import { CheckBuscamos, SelectBuscamos } from '../styles/DatosPerfil';

const ParaHacerEditar = ({ onHandleParaHacer, datosForm }) => {

    const {
        para_hacer: {
            'Intercambio completo': intercambioCompleto,
            'Mirar y ser vistos': mirarYSerVistos,
            'Intercambio Light': intercambioLight,
            'Cuckold': cuckold,
            'Sexo en grupo': sexoEnGrupo,
            'Practicas BDSM': practicasBDSM,
            'Trios': trios,
            'Compartir fetiches': compartirFetiches,
            'Solo ellas': soloEllas,
            'Cybersexo': cyberSexo,
            'Intercambio de fotos': intercambioDeFotos,
            'Sexo por separado': sexoPorSeparado,
        }
    } = datosForm

    //..managint select 'Para hacer'.........
    //inicialisamos los select en false
    const [optionsHacer, setOptionsHacer] = useState({
        'Intercambio completo': intercambioCompleto ? true : false,
        'Mirar y ser vistos': mirarYSerVistos ? true : false,
        'Intercambio Light': intercambioLight ? true : false,
        'Cuckold': cuckold ? true : false,
        'Sexo en grupo': sexoEnGrupo ? true : false,
        'Practicas BDSM': practicasBDSM ? true : false,
        'Trios': trios ? true : false,
        'Compartir fetiches': compartirFetiches ? true : false,
        'Solo ellas': soloEllas ? true : false,
        'Cybersexo': cyberSexo ? true : false,
        'Intercambio de fotos': intercambioDeFotos ? true : false,
        'Sexo por separado': sexoPorSeparado ? true : false,
    });
    // console.log(optionsHacer)

    //recibe el evento y extrae el nombre de la opción y el estado actualizado
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        //actualizar el estado con la nueva selección.
        setOptionsHacer(prevOptions => ({
            ...prevOptions,
            [name]: checked
        }));
    };
    const handleChange = (event) => {
        //crea un nuevo objeto llamado allOptions que contiene todas las opciones, 
        //tanto las seleccionadas como las no seleccionadas
        const handleSave = () => {
            //obtiene un array de pares clave-valor del objeto
            //utiliza reduce para construir el objeto allOptions a partir de ese array
            const allOptionsHacer = Object.entries(optionsHacer).reduce((accion, [key, value]) => {
                //Dentro de reduce, se asigna cada par clave-valor al nuevo objeto accion. 
                //Al final, se obtiene un objeto allOptionsHacer con todas las opciones, 
                //independientemente de si están seleccionadas (true) o no seleccionadas (false)
                accion[key] = value;
                return accion
            }, {});
            // se debuelve el objeto allOptions actualizado
            setOptionsHacer(allOptionsHacer);
        };
        //...................................
    };
    //enviamos el estado actualizado al padre
    useEffect(() => {
        onHandleParaHacer(optionsHacer);
    }, [optionsHacer])

    const optionListHacer = Object.entries(optionsHacer);

    return (
        <>
            <SelectBuscamos >
                <label className='label1' >Para Hacer</label>
                <span>
                    {
                        optionListHacer.map(([clave, valor]) => (
                            <CheckBuscamos
                                onChange={handleChange}
                                style={{
                                    backgroundColor: valor ? '#205285' : '#FFFFFF',
                                    color: valor ? '#FFFFFF' : '#707070'
                                }}
                                key={clave}
                            >
                                <input
                                    type="checkbox"
                                    name={clave}
                                    checked={valor}
                                    onChange={handleCheckboxChange}
                                /><p>{clave}</p>
                            </CheckBuscamos>
                        ))}
                </span>
            </SelectBuscamos>
        </>
    )
}

export default ParaHacerEditar
