import React from 'react'
//import styles
import {
    ButtonNot,
    Buttons,
    ConTextCardNot,
    ContCardNot,
    ContImgSearchNot,
    ContSliderTab,
    TitleCardNot
} from '../styles/TabsNotifications';
import { ContNoNotifications } from '../styles/Notoficaciones';
import styled from 'styled-components';
//import functions from utils js
import { formatearFecha } from '../Utils';
import { spanish } from '../data/spanish';
//import icons material ui
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';







const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: -8,
        padding: '5px 1px 1px 1px',
        fontWeight: "900",
    },
}));

const TabsNotifications = ({ requestFriends }) => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { Notifications: {
        textNotifications1,
        textNotifications2,
        TabsNotifications: {
            btnNoti1,
            btnNoti2,
            btnNoti3,
            btnNoti4,
            btnNoti5
        } } } = textBody;


    //esta funcion almacenara y sumara todos los items recibidos por props
    const allNotifications = () => {

    }

    let ComponentToRender;


    const Component = (

        <>
            {
                requestFriends && requestFriends.map((item, index) => (
                    <ContCardNot key={index}>
                        <div>
                            <ContImgSearchNot><img src={item.avatar} alt="image_avatar" /></ContImgSearchNot>
                            <span>
                                <TitleCardNot>{item.name}</TitleCardNot>
                                <ConTextCardNot>
                                    <p>Te envio una solicitud de amistad</p>
                                </ConTextCardNot>
                                <ConTextCardNot>
                                    <p>{formatearFecha(item.date)}</p>
                                </ConTextCardNot>
                            </span>
                        </div>
                        <ButtonNot>Aceptar</ButtonNot>
                    </ContCardNot>
                ))
            }
        </>

    );

    ComponentToRender = Component;

    console.log(requestFriends)

    return (
        <>
            <ContSliderTab>
                <Buttons>
                    <StyledBadge
                        badgeContent={requestFriends.length}
                        color="primary"
                    >
                        {btnNoti1}
                    </StyledBadge>
                </Buttons>
                <Buttons>{btnNoti2}</Buttons>
                <Buttons>{btnNoti3}</Buttons>
                <Buttons>{btnNoti4}</Buttons>
                <Buttons>
                    <StyledBadge
                        badgeContent={requestFriends.length}
                        color="primary"
                    >
                        {btnNoti5}
                    </StyledBadge>
                </Buttons>
                <Buttons></Buttons>
            </ContSliderTab>

            {requestFriends ?
                ComponentToRender :
                <ContNoNotifications>
                    <NotificationsActiveIcon />
                    <h1>{textNotifications1}</h1>
                    <p>{textNotifications2}</p>
                </ContNoNotifications>
            }
        </>
    )
}

export default TabsNotifications