import React, { useEffect, useState } from 'react'
//styles
import {
    CheckboxInputDP,
    ContCheckDP,
    ContInputPerfil,
    ContSelectPerfil,
    CustomDateInput,
    InputDate,
    SelectCountry,
    TextAreaPerfil
} from '../styles/DatosPerfil';
//import Util.js
import {
    fetchCountries,
    fetchHeight,
    fetchLanguages,
    fetchPenisLength,
    fetchPiercings,
    fetchSizeBra,
    fetchSmokes,
    fetchTattoo,
    fetchWeigth
} from '../Utils';
// import commponents
import GeoSearchEdit from './GeoSearchEdit'

const PerfilFormEdit = ({ onHandlePerfilEdit, datosForm }) => {

    const { perfil: { about, city, country, name_his, name_her, date_his, date_her, orientation_his,
        orientation_her, race_her, race_his, country_his, country_her, weight_his, weight_her,
        height_her, height_his, sizeBra_her, penisLenght_his, tattoo_her, tattoo_his,
        piercing_her, piercing_his, smokes_her, smokes_his, drink_her, drink_his,
        languages_her, languages_his, privateUser
    } } = datosForm;

    //estate countrye data
    const [countries, setCountries] = useState([]);
    //estate countrye data
    const [languages, setLanguages] = useState([]);
    //estate weight data
    const [weight, setWeight] = useState([]);
    //estate Height data
    const [height, setHeight] = useState([]);
    //estate Smoke data
    const [smokes, setSmokes] = useState([]);
    //estate Penis Length
    const [penisLength, setPenisLength] = useState([]);
    //estate SizeBra
    const [sizeBra, setSizeBra] = useState([]);
    //estate Tattoos
    const [tattoos, setTattoos] = useState([]);
    //estate Piercings
    const [piercings, setPiercings] = useState([]);
    //estate of checked
    const [isChecked, setIsChecked] = useState(privateUser ? true : false);
    //state of country
    const [itemCountry, setItemCountry] = useState('');
    //state of city
    const [itemCity, setItemCity] = useState('');
    //managing form values..............
    const [perfil, setPerfil] = useState({
        about: about,
        city:itemCity,
        country:itemCountry,
        privateUser: isChecked,
        name_his: name_his,
        name_her: name_her,
        date_his: date_his,
        date_her: date_her,
        orientation_his: orientation_his,
        orientation_her: orientation_her,
        race_his: race_his,
        race_her: race_her,
        country_his: country_his,
        country_her: country_her,
        weight_his: weight_his,
        weight_her: weight_her,
        height_his: height_his,
        height_her: height_her,
        sizeBra_his: "N/A",
        sizeBra_her: sizeBra_her,
        penisLenght_his: penisLenght_his,
        penisLenght_her: "N/A",
        tattoo_his: tattoo_his,
        tattoo_her: tattoo_her,
        piercing_his: piercing_his,
        piercing_her: piercing_her,
        smokes_his: smokes_his,
        smokes_her: smokes_her,
        drink_his: drink_his,
        drink_her: drink_her,
        languages_his: languages_his,
        languages_her: languages_her,
    });
    // Establecer la edad mínima en 18 años
    const calculateMinDate = () => {
        const currentDate = new Date();
        const minDate = new Date();
        minDate.setFullYear(currentDate.getFullYear() - 18);
        return minDate.toISOString().split('T')[0];
    };
    //...................................

    //......default Value.select..........
    const defaultValue = 'N/A';
    //....................................

    //enviamos el estado actualizado al padre
    useEffect(() => {
        // console.log(perfil)
        onHandlePerfilEdit(perfil);
    }, [perfil])
    //.......................................

    // import country data from Utils.js.......
    useEffect(() => {
        setLanguages(fetchLanguages());
        setWeight(fetchWeigth());
        setHeight(fetchHeight());
        setSmokes(fetchSmokes());
        setPenisLength(fetchPenisLength());
        setSizeBra(fetchSizeBra());
        setTattoos(fetchTattoo());
        setPiercings(fetchPiercings());
        setIsChecked(privateUser);

        const fetchCountriesData = async () => {
            try {
                const data = await fetchCountries();
                setCountries(data);
            } catch (error) {
                // Manejar el error según tus necesidades
            }
        };
        fetchCountriesData();

    }, []);
    //.......................................

    //..managing state check................
    const handleCheckbox = (event) => {
        const isChecked = event.target.checked;
        setPerfil((prevValores) => ({
            ...prevValores,
            privateUser: isChecked
        }));
    };
    //.......................................

    //cargamos los valores del formulario....
    const handleChange = (event) => {
        const { name, value } = event.target;
        setPerfil((prevValores) => ({
            ...prevValores,
            [name]: value
        }));
    }
    //.......................................

    //.managint state country and city ......
    useEffect(() => {
        setPerfil((prevPerfil) => ({
            ...prevPerfil,
            country: itemCountry,
            city: itemCity,
        }));
    }, [itemCountry, itemCity]);

    const handleRecibirEstados = (estado1, estado2) => {
        // Lógica para manejar los estados recibidos desde el hijo
        setItemCountry(estado2);
        setItemCity(estado1);
    };
    //.......................................

    const formulario = (
        <span >
            <ContInputPerfil>
                <label>Sobre mi</label>
                <TextAreaPerfil
                    type="about" name="about" value={perfil.about || about}
                    onChange={handleChange}
                ></TextAreaPerfil>
            </ContInputPerfil>{/* about */}

            <GeoSearchEdit city={city} country={country} onEnviar={handleRecibirEstados} />{/* country and city */}

            <ContInputPerfil>
                <label>Nombres</label>
                <CustomDateInput>
                    <input
                        type="text" name="name_his" className="normalClass"
                        value={perfil.name_his || name_his}
                        onChange={handleChange}

                    />
                    <input
                        type="text" name="name_her" className="normalClass"
                        value={perfil.name_her || name_her}
                        onChange={handleChange}

                    />
                </CustomDateInput>
            </ContInputPerfil>{/* name */}

            <ContCheckDP>
                <CheckboxInputDP
                    type="checkbox"
                    checked={perfil.privateUser}
                    onChange={handleCheckbox}
                /><span>Privado</span>
            </ContCheckDP>{/* checkbox private */}

            <ContInputPerfil>
                <label>Fecha de nacimiento</label>
                <CustomDateInput>
                    <InputDate
                        type="date" name="date_his" className="normalClass"
                        value={perfil.date_his || date_his}
                        onChange={handleChange}
                        max={calculateMinDate()} // Establecer la fecha máxima permitida

                    />
                    <InputDate
                        type="date" name="date_her" className="normalClass"
                        value={perfil.date_her || date_her}
                        onChange={handleChange}
                        max={calculateMinDate()} // Establecer la fecha máxima permitida

                    />
                </CustomDateInput>
            </ContInputPerfil>{/* date */}

            <ContSelectPerfil>
                <label className='form_label'>Orientacion</label>
                <div>
                    <select
                        name="orientation_his"
                        onChange={handleChange}
                    >
                        <option value="" hidden>{orientation_his}</option>
                        <option value='heterosexual'>Heterosexual</option>
                        <option value='Bisexual'>Bisexual</option>
                        <option value='Pansexual'>Pansexual</option>
                        <option value='No definido'>No definido</option>
                    </select>
                    <select
                        name="orientation_her"
                        onChange={handleChange}
                    >
                        <option value="" hidden>{orientation_her}</option>
                        <option value='heterosexual'>Heterosexual</option>
                        <option value='Bisexual'>Bisexual</option>
                        <option value='Pansexual'>Pansexual</option>
                        <option value='No definido'>No definido</option>
                    </select>
                </div>
            </ContSelectPerfil>{/* orientation */}

            <ContSelectPerfil>
                <label className='form_label'>Raza</label>
                <div>
                    <select
                        name="race_his"
                        onChange={handleChange}
                    >
                        <option value="" hidden>{race_his}</option>
                        <option value="Option 1" >Option 1</option>
                        <option value="Option 2" >Option 2</option>
                    </select>
                    <select
                        name="race_her"
                        onChange={handleChange}
                    >
                        <option value="" hidden>{race_her}</option>
                        <option value="Option 1" >Option 1</option>
                        <option value="Option 2" >Option 2</option>
                    </select>
                </div>
            </ContSelectPerfil>{/* race*/}

            <ContSelectPerfil>
                <label className='form_label'>Nacionalidad</label>
                <div>
                    <SelectCountry>
                        <select
                            id='nationality'
                            name="country_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{country_his}</option>
                            {countries.map((country) => (
                                <option key={country.name} value={country.name}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id='nationality'
                            name="country_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{country_her}</option>
                            {countries.map((country) => (
                                <option key={country.name} value={country.name}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* country */}

            <ContSelectPerfil>
                <label className='form_label'>Peso</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="weight_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{weight_his}</option>
                            {weight.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>

                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="weight_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{weight_her}</option>
                            {weight.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* weight */}

            <ContSelectPerfil>
                <label className='form_label'>Estatura</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="height_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{height_his}</option>
                            {height.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="height_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{height_her}</option>
                            {height.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* height */}

            <ContSelectPerfil>
                <label className='form_label'>Talla de pecho</label>
                <div>
                    <select
                        id="sizeBra_his"
                        name="sizeBra_his"
                        value={defaultValue}
                        disabled
                        onChange={handleChange}
                    >
                        <option disabled >N/A</option>
                    </select>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="sizeBra_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{sizeBra_her}</option>
                            {sizeBra.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* sizeBra */}

            <ContSelectPerfil>
                <label className='form_label'>Longitud del pene</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="penisLenght_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{penisLenght_his}</option>
                            {penisLength.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <select
                        id="nationality"
                        name="penisLenght_her"
                        className="normalClass"
                        value={defaultValue}
                        onChange={handleChange}
                    >
                        <option disabled >N/A</option>
                    </select>
                </div>
            </ContSelectPerfil>{/* penisLenght */}

            <ContSelectPerfil>
                <label className='form_label'>Tatuajes</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="tattoo_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{tattoo_his}</option>
                            {tattoos.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="tattoo_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{tattoo_her}</option>
                            {tattoos.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* tattoo */}

            <ContSelectPerfil>
                <label className='form_label'>Piercings</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="piercing_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{piercing_his}</option>
                            {piercings.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="piercing_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{piercing_her}</option>
                            {piercings.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* piercing*/}

            <ContSelectPerfil>
                <label className='form_label'>Fuma</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="smokes_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{smokes_his}</option>
                            {smokes.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="smokes_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{smokes_her}</option>
                            {smokes.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* smokes*/}

            <ContSelectPerfil>
                <label className='form_label'>Bebe Alcohol</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="drink_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{drink_his}</option>
                            {smokes.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="drink_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" hidden>{drink_her}</option>
                            {smokes.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* drink */}

            <ContSelectPerfil>
                <label className='form_label'>Idiomas</label>
                <div>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="languages_his"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" disabled selected>{languages_his}</option>
                            {languages.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                    <SelectCountry>
                        <select
                            id="nationality"
                            name="languages_her"
                            className="normalClass"
                            onChange={handleChange}
                        >
                            <option value="" disabled selected>{languages_her}</option>
                            {languages.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </SelectCountry>
                </div>
            </ContSelectPerfil>{/* languages */}
        </span >
    );

    return (
        <>
            <span>{formulario}</span>
        </>
    )
}

export default PerfilFormEdit