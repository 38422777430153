import React, { useContext, useEffect, useState } from 'react';
//import components
import NavBarAux from '../components/NavBarAux';
import NavBarFooter from '../components/NavBarFooter';
import ChildNotifications from '../components/ChildNotifications';
//import global context
import MyContext from '../Context/MyContext';

const Notificaciones = () => {

    //get data from useContext
    const userData = useContext(MyContext);
    // estado que maneja la espera de carga de los datos 
    const [chargeData, setChargeData] = useState(false);

    useEffect(() => {
        if (userData) {
            setTimeout(()=>{
                setChargeData(true)
            }, 500)
        }
    }, [userData])

    return (
        <>
            <NavBarAux propName={'Notificaciones'} />
            {chargeData && <ChildNotifications userData={userData} />}

            <NavBarFooter propName={'Notificaciones'}/>
        </>
    )
}

export default Notificaciones