import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
//import react router dom
import { Link } from 'react-router-dom';
//import logo from data
import Logo11 from '../images/Logos-Swirl/logo-swirl-11.png';
//import styles
import { ContLink, ContLogo, ContMenuHamburguer, ContNavLanding } from '../styles/NavBarLanding';
//import static data
import { spanish } from '../data/spanish';
//import logo from material ui
import MenuMobileLanding from './MenuMobileLanding';




const NavBarLanding = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring data body
    const { landingPage: {
        section1: {
            linksNavLanding: { text1, text2, text3 } } }
    } = textBody;

    //Animation Nav bar........................
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            if (position > window.innerHeight / 10) {
                setShowAnimation(true);
            } else {
                setShowAnimation(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    //Animation Nav bar........................

    return (
        <ContNavLanding showAnimation={showAnimation} >
            <ContLogo>
                <Link to='/'><img src={Logo11} alt="" /></Link>
            </ContLogo>
            <ContLink>
                <Link>{text1}</Link>
                <Link to='https://www.go2swirl.com/blog/'
                    target="_blank"
                    rel="noopener noreferrer"
                >{text2}</Link>
                {/* <Link to='/login'>{text3}</Link> */}
            </ContLink>

            <ContMenuHamburguer>
                <MenuMobileLanding />
            </ContMenuHamburguer>
        </ContNavLanding >
    )
}

export default NavBarLanding