import React, { useContext, useEffect, useState } from 'react'
//import styles
import { ArrowNav, ContConver, ContImgNav, ContMenuEdit, ContNavBarA, IconDots, SingleIcon } from '../styles/NavBarAux'
//import icons material ui
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EditIcon from '@mui/icons-material/Edit';
// import global context
import MyContext from '../Context/MyContext';
// import react router dom
import { Link, useParams } from 'react-router-dom';


const NavBarAux = ({ propName }) => {

    //estado nombre nav bar
    const [nameU, setNameU] = useState();
    //estado iconos
    const [iconDots, setIconDots] = useState(false);
    const [singleIcon, setSingleIcon] = useState(false);
    const [listIcon, setListIcon] = useState(false);
    const [imgIcon, setImgIcon] = useState(false);
    const [name, setName] = useState(false);
    const [arrow, setArrow] = useState(true);
    const [editar, setEditar] = useState(false);

    //get data from useContext
    const userData = useContext(MyContext);
    // Obtenemos el Id desde la URL actual utilizando el hook useParams()
    const { id } = useParams();
    //get user id from local storage
    const guardado = localStorage.getItem('userD');
    const data = JSON.parse(guardado)


    //logica que gestiona lo que se muestra en la nav bar de acuerdo de en donde se encuentre
    useEffect(() => {
        if (propName) {
            //import prop from component father
            switch (propName) {
                case 'Buscar':
                    setNameU(propName);
                    setSingleIcon(true);
                    break;
                case 'Nuevos Usuarios':
                    setNameU(propName);
                    setSingleIcon(true);
                    break;
                case 'Mensajes':
                    setNameU(propName);
                    setListIcon(true);
                    break;
                case 'Editar Perfil':
                    setName(propName);
                    setEditar(true);
                    setArrow(false);
                    setNameU(false);
                    break;
                case 'Notificaciones':
                    setNameU(propName);
                    setSingleIcon(true);
                    break;
                case 'Mis Amigos':
                    setNameU(propName);
                    setSingleIcon(true);
                    break;
                default:
                    setNameU(propName);
                    setIconDots(true);
                    break;
            }
        } else if (id && name === data.name) {
            // Obtengo el string previamente salvado
            const userInfo = userData.find(item => item.id === id);
            if (userInfo) {
                const { name } = userInfo;
                setIconDots(true);
                setName(name);
            }
        } else if (id) {
            const userInfo = userData.find(item => item.id === id);
            if (userInfo) {
                const { name, avatar } = userInfo;
                setSingleIcon(true);
                setName(name);
                setImgIcon(avatar);
            }
        }
    }, [propName, id])

    // force window to load on startup
    function handleClick(e) {
        e.preventDefault();
        window.history.back();
    }

    return (
        <ContNavBarA>
            {arrow ?
                <ArrowNav onClick={handleClick}><ArrowBackIosNewIcon />
                    {imgIcon ?
                        <ContConver>
                            <ContImgNav><img src={imgIcon} alt="image_avatar" /></ContImgNav>
                            <p>{name}</p>
                        </ContConver>
                        : ''
                    }
                </ArrowNav>
                : ''}

            {editar ?
                <ContMenuEdit>
                    <Link onClick={handleClick}>Cancelar</Link>
                    <h1>{name}</h1>
                    <p>Listo</p>
                </ContMenuEdit>
                : ''}

            <p>{nameU}</p>
            {iconDots ? <IconDots><MoreHorizIcon /></IconDots> : ''}
            {listIcon ?
                <span>
                    <IconDots><FormatListBulletedIcon /></IconDots>
                    <IconDots><EditIcon /></IconDots>
                </span>
                : ''
            }
            {singleIcon ? <SingleIcon><MoreHorizIcon /></SingleIcon> : ''}


        </ContNavBarA>
    )
}

export default NavBarAux