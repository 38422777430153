import * as React from 'react';
//import material ui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// hamburguer menu from material ui
import MenuIcon from '@mui/icons-material/Menu';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
//import styles
import { CloseImg, ContMenuMobile, LinkMenu, Prueba } from '../styles/MenuMobile';
import { TextAcordion } from '../styles/MenuMobile';
// Accordion from material ui
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import AUTH firebase
import AuthFirebase from "../firebase/credenciales";
import { getAuth } from 'firebase/auth'



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))
    (({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}{...props} />
))
    (({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .00)',
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(0),
        },
    }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),

    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function MenuMobile() {

    //autentication firebase
    const auth = getAuth(AuthFirebase);
    //funtion to close session an reload window
    const handelClick = () => {
        auth.signOut();
        localStorage.clear('user');
        // window.location.reload();
    }
    //get userId from local storage
    const userId = localStorage.getItem('user');

    const [state, setState] = React.useState({
        right: false,
    });

    // ................reload next page....................
    const handleReload = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1);
    }
    // ....................................................

    // ....................................................
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel, props) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    // ....................................................

    // ....................................................
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    // ....................................................


    const list = (anchor) => (
        <Box
            className='SlideMenu'
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {[''].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <CloseImg>
                                <CloseSharpIcon className='closeBtn' onClick={toggleDrawer(anchor, false)} />
                            </CloseImg>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            {/* <Divider /> */}

            <Prueba expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <TextAcordion sx={{ fontWeight: 700 }}>menu acordeon</TextAcordion>
                </AccordionSummary>
                <AccordionDetails>
                    <Link onClick={toggleDrawer(anchor, false)} to='url'>menu acordeon</Link>
                </AccordionDetails>
            </Prueba>

            <LinkMenu>
                <Link onClick={handleReload} to={`/mi-perfil/${userId}`}>Mi Perfil</Link>
            </LinkMenu>

            <LinkMenu onClick={handelClick}>
                <Link onClick={toggleDrawer(anchor, false)} to='url'>Cerrar Sesión</Link>
            </LinkMenu>

        </Box>
    );

    return (
        <>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    <Button className='contMenuHamburguer'
                        onClick={toggleDrawer(anchor, true)}
                    >
                        <MenuIcon className="menuserIduHamburguer" />
                    </Button>
                    <ContMenuMobile
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </ContMenuMobile>
                </React.Fragment>
            ))}
        </>
    );
}