import React, { useContext, useEffect, useState } from 'react';
//import components
import NavBar from '../components/NavBar';
import SimpleBackdrop from '../components/BackDrop';
import NavBarFooter from '../components/NavBarFooter';
import NuevosUsuarios from '../components/NuevosUsuarios';
//import global context
import MyContext from '../Context/MyContext';
//import react router dom
import { useNavigate } from 'react-router-dom';
//import styles
import { ContGeneral } from '../styles/Home';





export const Home = () => {

  //get data from useContext
  const userData = useContext(MyContext);
  //event management to dropdown
  const [open, setOpen] = useState(true)
  //get data from localStorage
  const userId = localStorage.getItem('user');
  //navigate to other page
  const navigate = useNavigate();




  useEffect(() => {
    //filter data to get user
    const dataUser = userData.find(item => item.id === userId);
    if (dataUser) {
      setOpen(false);

    }

  }, [userData])



  return (
    <section>
      <SimpleBackdrop open={open} />

      <NavBar />
      <ContGeneral>
        <NuevosUsuarios />
      </ContGeneral>

      <NavBarFooter propName={'Home'} />

    </section>
  )
}

