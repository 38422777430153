import React from 'react';
//import components
import NavBarAux from '../components/NavBarAux';
import PerfilOtroUsuario from './PerfilOtroUsuario';

const MIPerfil = () => {



    return (
        <>
            <NavBarAux />
            <PerfilOtroUsuario />
        </>
    )
}

export default MIPerfil