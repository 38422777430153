import styled from "styled-components";

export const ContNavBarS = styled.div`
    width: 100%;
    height: 100px;
    background-color: var(--degradado1);
`
export const ContTerms = styled.section`
    border: 1px solid var(--color8);
    padding: 1em;
    margin:3em auto;
    width: 90%;
    max-width: 940px;
`
export const TitleTerms = styled.h1`
    color: var(--color2);
`
export const ParagraphTerms1 = styled.p`
    color: var(--color4);
    padding-left: .7em;
    font-weight: 600;
    font-size: 1em;
    
`
export const ParagraphTerms = styled.p`
    color: var(--color2);
    padding-left: 1.2em;
    @media (max-width:800px) {
        font-size: 14px
    }
`