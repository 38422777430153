import { Link } from "react-router-dom";
import styled from "styled-components";


export const ContBtn = styled.section`
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
`
export const Btn1 = styled(Link)`
    background-color: var(--color6);
    color: white;
    width: 47%;
    height: 38px;
    border-radius:.6em;
    cursor: pointer;
    font-weight: 800;
    display: grid;
    place-items: center;
    text-decoration: none;
`
export const BtnEditar = styled(Link)`
    background-color: var(--color4-1);
    margin: auto;
    color: white;
    width: 95%;
    height: 38px;
    border-radius:.6em;
    cursor: pointer;
    font-weight: 800;
    display: grid;
    place-items: center;
    text-decoration: none;
    margin-bottom: 1em;
    /* Código para sombra compatible con todos los navegadores */
    box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
`
