import React, { useContext, useEffect, useState } from 'react';
//import Context
import MyContext from '../Context/MyContext';
//Styles
import {
    CardUserH,
    ContCardsH,
    ContImgH,
    ContTitle,
    ContUsuariosH
} from '../styles/NuevosUsuarios';
// import react router dom
import { Link } from 'react-router-dom';
//import functions from utils js
import { compararFechas } from '../Utils';
import { spanish } from '../data/spanish';
//import commponents
import SkeletonNuevosUsuarios from './Skeletons/SkeletonNuevosUsuarios';




const NuevosUsuarios = ({ data }) => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { NewUsers: { textNewUsers1, textNewUsers2 } } = textBody;

    var c = console.log.bind(document);

    //get data from useContext
    const userData = useContext(MyContext);
    //destructuring data
    const [destructuring] = userData;

    //managing load image....................
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };
    //.......................................

    //managint data users
    const [dataUsers, setDataUsers] = useState([]);

    useEffect(() => {
        if (destructuring) {
            const filtrarUsuarios = () => {
                // pidiendo datos de local storage
                const userUid = localStorage.getItem('user')
                //filtramos usuarios que no contengan 'fileData' 
                //y sacamos del array al usuario activo 
                const filterData = userData.filter(item => item.id !== userUid);
                //sacamos del array a usuarios con perfil vacio
                const usersFullPerfil = filterData.filter(item => item.perfil !== false);

                setDataUsers(usersFullPerfil.sort(compararFechas));
            };
            filtrarUsuarios();
        }
    }, [userData]);


    const [showCount, setShowCount] = useState(5);

    const handleClick = () => {
        setShowCount(showCount + 3);
    };

    const renderedElements = dataUsers.slice(0, showCount).map((item) => {
        return (
            <>
                <CardUserH key={item.id + 1} to={`/perfil-otros-usuarios/${item.id}`} >

                    <ContImgH>
                        <img
                            src={item.avatar}
                            alt="user_image"
                            onLoad={handleImageLoad}
                            style={{ display: loaded ? 'block' : 'none' }} />
                    </ContImgH>

                    <p>{item.name}</p>
                </CardUserH>
            </>

        );
    });

    return (
        <>
            <ContUsuariosH>
                <ContTitle>
                    <p>{textNewUsers1}</p>
                    {showCount < dataUsers.length && (
                        <Link to='/nuevos-usuarios'>{textNewUsers2}</Link>
                    )}
                </ContTitle>
                {
                    dataUsers.length > 0 ?
                        <ContCardsH>{renderedElements}</ContCardsH> :
                        <SkeletonNuevosUsuarios />
                }
            </ContUsuariosH>
        </>
    );
};

export default NuevosUsuarios;
