/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
//import styles
import {
    CardsLand,
    CardsSection3,
    CardsSection5,
    CardsSection6,
    ContCardsLand,
    ContCardsSection3,
    ContCardsSection5,
    ContCardsSection6,
    ContMockUp,
    ContRect1,
    ContSection,
    ImgRect1,
    MockUpImage,
    MockUpText
} from '../styles/LandingHome';
//import componets
import NavBarLanding from '../components/NavBarLanding';
//import react router dom
import { Link } from 'react-router-dom';
//import data body
import { spanish } from '../data/spanish';
// import component footer landing
import FooterLanding from '../components/FooterLanding';




const LandingHome = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring data body
    const { landingPage: {
        cards,
        section1: { banner1, mockUp, primaryText, btnSingUp },
        section3: { cards3 },
        section4: { text1 },
        section5: { cards5 },
        section6: { cards6 },
    } } = textBody;
    //destructuring cards
    const card = Object.values(cards);
    const card3 = Object.values(cards3);
    const card5 = Object.values(cards5);
    const card6 = Object.values(cards6);

    return (
        <>
            <NavBarLanding />
            <ContRect1>
                <ImgRect1 src={require(`../images/${banner1}`)} alt="image-landingHome" />
            </ContRect1>

            <ContMockUp>
                <MockUpText>
                    <h1>{primaryText}</h1>
                    <div><Link>{btnSingUp}</Link></div>
                </MockUpText>
                <MockUpImage className='odd'>
                    <div><img src={require(`../images/${mockUp}`)} alt="image-mockup" /></div>
                </MockUpImage>
            </ContMockUp>

            <ContCardsLand>
                {
                    card.map((item) =>
                        <CardsLand key={item.id}>
                            <img src={require(`../images/${item.icon}`)} alt={`image-${item.id}`} />
                            <p>{item.text1}</p>
                            <p>{item.text2}</p>
                        </CardsLand>
                    )
                }
            </ContCardsLand>

            <ContCardsSection3>
                {
                    card3.map((item) =>
                        <CardsSection3 key={item.id}>
                            {
                                item.img ?
                                    <img src={require(`../images/${item.img}`)} alt={`image-${item.id}`} />
                                    : ''
                            }
                            {item.text1 ? <p>{item.text1}</p> : ''}
                        </CardsSection3>
                    )
                }
            </ContCardsSection3>

            <ContSection>
                <p>{text1}</p>
            </ContSection>

            <ContCardsSection5>
                {
                    card5.map((item) =>
                        <CardsSection5 key={item.id}>
                            <span>{item.text1}</span>
                            <p className='CommentText'>
                                {item.text2}
                            </p>
                            <span><p>{item.text3}</p></span>
                            <p className='userNick'>{item.text4}</p>
                        </CardsSection5>
                    )
                }
            </ContCardsSection5>

            <ContCardsSection6>
                {
                    card6.map((item) =>
                        <CardsSection6 key={item.id}>
                            <p>{item.text1}</p>
                            <p>{item.text2}</p>
                        </CardsSection6>
                    )
                }
            </ContCardsSection6>

            <FooterLanding/>

        </>
    )
}

export default LandingHome
