import styled from "styled-components";


export const ContSectionP = styled.section`
    /* border:1px solid red;//................. */
    width: 95%;
    max-width: 850px;
    margin: auto;
    margin-top: 4em;
`
export const ContImgParagraph = styled.div`
    /* border:1px solid red;//................. */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
`
export const ContImgP = styled.div`
    /* border:1px solid red;//................. */
    border-radius: 50%;
    width: 6.3em;
    height: 6.3em;
    display: grid;
    place-content: center;
    overflow: hidden;
    background-color: #eee;
    img{
        width: 103%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center 
    }
`
export const ParagraphP1 = styled.div`
    /* border:1px solid red;//................. */
    width: 70%;
    color: var(--color2);
    p{
        :nth-child(1){
        font-size: 15px;
        font-weight: 700;
        margin: 0;
    }
        :nth-child(2){
        /* border:1px solid red;//................. */
        font-size: 12.7px;
        margin: 0;
        }
    }
`
export const ContTable = styled.table`
    /* border: 1px solid red;//.................. */
    width: 100%;
    height: 5px;
    border-collapse: collapse;
    
    tr:nth-child(-n + 3){
        /* border: 1px solid red;//.................. */
    }

    .class_tr{
        td:nth-child(2){
            color: var(--color9);
            font-weight: 600;
        }
        td:nth-child(3){
            color: var(--color-1);
            font-weight: 600;
        }
    }
`
export const TableTitle = styled.td`
    color: var(--color2);
    font-weight: 700;
    font-size: .9em;
`
export const TableCat1 = styled.td`
    color: var(--color9);
    font-weight: 700;
    font-size: .9em;
`
export const TableCat2 = styled.td`
    color: var(--color-1);
    font-weight: 700;
    font-size: .9em;
`
export const TableTd = styled.td`
    color: var(--color2);
    font-size: .9em;
`
export const ContBusca = styled.section`
    border: 1px solid var(--color2);
    border-radius: .8em;
    padding: .7em 0;
    margin: 1em 0;
    .class_title{
        margin: 0;
        padding-left: .4em;
        color: var(--color2);
        font-weight: 700;
    }
    .class_white{
        color: var(--color2);
    }
    .class_blue{
        border-radius: .4em;
        background-color: var(--color1);
        color: var(--color3);
    }
`
export const ContItemsBusca = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    p{
        width:47%;
        padding:.2em 0 .2em .2em;
        margin: .2em 0;
        font-size: .8em;
    }
`
export const ContTr = styled.tr`
    td:nth-child(2){
        border-right: 2px solid var(--degradado3);
        margin-right:.5em;
    }
    td:nth-child(3){
        padding-left:.8em;
    }

`
export const ContBtn = styled.div`
    /* border: 1px solid red;//...................... */
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--color2);
    svg{
        font-size:2.6em;
        float: right;
    }
`