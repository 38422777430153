import styled from "styled-components";

export const ContRect1 = styled.div`
    /* border: 1px solid red;//.................... */
    overflow: hidden;
    width: 100%;
    height: 1000px;

    position: relative;
    display: inline-block;
    @media (max-width:1500px) {
        height:900px;
    }
    @media (max-width:1400px) {
            height: 800px;
        }
    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color10);
        z-index: 1;
        opacity: 0.7;
    }
`
export const ImgRect1 = styled.img`
    object-fit: cover;
    display: block;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-position: 0% center;
    @media (max-width:600px) {
        width: auto;
    }
`
export const ContMockUp = styled.div`
    /* border: 1px solid red;//.................... */
    width: min(100%, 1600px);
    display: flex;
    flex-direction:row;
    justify-content: space-around;
    align-items: flex-end;
    position: absolute;
    height: 1000px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    top: 0;
    @media (max-width:1000px) {
        flex-direction:column;
        justify-content: flex-end;
        align-items: center;
        /* height: 800px; */
        margin: 0;
        
    }
    @media (max-width:1500px) {
        height:900px;
    }
    @media (max-width:1400px) {
        height: 800px;
    }
`
export const MockUpText = styled.div`
    /* border: 1px solid red;//.................... */
    display: flex;
    flex-direction: column;
    width: 55%;
    @media (max-width:1000px) {
            width: 90%;
            /* margin: auto; */
        }
    h1{
        color: #FFF;
        font-size: clamp(22px,3vw,48px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    div{
        margin: 3em auto 8em;
        border: 1px solid transparent;
        width: 150px;
        height: 40px;
        
        border-radius: .7em;
        background-color: transparent;
        a{
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: transparent;
            font-weight: 700;
            font-size: large;
        }
        @media (max-width:1000px) {
            margin: 3em auto 1.5em;
            
        }
    }
`
export const MockUpImage = styled.div`
    /* border: 1px solid red;//.................... */
    width: 30%;
    @media (max-width:1000px) {
            margin: 0;
            width: 70%;
            max-width: 250px;
        }
    div{
        padding: .8em .8em 0em .8em;
        border-radius: 2.5em 2.5em 0em 0em;
        background-color: #FFF;
        img{
            width: 100%;
        }
    }
`
export const ContCardsLand = styled.section`
    /* border: 1px solid red;//................. */
    width: min(100%, 1600px);
    display: flex;
    justify-content: space-around;
    margin: 2em auto;
    @media (max-width:1050px) {
        flex-direction: column;
    }
`
export const CardsLand = styled.div`
    /* border: 1px solid red;//................. */
    width: 350px;
    height: 220px;
    border-radius: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    @media (max-width:1200px) {
        width: 30%;
    }
    @media (max-width:1050px) {
        width: 70%;
        height: 100px;
        margin: 1em auto;
        border-radius: 17px;
        flex-direction: row;
        padding-left: 2em;
    }
    @media (max-width:620px) {
        width: 85%;
        height: 150px;
    }
    @media (max-width:440px) {
        flex-direction: column;
        height: max-content;
    }
    p:nth-child(even){
        /* border: 1px solid red;//................. */
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    p{
        /* border: 1px solid red;//................. */
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width:1050px) {
            padding: 0 1em;
        }
    }
`
export const ContCardsSection3 = styled.section`
    /* border: 1px solid red;//...................... */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width:1050px) {
        &:nth-child(even){
            flex-wrap: wrap-reverse;
        }
    }
`
export const CardsSection3 = styled.div`
    /* border: 1px solid red;//...................... */
    background-color: var(--degradado1);
    width: 25%;
    overflow: hidden;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    @media (max-width:1050px) {
        width: 50%;
        p:nth-child(even){
            flex-direction:row-reverse;
        }
    }
    img{
        width: 100%;
        /* height: 100%; */
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center center;
    }
    p{
        /* border: 1px solid red;//...................... */
        padding: 0 1.5em;
        width: auto;
        font-size: clamp(16px, 4vw, 28px);
        font-weight: 700;
        color: #FFF;
    }
`
export const ContSection = styled.section`
    /* border: 1px solid red;//...................... */
    width: 90%;
    max-width: 1250px;
    margin: 2.5em auto;
    flex-shrink: 0;
    p{
        color: #000;
        text-align: center;
        font-size: clamp(16px,3vw, 20px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`
export const ContCardsSection5 = styled.section`
    /* border: 1px solid red;//................. */
    width: min(100%, 1220px);
    display: flex;
    margin: 0 auto;

    box-sizing: border-box;
    overflow-x: scroll;
    padding: 1em;
    scroll-snap-type: x mandatory;
    scroll-padding:1em;
    gap: 3em;
    @media (max-width:550px) {
        gap: 1em;
        
    }
`
export const CardsSection5 = styled.div`
    /* border: 1px solid red;//................. */
    width: 350px;
    height: 220px;
    border-radius: 37px;
    padding: .5em;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

    flex: 0 0 content;
    scroll-snap-align:start;
    
    @media (max-width:440px) {
        width: 300px;
        height: 200px;
    }
    span{
        /* border: 1px solid red;//................. */
        width: 100%;
        color: var(--degradado1);
        font-family: Inter;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        p{
            float: right;
            font-family: Inter;
        }
    }
    p{
        /* border: 1px solid red;//................. */
        margin: 0;
    }
    .userNick{
        margin-top: 7%;
    }
    .CommentText{
        color: #000;
        width: 350px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media (max-width:440px) {
            width: 300px;
        }
    }
`
export const ContCardsSection6 = styled.section`
    /* border: 1px solid red;//................. */
    width: min(100%, 1600px);
    display: flex;
    justify-content: center;
    gap: 1em;
    margin: 2em auto;
    @media (max-width:470px) {
        width: 95%;
    }
`
export const CardsSection6 = styled.div`
    /* border: 1px solid red;//................. */
    width: 25%;
    height: 12vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width:800px) {
        height: 186px;
        
    }
    p:nth-child(1){
        /* border: 1px solid red;//................. */
        margin: 0;
        color: #000;
        text-align: center;
        font-size: clamp(15px, 3vw, 30px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p:nth-child(2){
        /* border: 1px solid red;//................. */
        margin: 0;
        color: #000;
        text-align: center;
        font-size: clamp(12px, 3vw, 25px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`