//import skeletons from material ui
import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';
//import styles 
import { ConTextCard, ContCard, ContImgSearch } from '../../styles/Buscador';

export default function SkeletonBuscador() {

    // create and managint inputs
    const fileInputs = (Array(6).fill(null));

    return (
        <>
            <div>
                {fileInputs.map((index) => (
                    <Stack key={index} spacing={1}>
                        <ContCard>
                            <ContImgSearch><Skeleton variant="circular" width={100} height={100} /></ContImgSearch>
                            <span>
                                <Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />
                                <ConTextCard>
                                    <Skeleton variant="text" width="150px" sx={{ fontSize: '1rem' }} />
                                </ConTextCard>
                            </span>
                        </ContCard>
                    </Stack>
                ))}
            </div>
        </>
    );
}