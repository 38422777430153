import React, { useEffect, useState } from 'react'
//styles 
import { BtnSignUp, ContFormSignUp, ContSignUp } from '../styles/SingUp';
import { ContInputName } from '../styles/EditarPerfil';
//import components
import PerfilFormEdit from '../components/PerfilFormEdit';
import BuscamosEditar from './BuscamosEditar';
import ParaHacerEditar from './ParaHacerEditar';
import CambiarAvatar from './CambiarAvatar';
// import db firebase
import app from '../firebase/credenciales';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
//import backdrop from material ui
import SimpleBackdrop from './BackDrop';



const EditarPerfilForm = ({ userData }) => {

    // db firebase
    const firestore = getFirestore(app)
    //get user id from local storage
    const userId = localStorage.getItem('user');
    // child state change'perfil'
    const [perfil, setPerfil] = useState({});
    //child state change'para hacer'
    const [estadoParaHacer, setEstadoParaHacer] = useState({});
    //child state change'para hacer'
    const [estadoBuscamos, setEstadoBuscamos] = useState({});
    //wait to charge data
    const [open, setOpen] = useState();
    //managing form values..............

    //function that receives the state of the child 'perfil'
    const handlePerfil = (newState) => {
        setPerfil(newState);
    };
    //function that receives the state of the child 'Buscamos'
    const handleBuscamos = (newState) => {
        setEstadoBuscamos(newState);
    };
    //function that receives the state of the child 'para hacer'
    const handleParaHacer = (newState) => {
        setEstadoParaHacer(newState);
    };

    //filter data
    const filterData = userData.filter(item => item.id === userId);
    const [datosFiltrados] = filterData;
    const { name, avatar } = datosFiltrados;

    // estado formulario....................................
    const [valoresInputs, setValoresInputs] = useState({
        avatar: avatar,
        name: name,
    });
    //.......................................................

    // managing the form.......................
    const submitHandler = async (e) => {
        setOpen(true);
        e.preventDefault();

        // pidiendo datos de local storage
        const userUid = localStorage.getItem('user')
        // write to the DataBase
        const docuRef = doc(firestore, `usuarios/${userUid}`);
        //search in DataBase and type
        try {

            await updateDoc(docuRef, {
                avatar: valoresInputs.avatar,
                name: valoresInputs.name,
                perfil: perfil,
                buscamos: estadoBuscamos,
                para_hacer: estadoParaHacer,
            });
            //navigating to next page
            window.history.back();
            setOpen(false);
            console.log('Documento actualizado exitosamente');
        } catch (e) {
            console.log('Error al actualizar el documento:', e);
        }
    }
    //.......................................

    useEffect(() => {
        //delay while waiting for data to be loaded

    }, [userData]);

    //.......................................
    const [estadoAvatar, setEstadoAvatar] = useState(false);

    const actualizarEstadoAvatar = () => {
        setEstadoAvatar(true);
    };
    //.......................................


    //
    let ComponentToRender;

    //comprobamos que el array no este vacio antes de desestructurar
    if (userData.length !== 0) {

        const handleChange = (event) => {
            const { name, value } = event.target;
            setValoresInputs((prevValores) => ({
                ...prevValores,
                [name]: value
            }));
        }

        const Component = (
            <>

                <SimpleBackdrop open={open} />

                <br /><br />
                <ContSignUp >
                    <ContFormSignUp>
                        <form onSubmit={submitHandler}>
                            <CambiarAvatar estadoAvatar={estadoAvatar} datosForm={datosFiltrados} />

                            <ContInputName>
                                <label>Nombre <br /> de usuario</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={valoresInputs.name || name}
                                    onChange={handleChange}
                                />
                            </ContInputName>

                            <PerfilFormEdit onHandlePerfilEdit={handlePerfil} datosForm={datosFiltrados} />
                            <BuscamosEditar onHandleBuscamos={handleBuscamos} datosForm={datosFiltrados} />
                            <ParaHacerEditar onHandleParaHacer={handleParaHacer} datosForm={datosFiltrados} />

                            <BtnSignUp onClick={actualizarEstadoAvatar} type="submit">enviar</BtnSignUp>

                        </form>
                    </ContFormSignUp>
                </ContSignUp >
            </>
        );

        ComponentToRender = Component;

    }

    return (
        <>
            {ComponentToRender}
        </>
    )
}

export default EditarPerfilForm;