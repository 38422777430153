import React from 'react'
// import AUTH firebase
import AuthFirebase from "../firebase/credenciales";
import { getAuth } from 'firebase/auth'
const auth = getAuth(AuthFirebase);

const AdminDashboard = () => {

    const handleLogout = () => {
        auth.signOut();
        localStorage.clear('admi');
        window.location.reload();
    };

    return (
        <>
            <button 
            onClick={handleLogout}
            >Cerrar sesión</button>
            AdminDashboard
        </>
    )
}

export default AdminDashboard