import React from 'react';
//import react router dom
import { Link } from 'react-router-dom';
//import styles
import { BtnIcons, Logo, NavStyle, RightIcons } from '../styles/NavBar';
//import material ui
import SearchIcon from '@mui/icons-material/Search';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
//import commponents
import MenuMobile from './MenuMobile';
//import icons page
import icon11 from '../images/Logos-Swirl/logo-swirl-11.png';




const NavBar = () => {

    return (
        <>
            <NavStyle>
                <BtnIcons>
                    <MenuMobile />
                </BtnIcons>

                <Logo src={icon11} alt="logo-swirl-11" />

                <RightIcons>
                    <BtnIcons className='secNujk1'>
                        <Link to='/buscador'><SearchIcon /></Link>
                    </BtnIcons>

                    <BtnIcons className='secNujk1'>
                        <Link to='/mensajes'><ChatOutlinedIcon /></Link>
                    </BtnIcons>

                    <BtnIcons><Link></Link></BtnIcons>
                    <BtnIcons><Link></Link></BtnIcons>
                </RightIcons>

            </NavStyle>

        </>
    );
}
export default NavBar;
