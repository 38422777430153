import styled from "styled-components";

export const FooterLandingPage = styled.footer`
    border-top: 3px solid var(--color4-1);//................
    width: 100%;
    height: 233px;
    bottom: 0;
`
export const ContFooter1 = styled.span`
    /* border: 1px solid blue;//................ */
    width: 93.5%;
    max-width: 1500px;
    margin: 1.5em auto;
    display: flex;
    @media (max-width:710px) {
        width: 90%;
    }
    @media (max-width:464px) {
        flex-direction: column;
        width: 90%;
    }
`
export const ContImgFooterLaptop = styled.div`
    /* border: 1px solid red;//................ */
    width:25%;
    height:100px;
    @media (max-width:1000px) {
        width:32%;
    }
    @media (max-width:710px) {
        display: none;
    }
    p{
        margin-top: 0;
        color: var(--degradado1);
        font-size: 18px;
        font-weight: 700;
    }
    img{
        background-color: var(--degradado1);
        border-radius: 50%;
        padding: .3em;
        margin-right: .5em;
        &:hover{
            transition: 0.3s ease-in-out;
            background-color: var(--degradado2);
        }
    }
`
export const ContImgFooterMovile = styled.div`
    /* border: 1px solid red;//................ */
    display: none;
    @media (max-width:710px) {
        display:block;
        /* display: flex; */
        width:90%;
        margin: auto;
        height: max-content;
        /* padding: .5em; */
        p{
            display:none;
        }
    }
    img{
        background-color: var(--degradado1);
        border-radius: 50%;
        padding: .3em;
        margin-right: .5em;
        &:hover{
            transition: 0.3s ease-in-out;
            background-color: var(--degradado2);
        }
    }
`
export const ContLinkFooter = styled.div`
    /* border: 1px solid red;//................ */
    width:25%;
    height:100px;
    display: flex;
    flex-direction: column;
    @media (max-width:1000px) {
        width:32%;
    }
    @media (max-width:710px) {
        width:100%;
        margin: auto;
    }
    @media (max-width:464px) {
        height:auto;
        a{
            padding-bottom:.3em;
        }
    }
    p{
        margin-top: 0;
        margin-bottom: 10px;
        color: var(--degradado1);
        font-size: 18px;
        font-weight: 700;
    }
    a{
        text-decoration: none;
        color: var(--color2);
        line-height: 1.5em;
        &:hover{
            transition: 300ms;
            color: var(--color10);
            font-weight: 500;
        }
    }
`
export const ContFooter2 = styled.div`
    /* border: 1px solid red;//................... */
    width: 93.5%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color2);
    @media (max-width:560px) {
        flex-direction: column;
    }
    img{
        width: 94px;
    }
`
export const Copy = styled.div`
    display: flex;
    @media (max-width:560px) {
        text-align: center;
    }
    p{
        padding-left: .5em;
    }
`
export const Hr = styled.hr`
    max-width: 1500px;
    width: 93.5%;
    border: 1px solid var(--color4-1);//................
`