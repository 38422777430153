import React from 'react'
//import components
import NavBarAux from '../components/NavBarAux'

const Conversacion = () => {
    return (
        <>
            <NavBarAux />
            <br /><br /><br /><br />
            Conversacion
        </>
    )
}

export default Conversacion