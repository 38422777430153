import React, { useContext, useEffect, useState } from 'react';
//import components
import NavBarAux from '../components/NavBarAux';
import SkeletonNuevosUsuarios from '../components/Skeletons/SkeletonNuevosUsuarios';
//import styles
import { ContCardsNew, ContUsuariosNew } from '../styles/NuevosUsuarios';
import { ConTextCard, ContCard, ContImgSearch, TitleCard } from '../styles/Buscador';
//import global context
import MyContext from '../Context/MyContext';
//import functions from utils js
import { calculateAge, compararFechas } from '../Utils';

const NewUser = () => {

    var c = console.log.bind(document);

    //get data from useContext
    const userData = useContext(MyContext);
    //destructuring data
    const [destructuring] = userData;

    //managing load image....................
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };
    //.......................................

    //managint data users
    const [dataUsers, setDataUsers] = useState([]);

    useEffect(() => {
        if (destructuring) {
            const filtrarUsuarios = () => {
                // pidiendo datos de local storage
                const userUid = localStorage.getItem('user')
                //filtramos usuarios que no contengan 'fileData' 
                //y sacamos del array al usuario activo 
                const filterData = userData.filter(item => item.id !== userUid);
                //sacamos del array a usuarios con perfil vacio
                const usersFullPerfil = filterData.filter(item => item.perfil !== false);

                setDataUsers(usersFullPerfil.sort(compararFechas));
            };
            filtrarUsuarios();
        }
    }, [userData]);


    const renderedElements = dataUsers.map((item) => {

        //calculate age
        const ageHer = calculateAge(item.perfil.date_her);
        const ageHis = calculateAge(item.perfil.date_his);

        return (
            <>
                <ContCard to={`/perfil-otros-usuarios/${item.id}`} key={item.id}>
                    <ContImgSearch><img src={item.avatar} alt="image_avatar" /></ContImgSearch>
                    <span>
                        <TitleCard>{item.name}</TitleCard>
                        <ConTextCard>
                            <p>{ageHer}</p>
                            <p>({item.perfil.orientation_her.substring(0, 6)})</p>
                            <p>y</p>
                            <p>{ageHis}</p>
                            <p>({item.perfil.orientation_his.substring(0, 6)})</p>
                        </ConTextCard>
                        <ConTextCard>
                            <p>{item.perfil.city},</p>
                            <p>{item.perfil.country}</p>
                        </ConTextCard>
                    </span>
                </ContCard>
            </>

        );
    });

    return (
        <>
            <NavBarAux propName={'Nuevos Usuarios'} />
            <ContUsuariosNew >
                {
                    dataUsers.length > 0
                        ?
                        <ContCardsNew>{renderedElements}</ContCardsNew>
                        :
                        <ContCardsNew><SkeletonNuevosUsuarios /></ContCardsNew>
                }
            </ContUsuariosNew >
        </>
    )
}

export default NewUser