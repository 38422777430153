import React, { useContext, useEffect, useState } from 'react';
//import global context
import MyContext from '../Context/MyContext';
//import styles
import {
    ContBusca,
    ContImgP,
    ContImgParagraph,
    ContItemsBusca,
    ContSectionP,
    ContTable,
    ContTr,
    Contcouples,
    ParagraphP1,
    TableCat1,
    TableCat2,
    TableTd,
    TableTitle
} from '../styles/PerfilOtrosUsuarios';
//import react router dom
import { useParams } from 'react-router-dom';
//import functions from utils js
import { calculateAge } from '../Utils';
import { ordenParejas } from '../UtilsData';
//import component
import NavBarAux from '../components/NavBarAux'
import BtnPerfilOtrosU from '../components/BtnPerfilOtrosU';
import BtnEditarPerfil from '../components/BtnEditarPerfil';
// import component backdrop
import SimpleBackdrop from "../components/BackDrop";
import SklPerfilOtrosUsuarios from '../components/Skeletons/SklPerfilOtrosUsuarios';
import AcordeonPerfil from '../components/AcordeonPerfil';
import NavBarFooter from '../components/NavBarFooter';



const PerfilOtroUsuario = () => {
    //wait to charge data...............
    const [open, setOpen] = useState(false);
    //
    const [datos, setDatos] = useState(false);
    //
    const [cargando, setCargando] = useState(true);
    //get data from useContext
    const userData = useContext(MyContext);
    // Obtenemos el Id desde la URL actual utilizando el hook useParams()
    const { id } = useParams();
    //get user id from local storage
    const userId = localStorage.getItem('user');
    //estate of child buton
    const [childState, setChildState] = useState(false);

    const handleChildState = (state) => {
        setChildState(state);
    };

    useEffect(() => {
        setOpen(true)
        //delay while waiting for data to be loaded
        if (userData.length !== 0) {
            setOpen(false);
            const searchData = async () => {
                setDatos(true)
                setCargando(false)
            };
            searchData();
        }
    }, [userData]);

    //
    let ComponentToRender;
    //filter data
    const filterData = userData.filter(item => item.id === id);
    //comprobamos que el array no este vacio antes de desestructurar
    if (userData.length > 0) {

        const [primerElemento] = filterData;

        const { perfil, avatar, name, buscamos, para_hacer } = primerElemento;
        const buscamosItems = Object.entries(buscamos);
        const paraHacer = Object.entries(para_hacer);

        const parejas = [];
        const parejaOrdenada = ordenParejas()

        // Recorrer las claves y agruparlas por pares
        parejaOrdenada.forEach(pareja => {
            const parejaObjeto = {
                [pareja.keys[0]]: perfil[pareja.keys[0]],
                [pareja.keys[1]]: perfil[pareja.keys[1]]
            };
            parejas.push({ nombre: pareja.nombre, pareja: parejaObjeto });
        });;

        //limitamos el numero de elemntos a mostrar
        const elementosVisibles = childState ? parejas : parejas.slice(0, 3);


        const Component = (
            <>
                <NavBarAux propName={name} />
                <ContSectionP>

                    <ContImgParagraph>
                        <ContImgP><img src={avatar} alt="user_image" /></ContImgP>
                        <ParagraphP1>
                            <p>{name}</p>
                            <p>{perfil.about}</p>
                        </ParagraphP1>
                    </ContImgParagraph>
                    {id === userId ? <BtnEditarPerfil /> : <BtnPerfilOtrosU propName={primerElemento} />}

                    <AcordeonPerfil onChildStateChange={handleChildState} />

                    <ContTable childState={childState} >
                        {elementosVisibles.map((pareja, index) => (
                            <ContTr className={pareja.nombre === 'Nombre:' ? 'class_tr' : ''} key={index}>
                                <TableTitle>{pareja.nombre}</TableTitle>
                                {Object.keys(pareja.pareja).map(key => (
                                    <TableTd key={key}>
                                        {pareja.nombre === 'Edad:' ? (
                                            (calculateAge(pareja.pareja[key]) + ' Años')
                                        ) : pareja.nombre === 'Nombre:' ? (
                                            (pareja.pareja[key].split(' ')[1])
                                        ) : (
                                            (pareja.pareja[key])
                                        )}
                                    </TableTd>
                                ))}
                            </ContTr>
                        ))}
                    </ContTable>

                    <ContBusca>
                        <p className='class_title'>Busca</p>
                        <ContItemsBusca>
                            {
                                buscamosItems.map(([key, value], index) => (
                                    <p key={index}
                                        className={value.toString() === 'false' ?
                                            'class_white'
                                            :
                                            'class_blue'}>
                                        {key}
                                    </p>
                                ))
                            }
                        </ContItemsBusca>
                    </ContBusca>

                    <ContBusca>
                        <p className='class_title'>Para hacer</p>
                        <ContItemsBusca>
                            {
                                paraHacer.map(([key, value], index) => (
                                    <p key={index}
                                        className={value.toString() === 'false' ?
                                            'class_white'
                                            :
                                            'class_blue'}>
                                        {key}
                                    </p>
                                ))
                            }
                        </ContItemsBusca>
                    </ContBusca>


                </ContSectionP>
            </>
        );

        ComponentToRender = Component;

    }

    return (
        <>
            <SimpleBackdrop open={open} />

            {cargando ? (
                <>
                    <NavBarAux />
                    <SklPerfilOtrosUsuarios />
                </>
            ) : (
                <div>
                    {datos && ComponentToRender}
                </div>
            )}

            <NavBarFooter propName={id} />

        </>
    )
}

export default PerfilOtroUsuario;