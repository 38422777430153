import styled from "styled-components";

export const ContSignUp = styled.div`
    /* border: 1px solid red;//................ */
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    padding-top: 1em;
    background: var(--color3);
`
export const ContFormSignUp = styled.div`
    /* border: 1px solid var(--color2);//.................. */
    background-color: var(--color3);
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    padding: 1em;
`
export const DivTitle = styled.div`
    h1{
        color: var(--color2);
        text-align: justify;
        font-size: 24px;
        padding-bottom: .5em;
        font-weight: 700;
    }
`
export const ContInputSign = styled.div`
    display: flex;
    flex-direction: column;
    label{
        /* border: 1px solid red;//.................. */
        /* color: var(--color2); */
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
    }
    .alertClass{
        height: 51px;
        border-radius: 15px;
        border: 2px solid var(--color5);
        padding: 0 1em;
        margin-bottom: .5em;
        /* Código para sombra compatible con todos los navegadores */
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    .normalClass{
        height: 51px;
        border-radius: 15px;
        border: 2px solid transparent;
        padding: 0 1em;
        margin-bottom: .5em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    
`
export const ContSelectSign = styled.div`
    display: flex;
    flex-direction: column;
    appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
    label{
        /* border: 1px solid red;//.................. */
        color: var(--color2);
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
    }
    select{
        height: 51px;
        border-radius: 15px;
        border: none;
        margin-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding: 0 1em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    option{
        color: red;
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding-left: 1em;
    }
    .red{
        color: red!important;
    }
`
export const BtnSignUp = styled.button`
    width: 100%;
    height: 51px;
    border-radius: 15px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 700;
    color: var(--color3);
    background-color: var(--color1);
    cursor: pointer;
    &:hover{
        background-color: var(--degradado2);
    }
`
export const ContPLink = styled.div`
    /* border: 1px solid red;//.................. */
    color: var(--color2);
    display: flex;
    justify-content: center;
    margin: 1em 0;
    a{
        color: var(--degradado1);
        &:active{
            color: var(--degradado2);
        }
    }
`
    /* align-items: flex-start; */
export const ContCheckSignUp = styled.label`
    /* border: 1px solid red;//.................. */
    width: 100%;
    margin: 1em 0;
    display: flex;
    align-items: center;
    text-align: justify;
    p{
        color: var(--color2);
        font-size: 14px;
    }
    span {
        width: 90%;
        font-size: 14px;
        color: var(--color1);
        font-weight: 700;
    }
`