import React, { useContext, useState } from "react";
//import react router dom
import { Link, useNavigate } from "react-router-dom";
//import styles
import { BtnLogin1, ContFather, ContForm, ContInput, ContP, Form } from "../styles/Login";
// AUTH firebase
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
// toastify alert
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import global context
import MyContext from "../Context/MyContext";
//import firebase
import app from "../firebase/credenciales";
// import component backdrop
import SimpleBackdrop from "../components/BackDrop";
import { spanish } from "../data/spanish";
//import material icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';



export function LogIn({ onData }) {

  //get data from text body
  const [textBody] = spanish;
  //destructuring static data
  const { login: {
    labelEmail,
    labelPassword,
    labelRemenber,
    button,
    textSingUp1,
    textSingUp2
  } } = textBody;

  //shorted de console.log
  var c = console.log.bind(document);
  //GET context
  const userData = useContext(MyContext);
  //active backdrop
  const [open, setOpen] = useState(false);
  //navigate to other page
  const navigate = useNavigate();
  //
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  //managint form values..............
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  //...................................

  // managint send validation AUTH....................
  async function loginUser(email, password) {

    //AUTH firebase
    const auth = getAuth(app);
    // console.log(email, password2)
    const infoUser = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    ).then((usuarioFirebase) => {
      //active backdrop
      setOpen(true)
      //delay while waiting for data to be loaded
      if (usuarioFirebase) {
        // estate to uid user
        searchCollection(usuarioFirebase.user.uid);
      }
    }).catch((auth) => {
      // close backdrop
      setOpen(false)
      //active error alert
      toast.error('Correo o Contraseña Invalida!', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }
  //...................................................

  // managint form.....................................
  const submitHandler = (e) => {
    e.preventDefault();
    setOpen(true);
    loginUser(user.email, user.password)
  }
  //....................................................

  //..........search collection.........................
  const searchCollection = (userUid) => {
    //eliminando entradas vacias
    const loggedInUser = userData.find(item => item.id === userUid);

    if (loggedInUser.S5p4r1bn3k === 'S1dm3n3str1t4r01') {
      //wait for data to load
      navigate('/dashboard');
    } else {
      //keys to search into object
      const keysToSearch = ["perfil_complete", "fileData_complete"];
      //array to save result of bucle
      const results = {};

      // bucle to search keys
      keysToSearch.forEach(key => {
        if (loggedInUser.hasOwnProperty(key)) {
          results[key] = loggedInUser[key];
        }
      });

      //destructuring data to array 
      const { perfil_complete, fileData_complete } = results;

      //route tree
      if (perfil_complete === false) {
        // console.log('Ok perfil')
        navigate('/datos-de-perfil')
      } else if (fileData_complete === false) {
        // console.log('Ok fileData')
        navigate('/agregar-fotos')
      } else {
        navigate('/home')
      }
    }
  }
  //...................................................

  return (
    <>
      <ContFather>

        <SimpleBackdrop open={open} />

        <ContForm>
          <h1>login</h1>
          <Form onSubmit={submitHandler}>
            <ContInput>
              <label htmlFor="email">{labelEmail}</label>
              <input
                type="email"
                name="email"
                required
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </ContInput>
            <ContInput>
              <label htmlFor="password">{labelPassword}</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                required
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
              <span type="button" onClick={handleTogglePassword}>
                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
              </span>
            </ContInput>
            <br />
            {/* <ContCheck>
              <CheckboxInput
                type="checkbox"
                name="rememberMe"
                value="true" /><span>{labelRemenber}</span>
            </ContCheck> */}

            <div>
              <BtnLogin1 type="submit">
                {button}
              </BtnLogin1>
            </div>
          </Form>

          <ContP>
            <p>{textSingUp1}</p><Link to="/singup">{textSingUp2}</Link>
          </ContP>

        </ContForm>

        <ToastContainer />

      </ContFather>

    </>
  );
}
