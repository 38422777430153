import styled from "styled-components";

export const ContGeneral = styled.section`
    /* border: 1px solid red;//................. */
    @media (min-width: 760px) {
        width: 93%;
        float: right;
    }
    @media (min-width: 1265px){
        /* width: 78%; */
        right: 0;
        max-width: calc(100% - 275px);
    }
`
