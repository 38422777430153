import styled from "styled-components";


export const ContNavLanding = styled.div`
    /* border: 1px solid red;//........ */
    position: fixed;
    top: 0;
    font-size: 1.5em;
    height: 100px;
    width:100%;
    z-index: 10;
    display: flex;
    transition: 1.5s;
    background-color: ${props => props.showAnimation ? 'var(--degradado2)' : 'transparent'};
    -webkit-box-shadow: ${props => props.showAnimation ? '0px 12px 15px -3px rgba(0,0,0,0.41)' : ''}; 
    box-shadow: ${props => props.showAnimation ? '0px 12px 15px -3px rgba(0,0,0,0.41)' : ''};
`
export const ContLogo = styled.div`
    /* border: 1px solid red;//........ */
    width: 50%;
    display: flex;
    align-items: center;
    margin-left: 1.5em;
    @media (max-width:1000px) {
        margin-left: .4em;
    }
    a{
        text-decoration: none;
        img{
            width: 20cqmax;
            max-width: 250px;
            margin: 1em 0;
            @media (max-width:1000px) {
                
            }
        }
    }
`
export const ContLink = styled.div`
    /* border: 1px solid red;//........ */
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 2em;
    gap: 2em;
    @media (max-width:930px) {
        display: none;
    }
    a{
        /* border: 1px solid red;//........ */
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
        &:active{
            color: var(--color9);
        }
    }
`
export const ContMenuHamburguer = styled.div`
    /* border: 1px solid red;//................ */
    display: none;
    width: 50%;
    button{
        float: right!important;
    }
    svg{
        font-size: 50px;
        color: #FFF;
        cursor: pointer;
        margin: .5em;
        &:active{
            color: var(--color7);
        }
    }
    @media (max-width:930px) {
        display: block;
    }
`
export const ContMenuMobileLanding = styled.div`
    display: flex;
    flex-direction: column;
    svg{
        padding: .4em;
        cursor: pointer;
        font-size: 2.5em;
        color: var(--color9);
    }
    a{
        border-bottom: 2px solid var(--color7);//..............
        padding: 1em 0 .4em 1em;
        text-decoration: none;
        color: #000;
        font-size: 500;
        &:active{
            color: var(--color10);
        }
    }
`