import React from 'react'
//import styles
import { BtnEditar } from '../styles/BtnPerfilOtrosU'

const BtnEditarPerfil = () => {
    
    return (
        <>
            <BtnEditar to='/editar-perfil'>Editar Perfil</BtnEditar>
        </>
    )
}

export default BtnEditarPerfil