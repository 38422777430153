import styled from "styled-components";


export const ContNoFriends = styled.div`
    /* border: 1px solid red;//.................. */
    width: max-content;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color4-1);
    svg{
        font-size: 5em;
    }
    h1{
        font-size: 1.8em;
    }
`