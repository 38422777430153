
// Define el orden y nombres personalizados de las parejas
export const ordenParejas = () => {

  const parejaOrdenada = [
    { nombre: 'Nombre:', keys: ['name_his', 'name_her'] },
    { nombre: 'Edad:', keys: ['date_his', 'date_her'] },
    { nombre: 'Orientacion:', keys: ['orientation_his', 'orientation_her'] },
    { nombre: 'Raza:', keys: ['race_his', 'race_her'] },
    { nombre: 'Nacionalidad:', keys: ['country_his', 'country_her'] },
    { nombre: 'Peso:', keys: ['weight_his', 'weight_her'] },
    { nombre: 'Altura:', keys: ['height_his', 'height_her'] },
    { nombre: 'Busto', keys: ['sizeBra_his', 'sizeBra_her'] },
    { nombre: 'Pene:', keys: ['penisLenght_his', 'penisLenght_her'] },
    { nombre: 'Tatuajes:', keys: ['tattoo_his', 'tattoo_her'] },
    { nombre: 'Piercings:', keys: ['piercing_his', 'piercing_her'] },
    { nombre: 'Fuma:', keys: ['smokes_his', 'smokes_her'] },
    { nombre: 'Bebe Alcohol:', keys: ['drink_his', 'drink_her'] },
    { nombre: 'Idioma:', keys: ['languages_his', 'languages_her'] },
  ];
  return parejaOrdenada;
}

// country and city data
export const dataCountries = [
// www.datosmundial.com/www.datosmundial.com
  {
    "America": {
      "Canada": [
        "Toronto",
        "Montréal",
        "Calgary",
        "Ottawa",
        "Edmonton",
        "Mississauga",
        "Winnipeg",
        "Brampton",
        "Vancouver",
        "Surrey",
        "Hamilton",
        "Québec",
        "Halifax",
        "Laval",
        "Gatineau",
        "Saskatoon",
        "Burnaby",
        "Regina",
        "St. John's",
        "Victoria",
        "Fredericton",
        "Charlottetown",
        "Whitehorse",
        "Yellowknife",
        "Iqaluit",
      ],
      "Estados Unidos": [
        "Washington, D.C.",
        "Phoenix",
        "Austin",
        "Columbus",
        "Indianapolis",
        "Denver",
        "Boston",
        "Nashville",
        "Oklahoma City",
        "Sacramento",
        "Atlanta",
        "Raleigh",
        "Honolulu",
        "Saint Paul",
        "Lincoln",
        "Madison",
        "Baton Rouge",
        "Richmond",
        "Boise",
        "Des Moines",
        "Montgomery",
        "Little Rock",
        "Salt Lake City",
        "Tallahassee",
        "Providence",
        "Jackson",
        "Salem	Oregon",
        "Columbia",
        "Topeka",
        "Hartford",

      ],
      "México": [
        "Ciudad de México",
        "Tijuana",
        "León",
        "Puebla",
        "Ecatepec",
        "Guadalajara",
        "Ciudad Juárez",
        "Zapopan",
        "Monterrey",
        "Nezahualcóyotl",
        "Chihuahua",
        "Naucalpan de Juárez",
        "Mérida",
        "Aguascalientes",
        "Saltillo",
        "Mexicali",
        "San Luis Potosí",
        "Culiacán",
        "Guadalupe",
        "Acapulco de Juárez",
        "Hermosillo",
        "Cancún",
        "Santiago de Querétaro",
        "Morelia",
        "Tuxtla Gutiérrez",
        "Toluca",
      ],
      "Brasil": [
        "Brasilia",
        "São Paulo",
        "Rio de Janeiro",
        "Salvador",
        "Fortaleza",
        "Belo Horizonte",
        "Manaus",
        "Curitiba",
        "Recife",
        "Goiânia",
        "Porto Alegre",
        "Belém",
        "Guarulhos",
        "Campinas",
        "São Luís",
        "Maceió",
        "Natal",
        "Campo Grande",
        "Teresina",
        "João Pessoa",
        "Aracaju",
        "Porto Velho",
        "Florianópolis",
        "Macapá",
        "Rio Branco",
        "Boa Vista",
        "Cuiabá",
        "Palmas",
        "Vitória",
      ],
      "Argentina": [
        "Buenos Aires",
        "Córdoba",
        "Rosario",
        "Mendoza",
        "San Miguel de Tucumán",
        "La Plata",
        "Mar del Plata",
        "Quilmes",
        "Salta",
        "Santa Fe de la Vera Cruz",
        "San Juan",
        "Resistencia",
        "Santiago del Estero",
        "Corrientes",
        "Posadas",
        "Morón",
        "San Salvador de Jujuy",
        "Paraná",
        "Neuquén",
        "Formosa",
        "San Fernando del Valle de Catamarca",
        "San Luis",
        "La Rioja",
        "Comodoro Rivadavia",
        "Ushuaia",
      ],
      "Colombia": [
        "Bogotá",
        "Medellín",
        "Cali",
        "Barranquilla",
        "Cartagena",
        "Cúcuta",
        "Bucaramanga",
        "Ibagué",
        "Soledad",
        "Soacha",
        "Santa Marta",
        "Villavicencio",
        "Pereira",
        "Manizales",
        "Pasto",
        "Neiva",
        "Valledupar",
        "Buenaventura",
        "Montería",
        "Armenia",
        "Popayán",
        "Sincelejo",
        "Florencia",
      ],
      "Perú": [
        "Lima",
        "Arequipa",
        "Callao",
        "Trujillo",
        "Chiclayo",
        "Iquitos",
        "Huancayo",
        "Piura",
        "Chimbote",
        "Cusco",
        "Pucallpa",
        "Tacna",
        "Ica",
        "Juliaca",
        "Sullana",
        "Chincha Alta",
        "Huánuco",
        "Ayacucho",
        "Cajamarca",
        "Puno",
        "Tumbes",
        "Chosica",
        "Huaraz",
        "Cerro de Pasco",
        "San Isidro",
      ],
      "Chile": [
        "Santiago",
        "Puente Alto",
        "Antofagasta",
        "Viña del Mar",
        "San Bernardo",
        "Valparaíso",
        "Temuco",
        "Rancagua",
        "Coquimbo",
        "Puerto Montt",
        "Arica",
        "Concepción",
        "La Serena",
        "Talca",
        "Iquique",
        "La Pintana",
        "Calama",
        "Chillán",
        "Copiapó",
        "Quilpué",
        "Valdivia",
        "Los Ángeles",
        "Osorno",
        "Talcahuano",
        "Curicó",
        "Villa Alemana",
        "Punta Arenas",
        "Coihaique",
      ],
      "Ecuador": [
        "Quito",
        "Guayaquil",
        "Cuenca",
        "Santa Elena",
        "Machala",
        "Santo Domingo de los Colorados",
        "Portoviejo",
        "Manta",
        "Loja",
        "Ambato",
        "Eloy Alfaro",
        "Esmeraldas",
        "Riobamba",
        "Ibarra",
        "Babahoyo",
        "Tulcán",
        "Latacunga",
        "Puerto Francisco de Orellana",
        "Puerto Baquerizo Moreno",
      ],
      "Venezuela": [
        "Caracas",
        "Maracaibo",
        "Valencia",
        "Barquisimeto",
        "Ciudad Guayana",
        "Maturín",
        "Petare",
        "Maracay",
        "Ciudad Bolívar",
        "Barinas",
        "Baruta",
        "Cumaná",
        "Barcelona",
        "Cabimas",
        "Alto Barinas",
        "San Cristóbal",
        "Santa Teresa",
        "Punto Fijo",
        "Mérida",
        "Turmero",
        "Los Teques",
        "El Tigre",
        "Coro",
        "Puerto Cabello",
        "San Juan de los Morros",
        "La Asunción",
      ],
      "Bolivia": [
        "Sucre",
        "La Paz",
        "Santa Cruz de la Sierra",
        "Cochabamba",
        "Oruro",
        "Tarija",
        "Potosí",
        "Montero",
        "Trinidad",
        "Yacuiba",
        "Riberalta",
        "Guayaramerín",
        "Mizque",
        "Villazón",
        "Llallagua",
        "Camiri",
        "Cobija",
        "San Ignacio de Velasco",
        "Tupiza",
        "San Borja",
        "Villamontes",
        "Huanuni",
        "Camargo",
        "Coroico",
      ],
      "Uruguay": [
        "Montevideo",
        "Salto",
        "Paysandú",
        "Las Piedras",
        "Rivera",
        "Maldonado",
        "Tacuarembó",
        "Melo",
        "Mercedes",
        "Artigas",
        "Minas",
        "San José de Mayo",
        "Durazno",
        "Florida",
        "Treinta y Tres",
        "Rocha",
        "San Carlos",
        "Fray Bentos",
        "Colonia del Sacramento",
        "Trinidad",
        "Canelones",
      ],
      "Paraguay": [
        "Asunción",
        "Ciudad del Este",
        "San Lorenzo",
        "Capiatá",
        "Lambaré",
        "Fernando de la Mora",
        "Limpio",
        "Nemby",
        "Pedro Juan Caballero",
        "Encarnación",
        "Colonia Mariano Roque Alonso",
        "Villa Hayes",
        "Caaguazú",
        "Coronel Oviedo",
        "Concepción",
        "Villarrica",
        "Pilar",
        "Caazapá",
        "Caacupé",
        "San Juan Bautista",
        "Areguá",
        "Paraguarí",
        "San Pedro de Ycuamandiyú",
        "Filadelfia",
        "Salto del Guairá",
        "Fuerte Olimpo",
      ],
      "Costa Rica": [
        "San José",
        "Alajuela",
        "Desamparados",
        "Cartago",
        "Heredia",
        "Puntarenas",
        "Limón",
        "Alajuelita",
        "San Francisco",
        "Liberia",
        "Paraíso",
        "San Isidro",
        "Curridabat",
        "San Vicente de Moravia",
        "San José",
        "Turrialba",
        "Quesada",
        "Chacarita",
        "Mercedes",
      ],
      "Panamá": [
        "Ciudad de Panama",
        "San Miguelito",
        "Tocumen",
        "David",
        "Arraiján",
        "Las Cumbres",
        "La Chorrera",
        "Pacora",
        "Santiago de Veraguas",
        "Chitré",
        "Vista Alegre",
        "Colón",
        "Cativá",
        "Changuinola",
        "Puerto Armuelles",
        "Aguadulce",
        "La Concepción",
        "Pedregal",
        "Bocas del Toro",
        "Penonomé",
        "Unión Chocó",
        "Las Tablas",
        "La Palma",
        "Kusapín",
        "El Porvenir",
      ],
      "Honduras": [
        "Tegucigalpa",
        "San Pedro Sula",
        "Choloma",
        "La Ceiba",
        "Danlí",
        "El Progreso",
        "Ciudad Choluteca",
        "Villanueva",
        "Comayagua",
        "Juticalpa",
        "Olanchito",
        "Siguatepeque",
        "Santa Rosa de Copán",
        "San Lorenzo",
        "La Paz",
        "Yoro",
        "Santa Bárbara",
        "Nacaome",
      ],
      "El Salvador": [
        "San Salvador",
        "Soyapango",
        "San Miguel",
        "Santa Ana",
        "Apopa",
        "Ilopango",
        "Santa Tecla",
        "Mejicanos",
        "Ahuachapán",
        "Delgado",
        "San Martín",
        "Sonsonate",
        "Usulután",
        "Cojutepeque",
        "San Vicente",
        "Zacatecoluca",
        "Antiguo Cuscatlán",
        "Chalchuapa",
        "Quezaltepeque",
        "La Unión",
        "Acajutla",
        "Sensuntepeque",
        "Chalatenango",
        "Izalco",
        "San Francisco",
      ],
      "Nicaragua": [
        "Managua",
        "León",
        "Masaya",
        "Tipitapa",
        "Chinandega",
        "Matagalpa",
        "Estelí",
        "Granada",
        "Ciudad Sandino",
        "Juigalpa",
        "El Viejo",
        "Nueva Guinea",
        "Jinotega",
        "Bluefields",
        "Diriamba",
        "Ocotal",
        "Puerto Cabezas",
        "Chichigalpa",
        "Rivas",
        "San Rafael del Sur",
        "Jinotepe",
        "Boaco",
        "Nagarote",
        "Jalapa",
        "La Paz Centro",
        "San Marcos",
        "Masatepe",
        "Somoto",
        "San Carlos",
      ],
      "Guatemala": [
        "Ciudad de Guatemala",
        "Mixco",
        "Villa Nueva",
        "Petapa",
        "San Juan Sacatepéquez",
        "Quetzaltenango",
        "Villa Canales",
        "Escuintla",
        "Chinautla",
        "Chimaltenango",
        "Chichicastenango",
        "Huehuetenango",
        "Totonicapán",
        "Santa Lucía Cotzumalguapa",
        "Puerto Barrios",
        "Cobán",
        "Jalapa",
        "Sololá",
        "Mazatenango",
        "Chiquimula",
        "San Pedro Sacatepéquez",
        "Salamá",
        "Flores",
      ],
      "Belice": [
        "Belmopan",
        "Belize City",
        "San Ignacio",
        "Orange Walk",
        "Dangriga",
        "Corozal",
        "San Pedro",
        "Benque Viejo del Carmen",
        "Punta Gorda",
        "Placencia",
        "Shipyard",
        "Valley of Peace",
      ],
      "Jamaica": [
        "Kingston",
        "Portmore",
        "Spanish Town",
        "Montego Bay",
        "Mandeville",
        "May Pen",
        "Old Harbour",
        "Linstead",
        "Half Way Tree",
        "Savanna-la-Mar",
        "Port Antonio",
        "Saint Ann’s Bay",
        "Bog Walk",
        "Constant Spring",
        "Hayes",
        "Ocho Rios",
        "Morant Bay",
        "Stony Hill",
        "Santa Cruz",
        "Port Maria",
        "Falmouth",
        "Yallahs",
        "Bull Savanna",
        "Runaway Bay",
        "Lucea",
        "Porus",
      ],
      "Haití": [
        "Port-au-Prince",
        "Delmas 73",
        "Pétionville",
        "Port-de-Paix",
        "Jacmel",
        "Okap",
        "Léogâne",
        "Les Cayes",
        "Tigwav",
        "Jérémie",
        "Miragoâne",
        "Gonayiv",
        "Saint-Marc",
        "Verrettes",
        "Saint-Raphaël",
        "Ti Port-de-Paix",
        "Lenbe",
        "Hinche",
        "Désarmes",
        "Saint-Louis du Nord",
        "Fort Liberté",
        "Trou du Nord",
        "Ouanaminthe",
      ],
      "República Dominicana": [
        "Santo Domingo",
        "Santo Domingo Oeste",
        "Santiago de los Caballeros",
        "San Pedro de Macorís",
        "Salvaleón de Higüey",
        "San Cristóbal",
        "La Romana",
        "San Francisco de Macorís",
        "Puerto Plata",
        "Concepción de La Vega",
        "Punta Cana",
        "Santa Cruz de Barahona",
        "Boca Chica",
        "Baní",
        "San Juan de la Maguana",
        "Bonao",
        "Bajos de Haina",
        "Moca",
        "Azua",
        "Mao",
        "Salcedo",
        "Cotuí",
        "Hato Mayor del Rey",
        "Nagua",
      ],
      "Cuba": [
        "la Habana",
        "Santiago de Cuba",
        "Camagüey",
        "Holguín",
        "Santa Clara",
        "Guantánamo",
        "Arroyo Naranjo",
        "Las Tunas",
        "Bayamo",
        "Cienfuegos",
        "Matanzas",
        "Pinar del Río",
        "Manzanillo",
        "Ciego de Ávila",
        "Sancti Spíritus",
        "Palma Soriano",
        "Cárdenas",
        "Moa",
        "Puerto Padre",
        "Contramaestre",
        "Güira de Melena",
        "Consolación del Sur",
        "Güines",
        "Artemisa",
        "San Luis",
        "Morón",
        "Colón",
        "San José de las Lajas",
        "Nueva Gerona",
      ],
      "Bahamas": [
        "Nassau",
        "Lucaya",
        "Freeport",
        "West End",
        "Cooper’s Town",
        "San Andros",
        "George Town",
        "Marsh Harbour",
        "High Rock",
        "Andros Town",
        "Clarence Town",
        "Dunmore Town",
        "Spanish Wells",
        "Arthur’s Town",
        "Alice Town",
        "Cockburn Town",
        "Matthew Town",
        "Colonel Hill",
        "Abraham’s Bay",
        "Port Nelson",
        "Duncan Town",
      ],
      "Trinidad y Tobago": [
        "Port-of-Spain",
        "Laventille",
        "Chaguanas",
        "Mon Repos",
        "San Fernando",
        "Rio Claro",
        "Arima",
        "Marabella",
        "Point Fortin",
        "Tunapuna",
        "Scarborough",
        "Sangre Grande",
        "Peñal",
        "Princes Town",
        "Siparia",
        "Petit Valley",
        "Couva",
      ],
      "Barbados": [
        "Bridgetown",
        "Speightstown",
        "Oistins",
        "Bathsheba",
        "Holetown",
        "Crane",
        "Greenland",
      ],
      "Antigua y Barbuda": [
        "Saint John’s",
        "All Saints",
        "Piggotts",
        "Liberta",
        "Bolands",
        "Potters Village",
        "Codrington",
        "Parham",
        "Falmouth",
      ],
      "San Cristóbal y Nieves": [
        "Basseterre",
        "Fig Tree",
        "Market Shop",
        "Saint Paul’s",
        "Middle Island",
        "Nicola Town",
        "Trinity",
        "Charlestown",
        "Cayon",
        "Sandy Point Town",
        "Monkey Hill",
        "Dieppe Bay Town",
        "Newcastle",
        "Cotton Ground",
      ],
      "Santa Lucía": [
        "Castries",
        "Bisée",
        "Vieux Fort",
        "Micoud",
        "Soufrière",
        "Dennery",
        "Gros Islet",
        "Praslin",
        "Canaries",
        "Anse La Raye",
        "Laborie",
        "Choiseul",
      ],
      "San Vicente y las Granadinas": [
        "Kingstown",
        "Georgetown",
        "Byera Village",
        "Biabou",
        "Barrouallie",
        "Port Elizabeth",
        "Chateaubelair",
        "Layou",
      ],
      "Granada": [
        "Saint George's",
        "Gouyave",
        "Grenville",
        "Victoria",
        "Saint David’s",
        "Sauteurs",
        "Hillsborough",
      ],
      "Guyana": [
        "Georgetown",
        "Linden",
        "New Amsterdam",
        "Anna Regina",
        "Bartica",
        "Skeldon",
        "Rosignol",
        "Mahaica Village",
        "Mahdia",
        "Parika",
        "Vreed-en-Hoop",
        "Mahaicony Village",
        "Mabaruma",
        "Lethem",
      ],
      "Surinam": [
        "Paramaribo",
        "Lelydorp",
        "Brokopondo",
        "Nieuw Nickerie",
        "Moengo",
        "Nieuw Amsterdam",
        "Mariënburg",
        "Wageningen",
        "Albina",
        "Groningen",
        "Brownsweg",
        "Onverwacht",
        "Totness",
      ]
    }
  }
]
