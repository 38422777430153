import * as React from 'react';
//import material ui
import ArrowLeftSharpIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
//import styles
import { ContBtn } from '../styles/PerfilOtrosUsuarios';

export default function AcordeonPerfil({ onChildStateChange }) {

    //status to manage the user's click 
    const [click, setClick] = React.useState(true);

    const handleClickTrue = () => {
        setClick(false);
        onChildStateChange(click);
    }
    const handleClickFalse = () => {
        setClick(true);
        onChildStateChange(click);
    }

    return (
        <>
            <div>
                {click === true ?
                    <ContBtn onClick={handleClickTrue}>
                        <ArrowLeftSharpIcon />
                    </ContBtn>
                    :
                    <ContBtn onClick={handleClickFalse}>
                        <ArrowDropUpSharpIcon />
                    </ContBtn>}
            </div>
        </>
    );
}