import styled from "styled-components";


export const NavStyle = styled.nav`
    /* border: 1px solid red;//........ */
    position: fixed;
    top: 0;
    font-size: 1.5em;
    height: 45px;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(31,72,126);
    background: linear-gradient(90deg, rgba(31,72,126,1) 0%, rgba(35,112,153,1) 50%, rgba(31,72,126,1) 100%);
`
export const Logo = styled.img`
    width: 100px;
`
export const BtnIcons = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0!important;
    margin: 0!important;
    
    svg{
        color: white;
        font-size: 2.5em;
        
    }
`
export const RightIcons = styled.span`
    padding-right: .8em;
    .secNujk1{
        @media (min-width: 760px) {
            display: none;
        }
    }
`