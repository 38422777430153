import React from 'react'
//import component
import NavBarAux from '../components/NavBarAux'
//import icon from material ui
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
//import data
import { spanish } from '../data/spanish';
//import styles
import { ContNotMessages } from '../styles/Mensajes';

const Mensajes = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { Messages: { textMessage1, textMessage2 } } = textBody;

    return (
        <>
            <NavBarAux propName={'Mensajes'} />
            
            <ContNotMessages>
                <ChatOutlinedIcon />
                <h1>{textMessage1}</h1>
                <p>{textMessage2}</p>
            </ContNotMessages>
        </>
    )
}

export default Mensajes