import styled from "styled-components";



export const Footer = styled.footer`
    /* border: 1px solid red;//............... */
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--color3);
    -webkit-box-shadow: 0px -9px 15px -8px rgba(0,0,0,0.38); 
    box-shadow: 0px -9px 15px -8px rgba(0,0,0,0.38);
    @media (min-width: 760px) {
        padding-top: 2em;
        padding-left: 1em;
        left: 0; /* Pegar la barra al lado izquierdo */
        bottom: unset; /* Eliminar la propiedad bottom para que no esté pegada al pie */
        top: 45px; /* Pegar la barra al lado superior */
        width: 6%;
        height: 95%;
        flex-direction: column;
        justify-content: start;
        align-items: self-start;
        gap: 2em;
    }
    @media (min-width: 1265px){
        width: 22%;
        max-width: 260px;
    }
    a{
        /* border: 1px solid red;//............... */
        background-color: #FFF;
        display: flex;
        align-items: center;
        text-decoration: none;
        &:hover{
            transition: 100ms;
            color: var(--color4);
        }
        .textNavFooter{
                display: none;
                padding-left: 1em;
        }
        @media (min-width: 760px) {
            /* align-items: left; */
        }
        @media (min-width: 1265px){
            .textNavFooter{
                display: block;
                font-size: larger;
                font-weight: 500;
            }
        }
        &:nth-child(3) {
            svg{
                border: 2px solid var(--color-3);
                border-radius: 50%;
                padding:0% 2px;
            }
        }
        color: var(--color-3);
        svg{
            font-size: 2em;
        }
    }
    .activo{
        transition: 100ms;
        color: var(--color9);
        svg{
            transition: 100ms;
            font-size: 2.2em;
        }
        &:nth-child(3) {
            svg{
                border: 2px solid var(--color9);
                border-radius: 50%;
                padding:0% 2px;
            }
        }
        @media (min-width: 760px) {
        }
    }
    .secNujk1{
        @media (max-width: 760px) {
            display: none;
        }
    }
`

