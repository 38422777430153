import React, { useEffect, useState } from 'react'
//import styles
import { Footer } from '../styles/NavBarFooter';
//import icons from material ui
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
//import react router dom
import { Link } from 'react-router-dom';
import { spanish } from '../data/spanish';
//import material ui
import SearchIcon from '@mui/icons-material/Search';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled, useMediaQuery } from '@mui/material';
//import styles from material ui
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {

        backgroundColor: theme.palette.common.white,
        color: '#4A90E2',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}));




const NavBarFooter = ({ propName }) => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { navBarFooter: {
        textNavFooter1,
        textNavFooter2,
        textNavFooter3,
        textNavFooter4,
        textNavFooter5,
        textNavFooter6,
        textNavFooter7
    } } = textBody;

    //get userId from local storage
    const userId = localStorage.getItem('user');
    //
    const [botonActivo, setBotonActivo] = useState(null);

    useEffect(() => {
        if (propName) {
            //import prop from component father
            switch (propName) {
                case 'Home':
                    setBotonActivo(1)
                    break;
                case 'Notificaciones':
                    setBotonActivo(2)
                    break;
                case 'Mis Amigos':
                    setBotonActivo(3)
                    break;
                case 'Eventos':
                    setBotonActivo(4)
                    break;
                case userId:
                    setBotonActivo(5)
                    break;
                default:
                    break;
            }
        }
    }, [propName])

    // Use the useMediaQuery hook to check for window size between 760 and 1265 px
    const isMediumScreen = useMediaQuery('(min-width: 760px) and (max-width: 1265px)');

    return (
        <Footer>
            {isMediumScreen ? (
                <LightTooltip title={textNavFooter1} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className={botonActivo === 1 ? 'activo' : ''}
                        to="/home">
                        <HomeOutlinedIcon />
                        <span className='textNavFooter'>{textNavFooter1}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className={botonActivo === 1 ? 'activo' : ''}
                    to="/home">
                    <HomeOutlinedIcon />
                    <span className='textNavFooter'>{textNavFooter1}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter2} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className={botonActivo === 2 ? 'activo' : ''}
                        to="/notificaciones">
                        <NotificationsOutlinedIcon />
                        <span className='textNavFooter'>{textNavFooter2}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className={botonActivo === 2 ? 'activo' : ''}
                    to="/notificaciones">
                    <NotificationsOutlinedIcon />
                    <span className='textNavFooter'>{textNavFooter2}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter3} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className={botonActivo === 3 ? 'activo' : ''}
                        to="/mis-amigos">
                        <PeopleAltOutlinedIcon />
                        <span className='textNavFooter'>{textNavFooter3}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className={botonActivo === 3 ? 'activo' : ''}
                    to="/mis-amigos">
                    <PeopleAltOutlinedIcon />
                    <span className='textNavFooter'>{textNavFooter3}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter4} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className={botonActivo === 4 ? 'activo' : ''}
                        to="/eventos">
                        <EventNoteIcon />
                        <span className='textNavFooter'>{textNavFooter4}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className={botonActivo === 4 ? 'activo' : ''}
                    to="/eventos">
                    <EventNoteIcon />
                    <span className='textNavFooter'>{textNavFooter4}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter5} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className={botonActivo === 5 ? 'activo' : ''}
                        to={`/mi-perfil/${userId}`}>
                        <PersonOutlineOutlinedIcon />
                        <span className='textNavFooter'>{textNavFooter5}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className={botonActivo === 5 ? 'activo' : ''}
                    to={`/mi-perfil/${userId}`}>
                    <PersonOutlineOutlinedIcon />
                    <span className='textNavFooter'>{textNavFooter5}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter6} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className='secNujk1'
                        to='/buscador'><SearchIcon />
                        <span className='textNavFooter'>{textNavFooter6}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className='secNujk1'
                    to='/buscador'><SearchIcon />
                    <span className='textNavFooter'>{textNavFooter6}</span>
                </Link>
            )}

            {isMediumScreen ? (
                <LightTooltip title={textNavFooter7} placement="right" TransitionComponent={Zoom}>
                    <Link
                        className='secNujk1'
                        to='/mensajes'><ChatOutlinedIcon />
                        <span className='textNavFooter'>{textNavFooter7}</span>
                    </Link>
                </LightTooltip>
            ) : (
                <Link
                    className='secNujk1'
                    to='/mensajes'><ChatOutlinedIcon />
                    <span className='textNavFooter'>{textNavFooter7}</span>
                </Link>
            )}
        </Footer>
    )
}

export default NavBarFooter