import React, { useState } from 'react'
//import styles
import { Btn1, ContBtn } from '../styles/BtnPerfilOtrosU'
// import db firebase
import app from '../firebase/credenciales';
import { getFirestore, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
//import alert from react tostify
import { ToastContainer, toast } from 'react-toastify';
//import backdrop from material ui
import SimpleBackdrop from './BackDrop';

const BtnPerfilOtrosU = ({ propName }) => {

    // db firebase
    const firestore = getFirestore(app);
    //wait to charge data
    const [open, setOpen] = useState(false);

    // managing the form.......................
    const friendRequest = async (e) => {
        setOpen(true);
        e.preventDefault();
        // get the month, day, year, time
        const now = new Date().toISOString();

        //requesting id from local storage
        const userId = localStorage.getItem('user');

        // write to the DataBase
        const docuRef = doc(firestore, `usuarios/${propName.id}`);

        // Leer el documento existente para obtener el valor actual del campo "notifications.friendRequest"
        const docSnapshot = await getDoc(docuRef);
        // Verificar si el ID ya existe en el campo "notifications.friendRequest"
        const friendRequests = docSnapshot.data().notifications.friendRequest;
        if (friendRequests && friendRequests.some(item => Object.keys(item)[0] === userId)) {
            setTimeout(() => {
                setOpen(false);
            }, 1000);
            console.log("El ID ya existe en el campo");
            return;
        }
        // Crea un objeto con el ID personalizado y el valor correspondiente
        const item = {
            [userId]: now,
        }
        // Atomically agregar el objeto al campo "notifications.friendRequest"
        await updateDoc(docuRef, {
            "notifications.friendRequest": arrayUnion(item),
            });
            toast.success('Solicitud Enviada Con Exito!', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setOpen(false);
    }
    //.......................................

    return (
        <ContBtn>
            <ToastContainer />
            <SimpleBackdrop open={open} />

            <Btn1 onClick={friendRequest}>Agregar amigo</Btn1>
            <Btn1 to={`/conversacion/${propName.id}`}>Mensaje</Btn1>
        </ContBtn>
    )
}

export default BtnPerfilOtrosU