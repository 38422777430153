import React, { useEffect, useState } from 'react'
//import styles
import { CheckBuscamos } from '../styles/DatosPerfil';
import { ContFilters } from '../styles/Buscador';




const ParaHacerFilter = ({ onHandleParaHacer }) => {

    // change background color label
    const [labelColors, setLabelColors] = useState({});
    //change text color label
    const [checkboxTextColors, setCheckboxTextColors] = useState({});

    //..managint select 'Para hacer'.........
    //inicialisamos los select en false
    const [optionsHacer, setOptionsHacer] = useState({
        'Intercambio completo': false,
        'Mirar y ser vistos': false,
        'Intercambio Light': false,
        'Cuckold': false,
        'Sexo en grupo': false,
        'Practicas BDSM': false,
        'Trios': false,
        'Compartir fetiches': false,
        'Solo ellas': false,
        'Cybersexo': false,
        'Intercambio de fotos': false,
        'Sexo por separado': false,
    });
    // console.log(optionsHacer)

    //recibe el evento y extrae el nombre de la opción y el estado actualizado
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        //actualizar el estado con la nueva selección.
        setOptionsHacer(prevOptions => ({
            ...prevOptions,
            [name]: checked
        }));
        // change background color label
        setLabelColors(prevColors => ({
            ...prevColors,
            [name]: checked ? '#205285' : '#FFFFFF'
        }));
        // change text color label
        setCheckboxTextColors(prevColors => ({
            ...prevColors,
            [name]: checked ? '#FFFFFF' : '#707070'
        }));
    };
    const handleChange = (event) => {
        //crea un nuevo objeto llamado allOptions que contiene todas las opciones, 
        //tanto las seleccionadas como las no seleccionadas
        const handleSave = () => {
            //obtiene un array de pares clave-valor del objeto
            //utiliza reduce para construir el objeto allOptions a partir de ese array
            const allOptionsHacer = Object.entries(optionsHacer).reduce((accion, [key, value]) => {
                //Dentro de reduce, se asigna cada par clave-valor al nuevo objeto accion. 
                //Al final, se obtiene un objeto allOptionsHacer con todas las opciones, 
                //independientemente de si están seleccionadas (true) o no seleccionadas (false)
                accion[key] = value;
                return accion
            }, {});
            // se debuelve el objeto allOptions actualizado
            setOptionsHacer(allOptionsHacer);
        };
        //...................................
    };
    //enviamos el estado actualizado al padre
    useEffect(()=>{
        onHandleParaHacer(optionsHacer);
    },[optionsHacer])

    const optionListHacer = Object.keys(optionsHacer);

    return (
        <>
            <ContFilters>
                <label className='label1' >Busca</label>
                <span>
                    {
                        optionListHacer.map((item, index) => (
                            <CheckBuscamos
                                onChange={handleChange}
                                style={{ backgroundColor: labelColors[item], color: checkboxTextColors[item] }}
                                key={index}
                            >
                                <input
                                    type="checkbox"
                                    name={item}
                                    checked={optionsHacer[item]}
                                    onChange={handleCheckboxChange}
                                /><p>{item}</p>
                            </CheckBuscamos>
                        ))}
                </span>
            </ContFilters>
        </>
    )
}

export default ParaHacerFilter
