import React, { useEffect, useState } from 'react'
// toastify alert
import 'react-toastify/dist/ReactToastify.css';
//import firebasestorage
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import app from '../firebase/credenciales';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
//import styles 
import { ContFaterEditarImg, ContImgEditar, ContInputEditarImg, FileInputEdit } from '../styles/EditarPerfil';
// Obtén una referencia al almacenamiento de Firebase
const storage = getStorage();




const CambiarAvatar = ({ datosForm, estadoAvatar }) => {

    //get data from parent
    // const { avatar } = datosForm.avatar
    // db firebase
    const firestore = getFirestore(app)
    // create and managint inputs
    const [fileInputs, setFileInputs] = useState(Array(1).fill(null));
    //managint preview image
    const [previews, setPreviews] = useState(Array(1).fill(datosForm.avatar));
    //managint data form
    const [fileData, setFileData] = useState({});
    //managint avatar form
    const [avatar, setAvatar] = useState('');
    //
    const [eventTrigger, setEventTrigger] = useState(false);
    // pidiendo datos de local storage
    const userUid = localStorage.getItem('user')
    //shorted de console.log
    var c = console.log.bind(document);


    useEffect(() => {
        if (eventTrigger) {

            const sendFile = async () => {
                // pidiendo datos de local storage
                const userUid = localStorage.getItem('user')
                // write in DataBase
                const docuRef = doc(firestore, `usuarios/${userUid}`);
                //search in DataBase and type
                // c(docuRef)
                try {
                    await updateDoc(docuRef, {
                        avatar
                    });
                    console.log('Documento actualizado exitosamente', fileData);
                } catch (e) {
                    console.log('Error al actualizar el documento:', e);
                }
            }
            sendFile()
        }
    }, [fileData])

    //................................................
    const handleFileInputChange = async (e, index) => {
        const file = e.target.files[0];
        // console.log(file.name)
        setFileInputs((prevState) => {
            const updatedFileInputs = [...prevState];
            updatedFileInputs[index] = file;
            return updatedFileInputs;
        });
        // addFiledb(file);
        previewFile(file, index);
    };
    //................................................

    //................................................
    // Función para subir la foto a Firebase Storage
    async function subirFoto(fotos) {

        try {
            const nombres = Object.keys(fotos);
            const urls = [];

            for (const nombre of nombres) {
                const foto = fotos[nombre];

                //send each photo to database
                const fileRef = ref(storage, `/images/${userUid}`);

                //subiendo datos al Storage
                const snapshot = await uploadBytesResumable(fileRef, foto);
                //pidiendo datos a Storage de la ubicacion de cada foto
                const urlPromise = await getDownloadURL(snapshot.ref)

                urls.push(urlPromise);
            };
            if (urls.length !== 0) {
                setAvatar(urls[0])
                setFileData(urls);
                setEventTrigger(true)
            }
        } catch (error) {
            // console.log('Error al cargar las fotos:', error);
        }
    }
    //................................................

    //.preview de imagen que se va a subir............
    const previewFile = (file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviews((prevState) => {
                const updatedPreviews = [...prevState];
                updatedPreviews[index] = reader.result;
                return updatedPreviews;
            });
        };
    };
    //................................................

    

    //manejamos evento gestion imagenes 
    const sendImages = () => {

        //eliminando entradas vacias del array
        const elementosNoNulos = fileInputs.filter(elemento => elemento !== null);
        // evitar que pasen datos vacios o en 0
        const MayoresA1 = elementosNoNulos;
        //disparando la accion para subir las fotos a Storage
        subirFoto(MayoresA1)
    }
    //manejando estado desde el padre para disparar funcion sendImages()
    if(estadoAvatar){
        sendImages()
    }

    return (
        <>

            {fileInputs.map((file, index) => (
                <ContFaterEditarImg key={index}>
                    <ContImgEditar>
                        {previews[index] && <img src={previews[index]} alt="preview" />}
                    </ContImgEditar>
                    <ContInputEditarImg>Cambiar foto de perfil</ContInputEditarImg>
                    <FileInputEdit type="file" onChange={(e) => handleFileInputChange(e, index)} />
                </ContFaterEditarImg>
            ))}

        </>
    )
}

export default CambiarAvatar;
