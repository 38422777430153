import React, { useEffect, useState } from 'react'
//styles
import {
    CheckboxInputDP,
    ContCheckDP,
    ContInputPerfil,
    ContSelectPerfil,
    CustomDateInput,
    InputDate,
    SelectCountry,
    TextAreaPerfil
} from '../styles/DatosPerfil';
//import Util.js
import {
    fetchCountries,
    fetchHeight,
    fetchLanguages,
    fetchPenisLength,
    fetchPiercings,
    fetchSizeBra,
    fetchSmokes,
    fetchTattoo,
    fetchWeigth
} from '../Utils';
//import commponents
import GeoSearch from './GeoSearch';
//import functions from utils js
import { spanish } from '../data/spanish';

const PerfilForm = ({ onHandlePerfil }) => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { datosPerfil: {
        placeHolderSelect,
        labelAboutMe,
        labelCountry,
        labelCities,
        labelNames,
        placeHolderNames1,
        placeHolderNames2,
        labelSelecP,
        labelDate,
        labelOrientation,
        labelRace,
        labelNationality,
        labelWeight,
        labelHeight,
        labelBustSize,
        labelPenisLength,
        labelTattoos,
        labelPiercings,
        labelSmoke,
        labelDrinking,
        labelLanguages,
        
    }, formOrientacion } = textBody;
    //destructuring options
    const orientacion = Object.values(formOrientacion);

    let c = console.log.bind(document);

    //state countrye data
    const [countries, setCountries] = useState([]);
    //state countrye data
    const [languages, setLanguages] = useState([]);
    //state weight data
    const [weight, setWeight] = useState([]);
    //state Height data
    const [height, setHeight] = useState([]);
    //state Smoke data
    const [smokes, setSmokes] = useState([]);
    //state Penis Length
    const [penisLength, setPenisLength] = useState([]);
    //state SizeBra
    const [sizeBra, setSizeBra] = useState([]);
    //state Tattoos
    const [tattoos, setTattoos] = useState([]);
    //state Piercings
    const [piercings, setPiercings] = useState([]);
    //state of checked
    const [isChecked, setIsChecked] = useState(false);
    //state of country
    const [itemCountry, setItemCountry] = useState('');
    //state of city
    const [itemCity, setItemCity] = useState('');
    // c(itemCountry, itemCity)
    //managing form values..............
    const [perfil, setPerfil] = useState({
        about: "",
        country: itemCountry,
        city: itemCity,
        privateUser: isChecked,
        name_his: "",
        name_her: "",
        date_his: "",
        date_her: "",
        orientation_his: "",
        orientation_her: "",
        race_his: "",
        race_her: "",
        country_his: "",
        country_her: "",
        weight_his: "",
        weight_her: "",
        height_his: "",
        height_her: "",
        sizeBra_his: "N/A",
        sizeBra_her: "",
        penisLenght_his: "",
        penisLenght_her: "N/A",
        tattoo_his: "",
        tattoo_her: "",
        piercing_his: "",
        piercing_her: "",
        smokes_his: "",
        smokes_her: "",
        drink_his: "",
        drink_her: "",
        languages_his: "",
        languages_her: "",
    });
    // Establecer la edad mínima en 18 años
    const calculateMinDate = () => {
        const currentDate = new Date();
        const minDate = new Date();
        minDate.setFullYear(currentDate.getFullYear() - 18);
        return minDate.toISOString().split('T')[0];
    };
    //...................................

    //......default Value.select..........
    const defaultValue = 'N/A';
    //....................................

    //enviamos el estado actualizado al padre
    useEffect(() => {
        // console.log(perfil)
        onHandlePerfil(perfil);
    }, [perfil])
    //.......................................

    // import country data from Utils.js.......
    useEffect(() => {
        setLanguages(fetchLanguages());
        setWeight(fetchWeigth());
        setHeight(fetchHeight());
        setSmokes(fetchSmokes());
        setPenisLength(fetchPenisLength());
        setSizeBra(fetchSizeBra());
        setTattoos(fetchTattoo());
        setPiercings(fetchPiercings());

        const fetchCountriesData = async () => {
            try {
                const data = await fetchCountries();
                setCountries(data);
            } catch (error) {
                // Manejar el error según tus necesidades
            }
        };
        fetchCountriesData();

    }, []);
    //.......................................

    //..managint state check................
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    //.......................................


    //.managint state country and city ......
    useEffect(() => {
        setPerfil((prevPerfil) => ({
            ...prevPerfil,
            country: itemCountry,
            city: itemCity,
        }));
    }, [itemCountry, itemCity]);

    const handleRecibirEstados = (estado1, estado2) => {
        // Lógica para manejar los estados recibidos desde el hijo
        setItemCountry(estado2);
        setItemCity(estado1);
    };
    //.......................................

    return (
        <>
            <span >

                <ContInputPerfil>
                    <label>{labelAboutMe}</label>
                    <TextAreaPerfil id="about" type="about" name="about"
                        onChange={(e) => setPerfil({ ...perfil, about: e.target.value })}
                    ></TextAreaPerfil>
                </ContInputPerfil>{/* about */}

                <GeoSearch onEnviar={handleRecibirEstados} />{/* country and city */}

                <ContInputPerfil>
                    <label>{labelNames}</label>
                    <CustomDateInput>
                        <input
                            id="name_his" type="text" name="name_his" className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, name_his: e.target.value })}
                            required placeholder={`${placeHolderNames1}`}
                        />
                        <input
                            id="name_his" type="text" name="name_his" className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, name_her: e.target.value })}
                            required placeholder={`${placeHolderNames2}`}
                        />
                    </CustomDateInput>
                </ContInputPerfil>{/* name */}

                <ContCheckDP>
                    <CheckboxInputDP
                        type="checkbox"
                        name="rememberMe"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    /><span>{labelSelecP}</span>
                </ContCheckDP>{/* checkbox private */}

                <ContInputPerfil>
                    <label>{labelDate}</label>
                    <CustomDateInput>
                        <InputDate
                            id="date_his" type="date" name="date_his" className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, date_his: e.target.value })}
                            max={calculateMinDate()} // Establecer la fecha máxima permitida
                            required
                        />
                        <InputDate
                            id="date_her" type="date" name="date_her" className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, date_her: e.target.value })}
                            max={calculateMinDate()} // Establecer la fecha máxima permitida
                            required
                        />
                    </CustomDateInput>
                </ContInputPerfil>{/* date */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelOrientation}</label>
                    <div>
                        <select
                            id="orientation_his" name="orientation_his" required
                            onChange={(e) => setPerfil({ ...perfil, orientation_his: e.target.value })}
                        >
                            <option value="" hidden>{placeHolderSelect}</option>
                            {
                                orientacion.map((item) =>
                                    <option key={item} value={item}>{item}</option>
                                )
                            }
                        </select>
                        <select
                            id="orientation_her" name="orientation_her" required
                            onChange={(e) => setPerfil({ ...perfil, orientation_her: e.target.value })}
                        >
                            <option value="" hidden>{placeHolderSelect}</option>
                            {
                                orientacion.map((item) =>
                                    <option key={item} value={item}>{item}</option>
                                )
                            }
                        </select>
                    </div>
                </ContSelectPerfil>{/* orientation */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelRace}</label>
                    <div>
                        <select
                            id="race_his" name="race_his" required
                            onChange={(e) => setPerfil({ ...perfil, race_his: e.target.value })}
                        >
                            <option value="" hidden>{placeHolderSelect}</option>
                            <option value="Option 1" >Option 1</option>
                            <option value="Option 2" >Option 2</option>
                        </select>
                        <select
                            id="race_her" name="race_her" required
                            onChange={(e) => setPerfil({ ...perfil, race_her: e.target.value })}
                        >
                            <option value="" hidden>{placeHolderSelect}</option>
                            <option value="Option 1" >Option 1</option>
                            <option value="Option 2" >Option 2</option>
                        </select>
                    </div>
                </ContSelectPerfil>{/* race*/}

                <ContSelectPerfil>
                    <label className='form_label'>{labelNationality}</label>
                    <div>
                        <SelectCountry
                            id="country_his" name="country_his" required
                            onChange={(e) => setPerfil({ ...perfil, country_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {countries.map((country) => (
                                    <option key={country.name} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="country_her" name="country_her" required
                            onChange={(e) => setPerfil({ ...perfil, country_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {countries.map((country) => (
                                    <option key={country.name} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* country */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelWeight}</label>
                    <div>
                        <SelectCountry
                            type="text" id="weight_his" name="weight_his" required className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, weight_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {weight.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>

                        </SelectCountry>
                        <SelectCountry
                            type="text" id="weight_her" name="weight_her" required className="normalClass"
                            onChange={(e) => setPerfil({ ...perfil, weight_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {weight.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* weight */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelHeight}</label>
                    <div>
                        <SelectCountry
                            id="height_his" name="height_his" required
                            onChange={(e) => setPerfil({ ...perfil, height_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {height.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="height_her" name="height_her" required
                            onChange={(e) => setPerfil({ ...perfil, height_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {height.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* height */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelBustSize}</label>
                    <div>
                        <select
                            id="sizeBra_his" name="sizeBra_his" value={defaultValue} required disabled
                            onChange={(e) => setPerfil({ ...perfil, sizeBra_his: e.target.value })}
                        >
                            <option disabled >N/A</option>
                        </select>
                        <SelectCountry
                            id="sizeBra_her" name="sizeBra_her" required
                            onChange={(e) => setPerfil({ ...perfil, sizeBra_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {sizeBra.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* sizeBra */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelPenisLength}</label>
                    <div>
                        <SelectCountry
                            id="penisLenght_his" name="penisLenght_his" required
                            onChange={(e) => setPerfil({ ...perfil, penisLenght_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {penisLength.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <select
                            id="penisLenght_her" name="penisLenght_her" value={defaultValue} required disabled
                            onChange={(e) => setPerfil({ ...perfil, penisLenght_her: e.target.value })}
                        >
                            <option disabled >N/A</option>
                        </select>
                    </div>
                </ContSelectPerfil>{/* penisLenght */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelTattoos}</label>
                    <div>
                        <SelectCountry
                            id="tattoo_his" name="tattoo_his" required
                            onChange={(e) => setPerfil({ ...perfil, tattoo_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {tattoos.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="tattoo_her" name="tattoo_her" required
                            onChange={(e) => setPerfil({ ...perfil, tattoo_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {tattoos.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* tattoo */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelPiercings}</label>
                    <div>
                        <SelectCountry
                            id="piercing_his" name="piercing_his" required
                            onChange={(e) => setPerfil({ ...perfil, piercing_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {piercings.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="piercing_her" name="piercing_her" required
                            onChange={(e) => setPerfil({ ...perfil, piercing_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {piercings.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* piercing*/}

                <ContSelectPerfil>
                    <label className='form_label'>{labelSmoke}</label>
                    <div>
                        <SelectCountry
                            id="smokes_his" name="smokes_his" required
                            onChange={(e) => setPerfil({ ...perfil, smokes_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {smokes.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="smokes_her" name="smokes_her" required
                            onChange={(e) => setPerfil({ ...perfil, smokes_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {smokes.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* smokes*/}

                <ContSelectPerfil>
                    <label className='form_label'>{labelDrinking}</label>
                    <div>
                        <SelectCountry
                            id="drink_his" name="drink_his" required
                            onChange={(e) => setPerfil({ ...perfil, drink_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {smokes.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="drink_her" name="drink_her" required
                            onChange={(e) => setPerfil({ ...perfil, drink_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {smokes.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* drink */}

                <ContSelectPerfil>
                    <label className='form_label'>{labelLanguages}</label>
                    <div>
                        <SelectCountry
                            id="languages_his" name="languages_his" required
                            onChange={(e) => setPerfil({ ...perfil, languages_his: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {languages.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                        <SelectCountry
                            id="languages_her" name="languages_her" required
                            onChange={(e) => setPerfil({ ...perfil, languages_her: e.target.value })}
                        >
                            <select id="nationality">
                                <option value="" disabled selected>{placeHolderSelect}</option>
                                {languages.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </SelectCountry>
                    </div>
                </ContSelectPerfil>{/* languages */}
            </span >
        </>
    )
}

export default PerfilForm