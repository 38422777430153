import styled from "styled-components";

export const ContFather = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(31,72,126);
    background: linear-gradient(45deg, rgba(31,72,126,1) 0%, rgba(36,123,160,1) 32%, rgba(35,112,153,1) 54%, rgba(31,72,126,1) 100%);
`
export const ContForm = styled.div`
    border: 1px solid var(--color2);//..................
    background-color: var(--color3);
    width: 85%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 35px;
    padding: 1em;
    h1{
        display: none;
    }
`
export const BtnLogin = styled.div`
    /* border: 1px solid red;//.................. */
    display: flex;
    width: 40%;
    margin: auto;
    .Cont_logos{
        width: 50%;
        display: grid;
        place-items: center;
        svg{
            /* border: 1px solid red;//.................. */
            font-size: 2.5em;
            cursor: pointer;
            color: var(--color2);
            &:active{
                transform: scale(0.95);
            }
        }
    }
`
export const ContInput = styled.div`
    display: flex;
    flex-direction: column;
    label{
        /* border: 1px solid red;//.................. */
        color: var(--color2);
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
    }
    input{
        height: 51px;
        border-radius: 15px;
        border: 2px solid transparent;
        padding: 0 1em;
        margin-bottom: .5em;
        /* Código para sombra compatible con todos los navegadores */
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    span{
        /* border: 1px solid red;//.................. */
        position: relative;
        cursor: pointer;
        color: var(--color10);
        bottom: 45px;
        width: 25px;
        left: 90%;
    }
`
export const ContCheck = styled.label`
    /* border: 1px solid red;//.................. */
    width: 100%;
    margin: 1em 0;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
        width: 90%;
        font-size: 14px;
        color: var(--color2);
    }
`
export const CheckboxInput = styled.input `
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color4);
    position: relative;
    transition: all 0.3s ease;
    margin-right: .5em;

    &:checked {
        background-color: var(--degradado2);
    }

    &:checked::after {
        content: "\\2714";
        font-size: 14px;
        color: var(--color3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        cursor: pointer;
    }
`
export const BtnLogin1 = styled.button`
    width: 100%;
    height: 51px;
    border-radius: 15px;
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 700;
    color: var(--color3);
    background-color: var(--color1);
    cursor: pointer;
    &:hover{
        background-color: var(--degradado2);
    }
`
export const ContP = styled.div`
    /* border: 1px solid red;//.................. */
    color: var(--color2);
    display: flex;
    justify-content: center;
    margin: 1em 0;
    p{
        margin:0!important;
    }
    a{
        color: var(--degradado1);
        &:active{
            color: var(--degradado2);
        }
    }
`
export const Form = styled.form`
    margin-top: 0px;
    text-align: center;
    justify-content: center;
`