import React, { useContext, useState } from 'react'
//import context
import MyContext from '../Context/MyContext';
//import components
import NavBarAux from '../components/NavBarAux'
import SimpleBackdrop from '../components/BackDrop';
import EditarPerfilForm from '../components/EditarPerfilForm';




const EditarPerfil = ({enviarDatoMiPerfil}) => {

    //wait to charge data...............
    const [open, setOpen] = useState(false);
    //get data from useContext
    const userData = useContext(MyContext);


    return (
        <>
            <SimpleBackdrop open={open} />
            <NavBarAux propName={'Editar Perfil'} />

            {userData.length === 0 ?
                (<p>Cargando datos...</p>)
                :
                (<EditarPerfilForm userData={userData} />)}

        </>
    )
}

export default EditarPerfil;