import React, { useContext, useEffect, useState } from 'react';
//import components
import NavBarAux from '../components/NavBarAux';
import NavBarFooter from '../components/NavBarFooter';
//import global context
import MyContext from '../Context/MyContext';
//import data
import { spanish } from '../data/spanish';
//import icon from material ui
import GroupIcon from '@mui/icons-material/Group';
//import styles
import { ContNoFriends } from '../styles/Amigos'




const Amigos = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { Friends: { textFriends1, textFriends2 } } = textBody;

    //get user id
    const userId = localStorage.getItem('user');
    //get data from useContext
    const userData = useContext(MyContext);
    //filter data
    const filterData = userData.filter(item => item.id === userId);
    //state friends 
    const [friends, setFriends] = useState()


    useEffect(() => {
        //destructuring filter data
        const [findData] = filterData;
        if (findData) {
            // finding specific data
            const { friends } = findData;
            setFriends(friends)
        }

    }, [filterData])

    return (
        <>
            <NavBarAux propName={'Mis Amigos'} />
            
            {friends ? 'muchos amigos' :
                <ContNoFriends>
                    <GroupIcon />
                    <h1>{textFriends1}</h1>
                    <p>{textFriends2}</p>
                </ContNoFriends>}

            <NavBarFooter propName={'Mis Amigos'} />
        </>
    )
}

export default Amigos