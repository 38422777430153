import React, { useState } from 'react';
//import styles
import { BtnClose, BtnFilter, BtnFiltro, ContForm, ContModalFilter } from '../styles/Buscador';
import styled from 'styled-components';
// import icon from material ui
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import badge from mui
import Badge from '@mui/material/Badge';
import { Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//import commponents
import ParaHacerFilter from './ParaHacerFilter';
import BuscamosFilter from './BuscamosFilter';
import GeoSearchFilter from './GeoSearchFilter';
import { sessionStorageWrite } from '../Utils';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -12,
        top: -3,
        padding: '5px 1px 1px 1px',
        fontWeight: "900",
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FiltroBuscador = ({ onClick, text3Search, numberFilters }) => {


    //child state change'para hacer'
    const [estadoParaHacer, setEstadoParaHacer] = useState({});
    //child state change'para hacer'
    const [estadoBuscamos, setEstadoBuscamos] = useState({});
    //state of country
    const [itemCountry, setItemCountry] = useState('');
    //state of city
    const [itemCity, setItemCity] = useState('');
    //state from close modal window
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //function that receives the state of the child 'Buscamos'
    const handleBuscamos = (newState) => {
        setEstadoBuscamos(newState);
    };

    //function that receives the state of the child 'para hacer'
    const handleParaHacer = (newState) => {
        setEstadoParaHacer(newState);
    };

    //.managint state country and city ......
    const handleRecibirEstados = (estado1, estado2) => {
        // Lógica para manejar los estados recibidos desde el hijo
        setItemCountry(estado2);
        setItemCity(estado1);
    };
    //.......................................

    //.......................................
    const handleFilter = (e) => {
        e.preventDefault();
        onClick()
        // Convertir los datos en formato JSON
        const country = itemCountry === '' ? false : JSON.stringify(itemCountry);
        const city = itemCity === '' ? false : JSON.stringify(itemCity);

        const objetoBuscamos = {};

        Object.entries(estadoBuscamos).forEach(([clave, valor]) => {
            if (valor === true) {
                objetoBuscamos[clave] = valor;
            }
        });

        const objetoParaHacer = {};

        Object.entries(estadoParaHacer).forEach(([clave, valor]) => {
            if (valor === true) {
                objetoParaHacer[clave] = valor;
            }
        });

        const obj1 = Object.entries(objetoBuscamos).length === 0; // true
        const obj2 = Object.entries(objetoParaHacer).length === 0; // true

        const buscamos = obj1 === true ? false : JSON.stringify(objetoBuscamos);
        const paraHacer = obj2 === true ? false : JSON.stringify(objetoParaHacer);

        if (itemCountry === '' && itemCity === '' && obj1 === true && obj2 === true) {
            // borrar los datos en el sessionStorage
            sessionStorageWrite(false, false, false, false)
        } else {
            // Guardar los datos en el sessionStorage
            sessionStorageWrite(country, city, buscamos, paraHacer)
        };
        setOpen(false);
    }
    //.......................................



    return (
        <div>
            <BtnFiltro onClick={handleClickOpen} >
                {text3Search}
                {numberFilters === 0 ?
                    <KeyboardArrowDownIcon />
                    :
                    <StyledBadge
                        badgeContent={numberFilters}
                        color="primary"
                    />}


            </BtnFiltro>
            <ContModalFilter TransitionComponent={Transition} fullScreen open={open} onClose={handleClose}>
                <ContForm >
                    <BtnClose onClick={handleClose}><CloseIcon /></BtnClose>
                    <DialogContent>
                        <GeoSearchFilter open={open} onEnviar={handleRecibirEstados} />
                        <BuscamosFilter onHandleBuscamos={handleBuscamos} />
                        <ParaHacerFilter onHandleParaHacer={handleParaHacer} />
                    </DialogContent>
                    <DialogActions>
                        <BtnFilter
                            onClick={(e) => handleFilter(e)}
                        >
                            Aplicar Filtro
                        </BtnFilter>
                    </DialogActions>
                </ContForm>

            </ContModalFilter>
        </div>
    );
}

export default FiltroBuscador