import React from 'react';
//import components from material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop({ open }) {


    return (
        <>
            <Backdrop
                sx={{ color: '#205285', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            ><CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}