import React from 'react';
//import skeletons fromn materia ui
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
//import styles
import { CardUserH, ContSkeleton } from '../../styles/NuevosUsuarios';

export default function SkeletonNuevosUsuarios() {

    // create and managint skeletons
    const fileInputs = (Array(3).fill(null));

    return (
        <>
            <ContSkeleton>
                {fileInputs.map((index) => (
                    <CardUserH>
                        <Stack key={`input_${index}`}  spacing={1}>
                            <Skeleton variant="circular" width={100} height={100} />
                            <Skeleton variant="text" width="100%" sx={{ fontSize: '1rem' }} />
                        </Stack>
                    </CardUserH>
                ))}
            </ContSkeleton>
        </>
    );
}