import styled, { keyframes } from "styled-components";
import { Placeholder } from "./Assets";
import { Link } from "react-router-dom";

const Animation = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`
export const ContUsuariosH = styled.section`
    /* border: 1px solid red;//..................... */
    width: 100%;
    max-width: 800px;
    margin: 3.2em auto;
    
`
export const ContUsuariosNew = styled.section`
    /* border: 1px solid red;//..................... */
    width: 100%;
    height: max-content;
    max-width: 800px;
    margin: 3.2em auto;
`
export const ContCardsNew = styled.div`
    /* border: 1px solid red;//.................. */
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
`
export const CardUserNew = styled(Link)`
    /* border: 1px solid red;//.................. */
    margin: 1em;
    width: 119px;
    height: 151px;
    border-radius: .8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    /* Código para sombra compatible con todos los navegadores */
    box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    p{
        margin: 0;
        color: var(--color2);
        font-weight: 600;
    }
`
export const ContTitle = styled.div`
    /* border: 1px solid red;//..................... */
    display: flex;
    color: var(--color2);
    font-weight: 700;
    width: 95%;
    max-width: 700px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
        text-decoration: none;
        color: var(--color2);
        font-weight: 400;
    }
`
export const ContCardsH = styled.div`
    /* border: 1px solid red;//.................. */
    box-sizing: content-box;
    margin: .3em auto;
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    padding: 1em 0;
    width: 95%;
    max-width: 700px;
    scroll-snap-type: x mandatory;
    scroll-padding:1em;
    gap: 1em;
    @media (max-width:700px) {
        justify-content: unset;
    }
`
export const CardUserH = styled(Link)`
    /* border: 1px solid red;//.................. */
    flex: 0 0 119px;
    scroll-snap-align:initial;
    width: 119px;
    height: 151px;
    border-radius: .8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
    /* Código para sombra compatible con todos los navegadores */
    box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    p{
        margin: 0;
        color: var(--color2);
        font-weight: 600;
    }
`
export const ContImgH = styled.div`
    /* border:1px solid red;//................. */
    border-radius: 50%;
    width: 85%;
    height: 70%;
    display: grid;
    place-content: center;
    overflow: hidden;
    /* animation background */
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${Animation};
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    /* .....................*/
    img{
        width: 103%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center 
    }
`
export const PlaceholderImg = styled(Placeholder)`
    /* border:1px solid red;//................. */
    width: 31500px;
    object-fit: cover;
    object-position: center center; 
`
export const ContSkeleton = styled.div`
    /* border: 1px solid red;//................. */
    width: 95%;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`