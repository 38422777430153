import { format } from 'date-fns';
import { es } from 'date-fns/locale';

//function from calculate exact age of user..................................
export function calculateAge(birthDate) {
    // año en curso
    const currentDate = new Date();
    //fecha ingresada por el usuario
    const birth = new Date(birthDate);

    let age = currentDate.getFullYear() - birth.getFullYear();

    // Verificar si el cumpleaños ya ha ocurrido este año o no
    const currentMonth = currentDate.getMonth();
    const birthMonth = birth.getMonth();
    const currentDay = currentDate.getDate();
    const birthDay = birth.getDate();

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    return age;
}
//..............................................................................

// function to obtain the list of countries.....................................
const fetchData = async () => {
    try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching countries:', error);
        return [];
    }
};

export const fetchCountries = async () => {
    const countries = await fetchData();
    // Filtrar y ordenar los países
    const filteredCountries = countries.map((country) => ({
        code: country.cca2,
        name: country.translations.spa.common,
    }));

    filteredCountries.sort((a, b) => a.name.localeCompare(b.name));

    return filteredCountries;
};

//..............................................................................

// function to obtain the list of languages.....................................
export const fetchLanguages = () => {
    const languages = [
        'Inglés ',
        'Chino mandarín',
        'Español ',
        'Francés',
        'Ruso',
        'Portugués',
        'Alemán',
        'Japonés ',
        'Italiano',
    ];
    languages.sort((a, b) => a.localeCompare(b));

    return languages;
}
//..............................................................................

// function to obtain the list of Weigth.....................................
export const fetchWeigth = () => {
    let weight = [];
    for (let i = 40; i < 300; i += 5) {
        weight.push(i + ' Kg')
    };
    return weight;
}
//............................................................................

// function to obtain the list of Height.....................................
export const fetchHeight = () => {
    let weight = [];
    for (let i = 90; i < 210; i++) {
        weight.push(i + ' Cm')
    };
    return weight;
}
//..............................................................................

// function to obtain the list of Smokes.....................................
export const fetchSmokes = () => {
    const languages = [
        'Nunca',
        'Rara vez',
        'Socialmente',
        'Regularmente',
        'Asiduo',
    ];

    return languages;
}
//..............................................................................

// function to obtain the list of Penis Length.....................................
export const fetchPenisLength = () => {
    let PenisLength = [];
    for (let i = 4; i < 25; i++) {
        PenisLength.push(i + ' Cm')
    };
    return PenisLength;
}
//..............................................................................

// function to obtain the list of Bra.....................................
export const fetchSizeBra = () => {
    let sizeBra = [];
    for (let i = 30; i < 46; i += 2) {
        sizeBra.push(i)
    }

    return sizeBra;
}
//..............................................................................

// function to obtain the list of Tattoo.....................................
export const fetchTattoo = () => {
    const tattoos = [
        'Ninguno',
        'Uno Pequeño',
        'Varios Pequeños',
        'Muchos',
        'Todo El Cuerpo',

    ];

    return tattoos;
}
//..............................................................................

// function to obtain the list of Piercings.....................................
export const fetchPiercings = () => {
    const tattoos = [
        'Ninguno',
        'Rostro',
        'Lengua',
        'Pezónes ',
        'Zona Intima',
        'Otro',

    ];

    return tattoos;
}
//..............................................................................

// function to obtain the list of Titles Table....................................
export const fetchTitlesTable = () => {
    const titles = [
        'Nombre:',
        'Edad:',
        'Orientacion:',
        'Raza',
        'Nacionalidad:',
        'Peso:',
        'Altura:',
        'Busto:',
        'Pene:',
        'Tatuajes:',
        'Piercings:',
        'Fuma:',
        'Bebe Alcohol:',
        'Idioma:',
    ];

    return titles;
}
//..............................................................................

//..function to uppercase first letter .........................................
export const capitalize = (word) => {
    return word[0].toUpperCase() + word.slice(1);
}
//..............................................................................

// Función de comparación para ordenar por fecha................................
export function compararFechas(a, b) {
    var fechaA = new Date(a.fecha);
    var fechaB = new Date(b.fecha);
    return fechaB - fechaA;
}
//..............................................................................

//.......formatear la fecha utilizando la biblioteca moment.js..................
export function formatearFecha(fechaOriginal) {
    const fechaFormateada = format(new Date(fechaOriginal), "MMMM dd", { locale: es });
    return fechaFormateada;
}
//..............................................................................

//............escribir datos en session storage.....................
export function sessionStorageWrite(data1, data2, data3, data4) {
    sessionStorage.setItem('filter1', data1);
    sessionStorage.setItem('filter2', data2);
    sessionStorage.setItem('filter3', data3);
    sessionStorage.setItem('filter4', data4);
}
//..............................................................................