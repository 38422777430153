import React, { useState } from 'react'
//styles
import { Form } from "../styles/Login";
import {
    BtnSignUp,
    ContFormSignUp,
    ContSignUp,
    DivTitle
} from "../styles/SingUp";
// import db firebase
import app from '../firebase/credenciales';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
//import react router dom 
import { useNavigate } from 'react-router-dom';
// import components backdrop
import SimpleBackdrop from "../components/BackDrop";
import ParaHacerSelect from '../components/ParaHacerSelect';
import BuscamosSelect from '../components/BuscamosSelect';
import PerfilForm from '../components/PerfilForm';
//import data
import { spanish } from '../data/spanish';




const DatosPerfil = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { datosPerfil: { titleDatosPerfil, boton } } = textBody;


    // db firebase
    const firestore = getFirestore(app)
    //navigate to other page
    const navigate = useNavigate();
    //child state change'para hacer'
    const [estadoParaHacer, setEstadoParaHacer] = useState({});
    //child state change'para hacer'
    const [estadoBuscamos, setEstadoBuscamos] = useState({});
    // child state change'perfil'
    const [perfil, setPerfil] = useState({});

    //function that receives the state of the child 'perfil'
    const handlePerfil = (newState) => {
        setPerfil(newState);
    };
    //function that receives the state of the child 'Buscamos'
    const handleBuscamos = (newState) => {
        setEstadoBuscamos(newState);
    };
    //function that receives the state of the child 'para hacer'
    const handleParaHacer = (newState) => {
        setEstadoParaHacer(newState);
    };



    //wait to charge data
    const [open, setOpen] = useState();
    //managing form values..............

    //...................................

    // managing the form.......................
    const submitHandler = async (e) => {
        setOpen(true);
        e.preventDefault();
        // pidiendo datos de local storage
        const userUid = localStorage.getItem('user')
        // write to the DataBase
        const docuRef = doc(firestore, `usuarios/${userUid}`);
        //search in DataBase and type
        try {

            await updateDoc(docuRef, {
                perfil_complete: true,
                perfil,
                buscamos: estadoBuscamos,
                para_hacer: estadoParaHacer,
            });
            //navigating to next page
            navigate('/agregar-fotos');
            setOpen(false);
            console.log('Documento actualizado exitosamente');
        } catch (e) {
            console.log('Error al actualizar el documento:', e);
        }
    }
    //.......................................


    return (
        <>
            <ContSignUp >

                <SimpleBackdrop open={open} />

                <ContFormSignUp>

                    <Form onSubmit={submitHandler}>
                        <DivTitle >
                            <h1>{titleDatosPerfil}</h1>
                        </DivTitle >

                        <PerfilForm onHandlePerfil={handlePerfil} />
                        <BuscamosSelect onHandleBuscamos={handleBuscamos} />
                        <ParaHacerSelect onHandleParaHacer={handleParaHacer} />


                        <div>
                            <BtnSignUp type="submit">{boton}</BtnSignUp>
                        </div>
                    </Form>

                </ContFormSignUp>
            </ContSignUp >
        </>
    )
}

export default DatosPerfil