/* eslint-disable import/no-anonymous-default-export */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const keys firebase
const API_KEY = `${process.env.REACT_APP_APIKEY}`;
const AUTHDOMAIN = `${process.env.REACT_APP_AUTHDOMAIN}`;
const PROJECTID = `${process.env.REACT_APP_PROJECTID}`;
const MESSAGINGSENDERID = `${process.env.REACT_APP_MESSAGINGSENDERID}`;
const APPID = `${process.env.REACT_APP_APPID}`;
const MEASUREMENTID = `${process.env.REACT_APP_MEASUREMENTID}`;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: API_KEY,
    authDomain: AUTHDOMAIN,
    projectId: PROJECTID,
    storageBucket: "red-social-sw-d7b14.appspot.com",
    messagingSenderId: MESSAGINGSENDERID,
    appId: APPID,
    measurementId: MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;