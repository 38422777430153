import styled from "styled-components";


export const ContImg = styled.div`
    border: 1px solid red;//.................
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const ContInputName = styled.div`
    border-top: 1px solid var(--degradado3);
    border-bottom: 1px solid var(--degradado3);
    padding: .7em 0 ;
    margin-bottom: 1em;
    display: flex;
    color: var(--color2);
    label{
        font-size:.8em;
        padding-right: 2em;
    }
    input{
        border: none;
        padding: 0 2em;
        color: var(--color2);
    }
`
export const ContFaterEditarImg = styled.div`
    /* border: 1px solid blue;//................ */
    width: 100%;
    height: 180px;
    max-height: 300px;
    margin-bottom: .6em;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ContImgEditar= styled.div`
    /* border: 1px solid red;//................ */
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--color8);
    img{
        /* border: 1px solid red;//................ */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
`
export const ContInputEditarImg = styled.div`
    /* border: 1px solid red;//................ */
    text-align: center;
    position: relative;
    top: 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer!important;
    background-color: transparent;
    color: var(--color4);
    z-index:0;
	overflow: hidden;
`
export const FileInputEdit = styled.input.attrs({
    type: "file",
})`
    border: 1px solid red;//................
    opacity: 0;
    z-index:1;
    width:100%;
    cursor: pointer!important;
    padding:1em 0;
    + label {
        font-size: 0em;
        display: inline-block;
    cursor: pointer!important;
    }
`
