import React, { useContext, useEffect, useState } from 'react'
//import components
import NavBarAux from '../components/NavBarAux'
import FiltroBuscador from '../components/FiltroBuscador';
import SkeletonBuscador from '../components/Skeletons/SkeletonBuscador';
//import material ui icon
import SearchIcon from '@mui/icons-material/Search';
//import styles
import { ConTextCard, ContCard, ContImgSearch, ContSearch, ContTitle, TitleCard } from '../styles/Buscador';
//import global context
import MyContext from '../Context/MyContext';
//import functions from utils js
import { calculateAge, capitalize, compararFechas } from '../Utils';
//import data
import { spanish } from '../data/spanish';



const Buscador1 = () => {

    var c = console.log.bind(document);
    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { buscador: { placeholder,
        searchFilter: { text1Search, text2Search, text3Search }
    } } = textBody;

    //get data from useContext
    const userData = useContext(MyContext);
    //get data from local storage
    const userId = localStorage.getItem('user');
    //filter data
    const filterData = userData.filter(item => item.id !== userId);
    //sacamos del array a usuarios con perfil vacio
    const usersFullPerfil = filterData.filter(item => item.perfil !== false);
    //extrac values from array
    const dataSearch = Object.values(usersFullPerfil.sort(compararFechas));
    //estate input search
    const [query, setQuery] = useState('');
    //estate data filter Search
    const [filter, setFilter] = useState('');
    //state to managing tab active
    const [activeTab, setActiveTab] = useState('tab1');
    //state from button modal search
    const [searchFilter, setSearchFilter] = useState([]);
    // active status search filter from child
    const [searchFil, setSearchFil] = useState(false);


    //function managing text search.................
    const handleInputChange = (event) => {
        // capture input text
        const inputValue = event.target.value;
        //transform de first letter to uppercase
        const convertedText = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        //managing estate from input
        setQuery(convertedText);

        //filter data and convert text to input to lowercase
        const filterSearch = usersFullPerfil.filter(item => item.name.includes(event.target.value.toLowerCase()));

        //set data
        setFilter(filterSearch);
    };
    //...............................................

    // Here we use the filter() method to iterate over the array of users and check each filter condition in the arrow function. 
    // filter condition is checked in the arrow function. The variables filter1, filter2, filter3 and filter4 
    // contain the values you want to look for in each filter respectively.
    let filter1 = JSON.parse(sessionStorage.getItem('filter1'));
    let filter2 = JSON.parse(sessionStorage.getItem('filter2'));
    let filter3 = JSON.parse(sessionStorage.getItem('filter3'));
    let filter4 = JSON.parse(sessionStorage.getItem('filter4'));

    useEffect(() => {
        if (query.length >= 1) {
            setActiveTab('tab2');
        }
    }, [query]);

    useEffect(() => {
        setTimeout(() => {
            if (filter1 === null) {
                setActiveTab('tab1');
            } else if (filter1) {
                Filters();
            }
        }, 1000);
    }, [])

    // useEffect se ejecuta cada vez que alguna de las variables de estado cambia
    useEffect(() => {
        // Actualizar el estado cuando se produzca un cambio en filter1
        setTimeout(() => {
            if (filter1 || filter2 || filter3 || filter4) {
                Filters();
            }
        }, 2000);
    }, [searchFil]);

    // funcion que filtra los usuarios
    const Filters = () => {
        // Filtrar los usuarios basados en los filtros
        // Filtrar basado en filter1
        const filteredUsersStep1 = filterData.filter(user => {
            return user.perfil.country === filter1;
        });

        // Filtrar basado en filter2
        const filteredUsersStep2 = filteredUsersStep1.filter(user => {
            return user.perfil.city === filter2;
        });

        // Filtrar basado en filter3
        const filteredUsersStep3 = filteredUsersStep2.filter(user => {
            if (user.buscamos && filter3) {
                const filter3Keys = Object.keys(filter3);
                const userBuscamosKeys = Object.keys(user.buscamos);

                for (const key of filter3Keys) {
                    if (userBuscamosKeys.includes(key) && user.buscamos[key] === filter3[key]) {
                        return true;
                    }
                }
            }
            return false;
        });

        // Filtrar basado en filter4
        const filteredUsersFinal = filteredUsersStep3.filter(user => {
            if (user.para_hacer && filter4) {
                const filter4Keys = Object.keys(filter4);
                const userHacemosKeys = Object.keys(user.para_hacer);

                for (const key of filter4Keys) {
                    if (userHacemosKeys.includes(key) && user.para_hacer[key] === filter4[key]) {
                        return true;
                    }
                }
            }
            return false;
        });

        // El array ahora contiene los usuarios que coinciden con todos los filtros.

        if (filteredUsersFinal.length !== 0) {
            setSearchFilter(filteredUsersFinal)
            setActiveTab('tab3');
        } else if (filteredUsersStep3.length !== 0) {
            setSearchFilter(filteredUsersStep3)
            setActiveTab('tab3');
        } else if (filteredUsersStep2.length !== 0) {
            setSearchFilter(filteredUsersStep2)
            setActiveTab('tab3');
        } else if (filteredUsersStep1.length !== 0) {
            setSearchFilter(filteredUsersStep1)
            setActiveTab('tab3');
        } else if (filteredUsersFinal.length === 0) {
            setActiveTab('tab1');
        }

    }
    //we hear click from modal window 
    const handleClickFunction = () => {
        setSearchFil(!searchFil)
    }

    // Inicializar el contador en 0
    let contador = 0;

    // Verificar si alguna de las variables es diferente de false y sumar al contador en consecuencia
    if (filter1 !== false && filter1 !== null) {
        contador++;
    }

    if (filter2 !== false && filter2 !== null) {
        contador++;
    }

    // Incrementar contador adicional para filter3 y filter4
    let countFilter3 = 0;
    let countFilter4 = 0;

    // Verificar si filter3 y filter4 contienen elementos y cuantos elementos son
    if (Array.isArray(filter3)) {
        countFilter3 = filter3.length;
    } else if (typeof filter3 === 'object' && filter3 !== null) {
        countFilter3 = Object.keys(filter3).length;
    }

    if (Array.isArray(filter4)) {
        countFilter4 = filter4.length;
    } else if (typeof filter4 === 'object' && filter4 !== null) {
        countFilter4 = Object.keys(filter4).length;
    }

    let numberFilters = (contador + countFilter3 + countFilter4);

    return (
        <div>
            <NavBarAux propName={'Buscar'} />
            <br /><br /><br />

            <form >
                <ContSearch>
                    <input type="search" placeholder={placeholder} value={query} onChange={handleInputChange} />
                    <SearchIcon />
                </ContSearch>
            </form>

            <ContTitle>
                <h1>{text1Search}</h1>
                <p>{text2Search}</p>
                <FiltroBuscador
                    text3Search={text3Search}
                    numberFilters={numberFilters}
                    onClick={handleClickFunction}
                />
            </ContTitle>

            {dataSearch.length === 0 && <SkeletonBuscador />}

            {activeTab === 'tab1' && dataSearch.map((item) => {

                //calculate age
                const ageHer = calculateAge(item.perfil.date_her);
                const ageHis = calculateAge(item.perfil.date_his);
                //uppercase first letter
                const nameUserCap = capitalize(item.name);

                return (
                    <ContCard to={`/perfil-otros-usuarios/${item.id}`} key={item.id}>
                        <ContImgSearch><img src={item.avatar} alt="image_avatar" /></ContImgSearch>
                        <span>
                            <TitleCard>{nameUserCap}</TitleCard>
                            <ConTextCard>
                                <p>{ageHer}</p>
                                <p>({item.perfil.orientation_her.substring(0, 6)})</p>
                                <p>y</p>
                                <p>{ageHis}</p>
                                <p>({item.perfil.orientation_his.substring(0, 6)})</p>
                            </ConTextCard>
                            <ConTextCard>
                                <p>{item.perfil.city},</p>
                                <p>{item.perfil.country}</p>
                            </ConTextCard>
                        </span>
                    </ContCard>
                )
            })}

            {activeTab === 'tab2' && filter.map((item) => {

                //calculate age
                const ageHer = calculateAge(item.perfil.date_her);
                const ageHis = calculateAge(item.perfil.date_his);
                //uppercase first letter
                const nameUserCap = capitalize(item.name);

                return (
                    <ContCard to={`/perfil-otros-usuarios/${item.id}`} key={item.id}>
                        <ContImgSearch><img src={item.avatar} alt="image_avatar" /></ContImgSearch>
                        <span>
                            <TitleCard>{nameUserCap}</TitleCard>
                            <ConTextCard>
                                <p>{ageHer}</p>
                                <p>({item.perfil.orientation_her.substring(0, 6)})</p>
                                <p>y</p>
                                <p>{ageHis}</p>
                                <p>({item.perfil.orientation_his.substring(0, 6)})</p>
                            </ConTextCard>
                            <ConTextCard>
                                <p>{item.perfil.city},</p>
                                <p>{item.perfil.country}</p>
                            </ConTextCard>
                        </span>
                    </ContCard>
                )
            })}

            {activeTab === 'tab3' && searchFilter.map((item) => {

                //calculate age
                const ageHer = calculateAge(item.perfil.date_her);
                const ageHis = calculateAge(item.perfil.date_his);
                //uppercase first letter
                const nameUserCap = capitalize(item.name);

                return (
                    <ContCard to={`/perfil-otros-usuarios/${item.id}`} key={item.id}>
                        <ContImgSearch><img src={item.avatar} alt="image_avatar" /></ContImgSearch>
                        <span>
                            <TitleCard>{nameUserCap}</TitleCard>
                            <ConTextCard>
                                <p>{ageHer}</p>
                                <p>({item.perfil.orientation_her.substring(0, 6)})</p>
                                <p>y</p>
                                <p>{ageHis}</p>
                                <p>({item.perfil.orientation_his.substring(0, 6)})</p>
                            </ConTextCard>
                            <ConTextCard>
                                <p>{item.perfil.city},</p>
                                <p>{item.perfil.country}</p>
                            </ConTextCard>
                        </span>
                    </ContCard>
                )
            })}
        </div>
    );
};

export default Buscador1;
