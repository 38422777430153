import React, { useEffect, useState } from 'react'
//import styles
import { CheckBuscamos } from '../styles/DatosPerfil'
import { ContFilters } from '../styles/Buscador';



const BuscamosFilter = ({onHandleBuscamos}) => {

    // change background color label
    const [labelColors, setLabelColors] = useState({});
    //change text color label
    const [checkboxTextColors, setCheckboxTextColors] = useState({});

    //..managint select Buscamos.........
    //inicialisamos los select en false
    const [options, setOptions] = useState({
        'Parejas heterosexuales': false,
        'Mujeres heterosexuales': false,
        'Parejas (Ella bisexual)': false,
        'Mujer Bisexual': false,
        'Parejas (El Bisexual)': false,
        'Hombre heterosexual': false,
        'Parejas Bisexuales': false,
        'Hombre bisexual': false,
    });

    //recibe el evento y extrae el nombre de la opción y el estado actualizado
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        //actualizar el estado con la nueva selección.
        setOptions(prevOptions => ({
            ...prevOptions,
            [name]: checked
        }));
        // change background color label
        setLabelColors(prevColors => ({
            ...prevColors,
            [name]: checked ? '#205285' : '#FFFFFF'
        }));
        // change text color label
        setCheckboxTextColors(prevColors => ({
            ...prevColors,
            [name]: checked ? '#FFFFFF' : '#707070'
        }));
    };

    const handleChange = (event) => {
        //crea un nuevo objeto llamado allOptions que contiene todas las opciones, 
        //tanto las seleccionadas como las no seleccionadas
        const handleSave = () => {
            //obtiene un array de pares clave-valor del objeto
            //utiliza reduce para construir el objeto allOptions a partir de ese array
            const allOptions = Object.entries(options).reduce((acc, [key, value]) => {
                //Dentro de reduce, se asigna cada par clave-valor al nuevo objeto acc. 
                //Al final, se obtiene un objeto allOptions con todas las opciones, 
                //independientemente de si están seleccionadas (true) o no seleccionadas (false)
                acc[key] = value;
                return acc
            }, {});
            // se debuelve el objeto allOptions actualizado
            setOptions(allOptions);
        };
    };
    //enviamos el estado actualizado al padre
    useEffect(()=>{
        onHandleBuscamos(options);
    },[options])
    //...................................

    const optionList = Object.keys(options);

    return (
        <>
            <ContFilters>
                <label className='label1' >Me Interesa</label>
                <span>
                    {
                        optionList.map((item, index) => (
                            <CheckBuscamos
                                onChange={handleChange}
                                style={{ backgroundColor: labelColors[item], color: checkboxTextColors[item] }}
                                key={index}
                            >
                                <input
                                    type="checkbox"
                                    name={item}
                                    checked={options[item]}
                                    onChange={handleCheckboxChange}
                                /><p>{item}</p>
                            </CheckBuscamos>
                        ))}
                </span>
            </ContFilters>
        </>
    )
}

export default BuscamosFilter