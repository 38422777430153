import styled from "styled-components";

export const ContInputPerfil = styled.div`
    /* border: 1px solid red;//........................ */
    display: flex;
    flex-direction: column;
    padding: 0!important;
    label{
        /* border: 1px solid red;//.................. */
        color: var(--color2); 
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
    }
    .alertClass{
        height: 51px;
        border-radius: 15px;
        border: 2px solid var(--color5);
        padding: 0 1em;
        margin-bottom: .5em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    .normalClass{
        width: 40%;
        height: 51px;
        border-radius: 15px;
        border: 2px solid transparent;
        padding: 0 1em;
        margin-bottom: .5em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    
`
export const ContSelectPerfil = styled.div`
    display: flex;
    flex-direction: column;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    label{
        /* border: 1px solid red;//.................. */
        color: var(--color2);
        text-align: justify;
        font-size: 15px;
        padding-bottom: .2em;
        margin-top:.8em;
    }
    div{
        /* border: 1px solid red;//.................. */
        display: flex;
        justify-content: space-between;
        select{
        width: 48%;
        height: 51px;
        border-radius: 15px;
        border: none;
        margin-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding: 0 1em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    }
    option{
        color: red;
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding-left: 1em;
    }
    .red{
        color: red!important;
    }
`
export const TextAreaPerfil = styled.textarea`
    width: 90%;
    height: 110px;
    border-radius: 15px;
    border: 2px solid transparent;
    margin-bottom: .5em;
    background-color: var(--color3);
    color: var(--color2);
    padding: 1em;
    -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
    box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
    &:focus {
        border: 2px solid var(--degradado1);
        outline: none;
        &::placeholder {
            color: var(--color6);
        }
    }
`
export const SelectBuscamos = styled.div`
    /* border: 1px solid red;//............. */
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    span{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .label1{
        text-align:left;
        color:var(--color2);
        font-weight: 700;
        padding: 0 0 .5em .2em;
        font-size: 1.3em;
    }
`
export const CheckBuscamos = styled.label`
    cursor: pointer;
    color: var(--color2);
    width:49%;
    border-radius: .4em;
    padding:.3em 0;
    margin:.1em 0;
    text-align:left;
    p{
        padding:0 0 0 .4em;
        margin: 0;
        font-size: .8em;
    }
    input{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        visibility: hidden;
        position: absolute;
        right: 0;
    }
`
export const CustomDateInput = styled.div`
    /* border: 1px solid blue;//..................*/
    display: flex;
    justify-content: space-between;
    padding: 0!important;
    margin: 0!important;
    text-align: inherit;
`
export const InputDate = styled.input`
    text-transform: uppercase;
    outline: none;
    color: var(--color2);
    width: 32%;
    &::-webkit-calendar-picker-indicator {
        font-size: 28px;
        cursor: pointer;
    }
`
export const SelectCountry = styled.div`
    width: 48%;
    #nationality{
        width: 100%;
    }

`
export const ContCheckDP = styled.label`
    /* border: 1px solid red;//.................. */
    width: 100%;
    margin: 1em 0;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
        width: 90%;
        font-size: 14px;
        color: var(--color2);
    }
`
export const CheckboxInputDP = styled.input`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color4);
    position: relative;
    transition: all 0.3s ease;
    margin-right: .5em;

    &:checked {
        background-color: var(--degradado2);
    }

    &:checked::after {
        content: "\\2714";
        font-size: 14px;
        color: var(--color3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        cursor: pointer;
    }
`
export const ContSelectPais = styled.div`
    display: flex;
    flex-direction: column;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    span{
        text-align: justify;
        margin-top:.8em;
        label{
            /* border: 1px solid red;//.................. */
            color: var(--color2);
            font-size: 15px;
            padding-bottom: .5em;
        }
    }
    div{
        /* border: 1px solid red;//.................. */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        select{
        width: 95%;
        height: 51px;
        border-radius: 15px;
        border: none;
        margin-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding: 0 1em;
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33); 
        box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.33);
        &:focus {
            border: 2px solid var(--degradado1);
            outline: none;
            &::placeholder {
                color: var(--color6);
            }
        }
    }
    }
    option{
        color: red;
        text-align: justify;
        font-size: 15px;
        padding-bottom: .5em;
        background-color: var(--color3);
        color: var(--color2);
        padding-left: 1em;
    }
    .red{
        color: red!important;
    }
`
export const SelectCity = styled.div`
    width: 100%;
    #nationality{
        width: 100%;
    }

`