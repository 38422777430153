import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


//This component uses React Router's useLocation hook to get //the current path.
//It then uses the useEffect hook to update the window's scroll position to(0, 0) each time the
//the scroll position of the window to(0, 0) each time the path changes.
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
