import React from 'react'
//import components
import NavBarFooter from '../components/NavBarFooter';
import NavBarAux from '../components/NavBarAux';
//import data
import { spanish } from '../data/spanish';
//import styles
import { ContNoEvents } from '../styles/Eventos';
//import material ui
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Eventos = () => {

    //get data from text body
    const [textBody] = spanish;
    //destructuring static data
    const { Events: { textEvents1, textEvents2 } } = textBody;

    return (
        <>
            <NavBarAux propName={'Eventos'} />
            <ContNoEvents >
                <CalendarMonthIcon/>
                <h1>{textEvents1}</h1>
                <p>{textEvents2}</p>
            </ContNoEvents >
            <NavBarFooter propName={'Eventos'} />
        </>
    )
}

export default Eventos